import { FadableComponent } from 'components/FadableComponent/FadableComponent';
import React from 'react';
import './FounderJames.scss'

import james from 'assets/images/about-us/james-bkg.png';
import jamesDeco from 'assets/images/about-us/founder-james-deco-pink.png';
import { MenuThemeSetter } from 'components/MenuThemeSetter/MenuThemeSetter';
import { useTranslation } from 'react-i18next';

export const FounderJames = () => {

    const { t, i18n } = useTranslation();
    const lang = i18n.language === "en-US" ? "en":"zh";

    return (
        <MenuThemeSetter theme="dark" className="founder-james-section fullpage">
            <div className="founder-james-section__background page-container">
                <div className="sticky-founder-image">
                    <div>
                        <img src={james} alt="Founder James" />
                    </div>
                </div>
            </div>

            <div className="fullpage__page">
                <div className="page-container">
                    <div className="founder-james-section__description">
                        <div className={`founder-james-section__content founder-quote ${lang}`}>
                            <div className="p font-l">{t("about_us_james.quote")}</div>
                            <FadableComponent>
                                <h4 className={`title-bodoniModa`}>{t("about_us_james.caption")}</h4>
                            </FadableComponent>
                            {/* <div>
                                <FadableComponent className="title-m title-bodoniModa">{t("about_us_james.caption")}</FadableComponent>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="fullpage__page">
                <div className="page-container">
                    <div className="founder-james-section__history">
                        <div className="founder-james-section__content">
                            <div>
                                <h6 className="founder-james-section__title">
                                    <span className="title-s title-shadow">{t("about_us_james.name")}</span>
                                    <p className="font-l">CO-FOUNDER</p>
                                    <br />
                                    {/* <span className="font-s">A registered Chinese medicine practitioner, James shares his medical knowledge regularly on major media platforms in Hong Kong, such as RTHK and Apple Daily.</span> */}
                                    <div className="line-decoration line-decoration--right">
                                        <img src={jamesDeco} alt="deco" />
                                    </div>
                                </h6>
                            </div>
                            <div>
                                <div className="caption">{t("about_us_james.desc_1")}</div>

                                <div className="caption">{t("about_us_james.desc_2")}</div>

                                <div className="caption">{t("about_us_james.desc_3")}</div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </MenuThemeSetter>
    );
}