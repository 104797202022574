import { UITheme } from "types/ThemeTypes";
import "./NavigationMenuItem.scss"
import { ReactComponent as PointerRight } from './../../assets/images/icon-pointer-right.svg'
import { AnimatePresence, motion, useAnimation } from "framer-motion";
import { useMediaQuery } from "react-responsive";
import React, { useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { NavBarContext } from "components/Context/NavBarContext";

function easeOutBounceFunc(x: number): number {
    const n1 = 7.5625;
    const d1 = 2.75;

    if (x < 1 / d1) {
        return n1 * x * x;
    } else if (x < 2 / d1) {
        return n1 * (x -= 1.5 / d1) * x + 0.75;
    } else if (x < 2.5 / d1) {
        return n1 * (x -= 2.25 / d1) * x + 0.9375;
    } else {
        return n1 * (x -= 2.625 / d1) * x + 0.984375;
    }
}

export const NavigationMenuItem = (props: { 
    name: string, 
    href?: string, 
    target?: string, 
    onClick?: () => void, 
    color: UITheme, 
    animationDelay: number,
    disabled?: boolean,
}) => {

    const mountAnimationControl = useAnimation();
    const breakpoint = useMediaQuery({ query: "(min-width: 1200px)" });

    const { menuOpen, setMenuOpen } = useContext(NavBarContext);

    useEffect(() => {
        if (menuOpen)
            mountAnimationControl.start("mount");
        else
            mountAnimationControl.start("init");
    }, [menuOpen])

    const delayedMountAnimationMotion = {
        init: {
            opacity: 0,
            transition: {
                duration: 0.25,
                type: "tween",
                ease: "easeIn"
            }
        },
        mount: {
            opacity: 1,
            transition: {
                delay: props.animationDelay,
                duration: 0.5,
                type: "tween",
                ease: "easeInOut"
            }
        }
    };

    const textMotion = {
        rest: {
            x: 0,
            transition: {
                duration: 0.4,
                type: "tween",
                ease: "easeIn"
            }
        },
        hover: {
            x: 60,
            transition: {
                duration: 0.4,
                type: "tween",
                ease: "easeOut"
            }
        }
    };

    const pointerMotion = {
        rest: {
            x: -15,
            opacity: 0,
            transition: {
                duration: 0.4,
                type: "tween",
                ease: "easeIn"
            }
        },
        hover: {
            x: 25,
            opacity: 1,
            transition: {
                duration: 0.4,
                type: "tween",
                ease: easeOutBounceFunc
            }
        },
    };

    return (
        <motion.div
            initial="init"
            variants={delayedMountAnimationMotion}
            animate={mountAnimationControl}
        >
            <motion.div
                className="menu-list-item"
                initial="rest"
                whileHover={breakpoint ? "hover" : undefined}
                animate="rest"
            >
                <motion.div
                    className="menu-list-item-pointer-container"
                    key={`menu-list-item-pointer-container-${props.name}`}
                    variants={pointerMotion}
                >
                    <PointerRight className="menu-list-item-pointer" />
                </motion.div>

                {
                    props.href ?
                        (
                            props.target !== "_blank" ?
                            <NavLink target={props.target} to={props.href} onClick={() => { setMenuOpen(false) }}
                                style={(props.disabled??false ? { pointerEvents: "none", opacity: 0.6 }:{})}
                            >
                                <motion.div
                                    className={`menu-list-item-text title-shadow ${props.color}`}
                                    variants={textMotion}
                                >
                                    {props.name}
                                </motion.div>
                            </NavLink>
                            :
                            <a href={props.href} target={props.target} rel="noreferrer noopener"
                                style={(props.disabled??false ? { pointerEvents: "none", opacity: 0.6 }:{})}
                            >
                                <motion.div
                                    className={`menu-list-item-text title-shadow ${props.color}`}
                                    variants={textMotion}
                                >
                                    {props.name}
                                </motion.div>
                            </a>
                        )
                        :
                        <motion.div
                            className={`menu-list-item-text title-shadow ${props.color}`}
                            variants={textMotion}
                            onClick={() => { props.onClick?.(); setMenuOpen(false); }}
                        >
                            {props.name}
                        </motion.div>
                }
            </motion.div>
        </motion.div>
    );
}