import './AboutUsSection3.scss'
import LiquorJourneyImage from './../../../assets/images/liquor-journey.png';
import LiquorJourneyText from './../../../assets/images/liquor-journey-text.png';
import LiquorJourneyTextZH from './../../../assets/images/liquor-journey-text-zh.png';
import { useViewportScroll } from 'framer-motion';
import React, { useContext, useEffect, useRef } from 'react';
import { NavBarContext } from 'components/Context/NavBarContext';
import { Timeline } from '../Timeline/Timeline';
import { MenuThemeSetter } from 'components/MenuThemeSetter/MenuThemeSetter';
import { useTranslation } from 'react-i18next';
import timelineRemedy from 'assets/images/about-us/timeline-remedy.jpg';
import timelineWisdom from 'assets/images/about-us/timeline-wisdom.jpg';

export const AboutUsSection3 = () => {
    const { t, i18n } = useTranslation();

    const { scrollY } = useViewportScroll();
    const { theme } = useContext(NavBarContext);

    const rotatingText = useRef<HTMLImageElement>(null);

    const themeStateRef = useRef(theme);
    useEffect(() => {
        themeStateRef.current = theme;
    }, [theme]);

    useEffect(() => {
        const unsubscribe = scrollY.onChange((y) => {
            if (!rotatingText.current) return;

            rotatingText.current.style.transform = `rotate(${y / 10}deg)`;
        });

        return () => {
            unsubscribe();
        }
    }, []);

    return (
        <MenuThemeSetter theme="dark" className="about-us-section3-wrapper" id="about-us-section3">
            <div className="about-us-section3-liquor-journey-intro-wrapper">
                <img src={LiquorJourneyImage} className="about-us-section3-liquor-journey-intro-image" alt="" />
                <img
                    ref={rotatingText}
                    src={i18n.language === "en-US" ? LiquorJourneyText : LiquorJourneyTextZH}
                    className="about-us-section3-liquor-journey-rotating-text"
                    alt=""
                />
            </div>

            <Timeline
                nodes={[
                    {
                        src: timelineRemedy,
                        title: t("about_us_timeline.title_1"),
                        desc: t("about_us_timeline.p_1"),
                        side: "right",
                        offsetTop: 100
                    },
                    {
                        src: timelineWisdom,
                        title: t("about_us_timeline.title_2"),
                        desc: t("about_us_timeline.p_2"),
                        side: "left",
                        offsetTop: 100
                    },
                ]}
            />
        </MenuThemeSetter>
    );
}