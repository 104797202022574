import React, { useContext, useEffect, useState } from "react"
import './Magnolia1.scss'

import wrappedMagnolia from 'assets/images/product/bottle-magnolia-wrapped.png';
import magnoliaBottle from 'assets/images/product/bottle-magnolia-cps.png';
import { motion } from "framer-motion";
import { ScrollingAnimator } from "components/ScrollingAnimator/ScrollingAnimator";
import { Frame } from "components/ScrollingAnimator/Frame/Frame";
import { NavBarContext } from "components/Context/NavBarContext";
import { IngredientConfigs, IngredientPopup, IngredientPopupConfig } from "../IngredientPopup/IngredientPopup";

import berries from "assets/images/product/ingredient-magnolia-berries.png"
import tangerine from "assets/images/product/ingredient-aged-sundried-tangerine-peel.png"
import mulberries from "assets/images/product/ingredient-mulberries.png"
import sandalwood from "assets/images/product/ingredient-sandalwood.png"
import magnoliaTitleChi from 'assets/images/product/title-magnolia-chi.png';
// import magnoliaTitleEng from 'assets/images/product/title-magnolia-eng.png';

import {ReactComponent as PointerLeft} from 'assets/images/icon-pointer-left.svg';
import {ReactComponent as PointerRight} from 'assets/images/icon-pointer-right.svg';

import { DecoStar } from 'components/DecoStar/DecoStar'
import { useTranslation } from "react-i18next";

export const Magnolia1 = () => {
    const { t, i18n } = useTranslation();
    const lang = i18n.language === "en-US" ? "en" : "zh";

    const [openIngredient, setOpenIngredient] = useState(false);
    const [ingredientData, setIngredientData] = useState<IngredientPopupConfig>(IngredientConfigs.tangerine);

    const [bottleFrame, setBottleFrame] = useState(0);
    const { setTheme } = useContext(NavBarContext);

    const ingredients = [
        { src: berries, name: t("product_detail.magnolia_berry"), config: IngredientConfigs.magnolia },
        { src: tangerine, name: t("product_detail.tangerine_peel"), config: IngredientConfigs.tangerine },
        { src: mulberries, name: t("product_detail.mulberry"), config: IngredientConfigs.mulberries },
        { src: sandalwood, name: t("product_detail.sandalwood"), config: IngredientConfigs.sandalwood },
    ];

    useEffect(() => {
        if (openIngredient) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [openIngredient]);

    return (
        <>
            <IngredientPopup
                config={ingredientData}
                visible={openIngredient}
                onClose={() => { setOpenIngredient(false); }}
            />

            <div id="magnolia-section-1" className={`magnolia-section-1 ${bottleFrame === 3 ? "table" : ""}`}>

                <ScrollingAnimator
                    frameDistance={window.innerHeight}
                    totalFrame={4}
                    onFrameChange={(f: number) => {
                        setBottleFrame(f);
                        if (f >= 3) setTheme("dark");
                        else setTheme("light");
                    }}

                    debug={false}
                >
                    <div className="sticky-bottle">
                        <div className="sticky-bottle__bottle-container">
                            <Frame currentFrame={bottleFrame} activeFrames={[0]} activeFramesMobile={[]}>
                                <motion.img
                                    className={`sticky-bottle__bottle`}
                                    src={wrappedMagnolia}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    // transition={{duration:0}}
                                    alt="Wrapped Bottle"
                                />
                            </Frame>
                            <Frame currentFrame={bottleFrame} activeFrames={[1, 2, 3, 4]} activeFramesMobile={[0]}>
                                <motion.img
                                    className={`sticky-bottle__bottle ${bottleFrame === 3 ? "shadow" : ""}`}
                                    src={magnoliaBottle}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    // transition={{duration:0}}
                                    alt="Wrapped Bottle"
                                />
                            </Frame>
                        </div>
                        <Frame currentFrame={bottleFrame} activeFrames={[0, 1]} activeFramesMobile={[0]}>
                            <motion.div
                                className={`sticky-bottle__lable title-xxl font-shadow--6`}
                                initial={{ opacity: 0, y: "-10vh" }}
                                animate={{ opacity: 1, y: "-15vh" }}
                                transition={{ duration: 0.75 }}
                                exit={{ opacity: 0, y: "-10vh" }}
                            >
                                {lang === "en" ? 
                                    t("product.magnolia")
                                    :
                                    <img src={magnoliaTitleChi} alt={t("product.magnolia")} />
                                }
                            </motion.div>
                        </Frame>
                        <Frame currentFrame={bottleFrame} activeFrames={[2]} activeFramesMobile={[1, 2]}>
                            <motion.div
                                className={`sticky-bottle__content`}
                                initial={{ opacity: 0, y: "5vh" }}
                                animate={{ opacity: 1, y: "0vh" }}
                                transition={{ duration: 0.75 }}
                                exit={{ opacity: 0, y: "5vh" }}
                            >
                                <Frame currentFrame={bottleFrame} activeFrames={[2]} activeFramesMobile={[1]}>
                                    <motion.div
                                        className={`sticky-bottle__description`}
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                    >
                                        <h5 className={`title-shadow`}>{t("product_detail.infusion").toUpperCase()}</h5>
                                        <p className={'font-xl'}>{t("product_detail.magnolia_berry").toUpperCase()}</p>
                                        <p className={'font-xl'}>{t("product_detail.tangerine_peel").toUpperCase()}</p>
                                        <p className={'font-xl'}>{t("product_detail.mulberry").toUpperCase()}</p>
                                        <p className={'font-xl'}>{t("product_detail.sandalwood").toUpperCase()}</p>
                                        <DecoStar color="black" />
                                        {/* <h5 className={`title-shadow`}>{t("product_detail.alcohol_title")}</h5> */}
                                        <h5 className={`title-shadow`}>{t("product_detail.alcohol_amount", { amount: "29%" })}<p>380ML</p></h5>
                                    </motion.div>
                                </Frame>
                                <Frame currentFrame={bottleFrame} activeFrames={[2]} activeFramesMobile={[2]}>
                                    <motion.div
                                        className={`sticky-bottle__description`}
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                    >
                                        <h5 className={`title-shadow`}>{t("product_detail.magnolia_title")}</h5>
                                        <p className={'font-m'}>{t("product_detail.magnolia_p_1")}</p>
                                        <br />
                                        <p className={'font-m'}>{t("product_detail.magnolia_p_2")}</p>
                                    </motion.div>
                                </Frame>
                            </motion.div>
                        </Frame>

                        <Frame currentFrame={bottleFrame} activeFrames={[3]}>
                            <motion.div
                                className={`sticky-bottle__ingredient-container`}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                <div className={`sticky-bottle__ingredients`}>
                                    <div className="sticky-bottle__ingredients-column">
                                        {
                                            ingredients.slice(0, 2).map((ingredient, index) => {
                                                return (
                                                    <motion.figure
                                                        key={index}
                                                        className={`sticky-bottle__ingredient`}
                                                        initial={{ opacity: 0, y: "5vh" }}
                                                        animate={{ opacity: 1, y: "0vh" }}
                                                        transition={{ delay: 0.3 * index, duration: 0.5 }}
                                                        exit={{ opacity: 0, y: "5vh" }}
                                                        onClick={() => {
                                                            setIngredientData(ingredient.config);
                                                            setOpenIngredient(true);
                                                        }}
                                                    >
                                                        <img src={ingredient.src} alt="Ingredient" />
                                                        <figcaption className="font-l">{ingredient.name}</figcaption>
                                                        <div className="see-more">
                                                            <PointerRight/>
                                                            {t("product_detail.see_more")}
                                                            <PointerLeft/>
                                                        </div>
                                                    </motion.figure>
                                                );
                                            })
                                        }
                                    </div>
                                    <div className="sticky-bottle__ingredients-column">
                                        {
                                            ingredients.slice(2, 4).map((ingredient, index) => {
                                                return (
                                                    <motion.figure
                                                        key={index}
                                                        className={`sticky-bottle__ingredient`}
                                                        initial={{ opacity: 0, y: "5vh" }}
                                                        animate={{ opacity: 1, y: "0vh" }}
                                                        transition={{ delay: 0.3 * (index + 0), duration: 0.5 }}
                                                        exit={{ opacity: 0, y: "5vh" }}
                                                        onClick={() => {
                                                            setIngredientData(ingredient.config);
                                                            setOpenIngredient(true);
                                                        }}
                                                    >
                                                        <img src={ingredient.src} alt="Ingredient" />
                                                        <figcaption className="font-l">{ingredient.name}</figcaption>
                                                        <div className="see-more">
                                                            <PointerRight/>
                                                            {t("product_detail.see_more")}
                                                            <PointerLeft/>
                                                        </div>
                                                    </motion.figure>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                                <p className="sticky-bottle__ingredients-note">{t("product_detail.disclaimer")}</p>
                            </motion.div>
                        </Frame>
                    </div>
                    <Frame currentFrame={bottleFrame} activeFrames={[3]}>
                        <motion.div
                            className={`sticky-bottle__ingredient-background`}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                        >
                            <div className="sticky-bottle__table"></div>
                        </motion.div>
                    </Frame>
                </ScrollingAnimator>

            </div>
        </>
    )
}