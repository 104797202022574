import './Footer.scss';
import arrowRight from 'assets/images/icon-pointer-right.svg';
import { MenuThemeSetter } from 'components/MenuThemeSetter/MenuThemeSetter';
import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FooterContext } from 'components/Context/FooterContext';
import { useTranslation } from 'react-i18next';
import { getLocaleUrl } from 'helpers/i18n/locale';
import { FormStatusType } from 'types/FormStatusType';
import { subscribeEmail } from 'helpers/api/subscription/subscription';
import { environment } from 'environment/environment';
import { ShopEvent } from 'helpers/google-analytic/ga';

export const Footer = () => {
    const { t, i18n } = useTranslation();
    const { enable } = useContext(FooterContext);

    const [loading, setLoading] = useState(false);
    const [formStatus, setFormStatus] = useState("Unsend" as FormStatusType);
    const [email, setEmail] = useState<string>("");

    const handleSubmit = (event: any) => {
        event.preventDefault();
        const emailReg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

        if (emailReg.test(email)) {
            setLoading(true);
            subscribeEmail(email).then((res) => {
                setEmail("");
                setFormStatus("Success");
            })
                .catch(err => {
                    console.log(err);
                    setFormStatus("Error");
                })
                .finally(() => setLoading(false));
        } else {
            setFormStatus("Error");
        }

        // subscribeEmail();
    }
    return (
        <>
            {enable && <footer className={`footer`}>
                <MenuThemeSetter theme="dark">
                    <div className="footer__container">
                        <div className="footer__stay-connect">
                            <form onSubmit={handleSubmit}>
                                <h6 className={`title-shadow`}>{t("footer.title")}</h6>
                                <div className="input-field">
                                    <input type="email" className="input-field__input" placeholder={t("footer.enter_email")} value={email}
                                        onChange={e => {
                                            setEmail(e.target.value);
                                            setFormStatus("Unsend");
                                        }}
                                        disabled={loading}
                                    />
                                    {formStatus === "Error" && <span className="input-field__error">Please enter a valid email address</span>}
                                    {formStatus === "Success" && <span className="input-field__success">Successfully Subscribed</span>}
                                    <button className="button button--submit" disabled={loading}><img src={arrowRight} alt="Right Arrow" /></button>
                                </div>
                            </form>
                        </div>
                        <div className="footer__nav">
                            <NavLink to={`/${getLocaleUrl(i18n)}/about-us`} className="footer__navItem">{t("footer.about_us")}</NavLink>
                            <a href={`${environment.shopUrl}`} onClick={ShopEvent} target="_blank" rel="noreferrer noopener" className="footer__navItem">{t("footer.shop")}</a>
                            <NavLink to={`/${getLocaleUrl(i18n)}/product`} className="footer__navItem">{t("footer.product")}</NavLink>
                            <NavLink to={`/${getLocaleUrl(i18n)}/press-room`} className="footer__navItem">{t("footer.press")}</NavLink>
                            <NavLink to={`/${getLocaleUrl(i18n)}/sober-thoughts`} className="footer__navItem">{t("footer.media")}</NavLink>
                            <NavLink to={`/${getLocaleUrl(i18n)}/faq`} className="footer__navItem">{t("footer.faq")}</NavLink>
                            <NavLink to={`/${getLocaleUrl(i18n)}/privacy`} className="footer__navItem">{t("footer.privacy")}</NavLink>
                        </div>
                        <div className="footer__contact">
                            <hr />
                            <div className="footer__contactItem">
                                <strong>{t("footer.contact_email_title")}</strong>
                                <p>
                                    <a href={`mailto:${environment.emailAddress}?subject=Mail from Magnolia Site`}>{environment.emailAddress}</a>
                                </p>
                            </div>
                            <div className="footer__contactItem">
                                <strong>{t("footer.contact_tel_title")}</strong>
                                <p>{environment.phone}</p>
                            </div>
                            <div className="footer__contactItem" style={{ lineHeight: 1.5 }}>
                                <strong>{t("footer.contact_address_title")}</strong>
                                <p>{t("footer.contact_address")}</p>
                            </div>
                        </div>
                    </div>

                    <div className="footer__copyright font-xs">{t('footer.copyright', { year: `2021${new Date().getFullYear() !== 2021 ? `-${new Date().getFullYear()}` : ""}` })}</div>
                </MenuThemeSetter>
            </footer>}
        </>

    )
}