import './Story.scss'

import React from 'react'
import { DecoStar } from 'components/DecoStar/DecoStar'
import { FadableComponent } from 'components/FadableComponent/FadableComponent'

import bk2 from 'assets/images/about-us/about-us-bkg2.svg'
import bk3 from 'assets/images/about-us/about-us-bkg3.svg'
import { MenuThemeSetter } from 'components/MenuThemeSetter/MenuThemeSetter'
import { useTranslation } from 'react-i18next'

export const StoryBorn = () => {

    const { t } = useTranslation();

    return (
        <div className="story-section fullpage">
            <MenuThemeSetter theme="light" className="born fullpage__page">
                <div className="born__content flex-layout flex-direction-column justify-content-center align-items-start">
                    <div className="born__quote font-l">
                        <blockquote>
                            <p>{t("about_us_story.story_born_1")}</p>

                            <p>{t("about_us_story.story_born_2")}</p>
                        </blockquote>
                    </div>
                    <FadableComponent className="born__slogan flex-layout flex-center">
                        <DecoStar color="black" />
                        <h4 className="title-bodoniModa">{t("about_us_story.conclusion")}</h4>
                    </FadableComponent>

                    <div className="born__background flex-layout justify-content-start align-items-end">
                        <div>
                            <figure className="bottle1"><img src={bk2} alt="Born background plant" /></figure>
                            <figure className="bottle2"><img src={bk3} alt="Born background plant" /></figure>
                        </div>
                    </div>
                </div>
            </MenuThemeSetter>
        </div>
    )
}