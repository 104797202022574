import './Plants.scss';
import plant1 from 'assets/images/product/serve-plant1.png'
import plant2 from 'assets/images/product/serve-plant2.png'
import plant3 from 'assets/images/product/serve-plant3.png'
import plant4 from 'assets/images/product/serve-plant4.png'
import plant5 from 'assets/images/product/serve-plant5.png'
import plant6 from 'assets/images/product/serve-plant6.png'
import plant7 from 'assets/images/product/serve-plant7.png'
import { ProductType } from 'types/ProductTypes';

export const Plants = (props: {
    active?: boolean,
    type?: ProductType,
}) => {
    const type = props.type ?? "Magnolia";

    return (
        <div className={`product-plants ${props.active? "active":""}`}>
            <div className="transform">
                {type === "Magnolia" && <>
                    <img className="product-plants--1" src={plant1} alt="plant 1"/>
                    <img className="product-plants--2" src={plant2} alt="plant 2"/>
                    <img className="product-plants--3" src={plant3} alt="plant 3"/>
                    <img className="product-plants--4" src={plant4} alt="plant 4"/>
                </>}
                {type === "Roselle" && <>
                    <img className="product-plants--5" src={plant5} alt="plant 5"/>
                    <img className="product-plants--6" src={plant6} alt="plant 6"/>
                    <img className="product-plants--7" src={plant7} alt="plant 7"/>
                </>}
            </div>
        </div>
    )
}