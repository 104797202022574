import { useEffect, useState } from "react";
import { useWindowSize } from "./UseWindowResize"

export const useIsMobile = () => {
    const windowSize = useWindowSize();

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        setIsMobile(() => (windowSize.width ?? 0) <= 1000);
        return () => {
            
        }
    }, [windowSize]);

    return isMobile;
}