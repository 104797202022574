import { AnimatePresence } from "framer-motion";
import { useIsMobile } from "hook/UseIsMobile";
import React from "react";

export const Frame = (props: {
    children: any,
    currentFrame: number,
    active?: boolean,
    activeMobile?: boolean,
    activeFrames?: number[],
    activeFramesMobile?: number[],
}) => {
    const {
        children, 
        currentFrame,
        active,
        activeMobile,
        activeFrames, 
        activeFramesMobile,
    } = props;

    const isMobile = useIsMobile();

    const activeCondition = () => {
        if (active) return true;
        if (isMobile && activeMobile) return true;

        if (!isMobile || !activeFramesMobile) {
            if (activeFrames) {
                return activeFrames.includes(currentFrame);
            }
        } else {
            return activeFramesMobile.includes(currentFrame);
        }
    }

    return (
        <>
            <AnimatePresence>
                {activeCondition() && children}
            </AnimatePresence>
        </>
    )
}
