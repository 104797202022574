import { Api } from '../api';
import { environment } from "environment/environment";
import { PostModel } from 'types/BlogPostType';

export const getPublicPost = async (url: string, page: number, limit: number = 10): Promise<{ totalPage: number, posts: PostModel[] }> => {
    const api = new Api();

    return api.get(`${environment.apiUrl}/post/${url}`, {
        page: page,
        limit: limit
    })
        .then((response) => {
            return {
                totalPage: response.result.last_page,
                posts: response.result.data as PostModel[]
            };
        })
        .catch((err) => {
            throw err;
        });
}