import { NavBarContext } from "components/Context/NavBarContext";
import { SandalwoodSection } from "pages/ProductDetail/JourneySections/SandalWoodSection";
import { ProductDetailJourney } from "pages/ProductDetail/ProductDetailJourney/ProductDetailJourney";
import React, { useContext, useEffect } from "react"
import { NavLink, useParams } from "react-router-dom";
import { Magnolia1 } from "./Magnolia1/Magnolia1";
import { Magnolia3 } from "./Magnolia3/Magnolia3";
import "./ProductDetail.scss"

import SandalwoodBackground from "assets/images/product-detail/sandalwood.png";
import CitrusBackground from "assets/images/product-detail/citrus.png";
import BerryDeco from "assets/images/product-detail/berry-deco.png";
import FloralDeco from "assets/images/product-detail/floral-deco.png";
import LeafyDeco from "assets/images/product-detail/leafy-deco.png";
import PlumDecoTop from "assets/images/product-detail/plum-deco-top.png";
import PlumDecoBottom from "assets/images/product-detail/plum-deco-bottom.png";
import SpicyDeco from "assets/images/product-detail/spicy-deco.png";
import noisebkg from "assets/images/noise-bkg.png";
import woodbkg from "assets/images/product/bkg-wood.jpg";
import wrappedMagnolia from 'assets/images/product/bottle-magnolia-wrapped.png';
import wrappedRoselle from 'assets/images/product/bottle-roselle-wrapped.png';
import magnoliaBottle from 'assets/images/product/bottle-magnolia-cps.png';
import roselleBottle from 'assets/images/product/bottle-roselle-cps.png';
import magnoliaTitleChi from 'assets/images/product/title-magnolia-chi.png';
import magnoliaTitleEng from 'assets/images/product/title-magnolia-eng.png';
import roselleTitleChi from 'assets/images/product/title-roselle-chi.png';
import roselleTitleEng from 'assets/images/product/title-roselle-eng.png';

import pointerLeft from 'assets/images/icon-pointer-left.svg';

import { BerrySection } from "pages/ProductDetail/JourneySections/BerrySection";
import { CitrusSection } from "pages/ProductDetail/JourneySections/CitrusSection";
import { LeafySection } from "pages/ProductDetail/JourneySections/LeafySection";
import { PlumSection } from "pages/ProductDetail/JourneySections/PlumSection";
import { JourneySectionConfig } from "types/ProductDetailJourneyTypes";
import { FloralSection } from "pages/ProductDetail/JourneySections/FloralSection";
import { SpicySection } from "pages/ProductDetail/JourneySections/SpicySection";
import { Roselle1 } from "./Roselle1/Roselle1";
import { Roselle3 } from "./Roselle3/Roselle3";
import { LoaderContext } from "components/Context/LoaderContext";
import { LoaderComponent } from "components/LoaderComponent/LoaderComponent";
import { AnchorGroup } from "components/Anchor/AnchorGroup";
import { useTranslation } from "react-i18next";
import { getLocaleUrl } from 'helpers/i18n/locale';
import { PromotionPopup } from "components/PromotionPopup/PromotionPopup";
import { BackToTopButton } from "components/BackToTopButton/BackToTopButton";
import GoToTop from "components/GoToTop/GoToTop";
import { DisclaimerPopup } from "components/DisclaimerPopup/DisclaimerPopup";
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';

const predifinedSections: { [key: string]: JourneySectionConfig } = {
    "Sandalwood": {
        pageName: "Sandalwood",
        pageContent: <SandalwoodSection />,
        blendColor: "#C87D1D",
        waypointColor: "#FFCD8D",
        backgroundImage: SandalwoodBackground,
        backgroundSize: "cover",
    },
    "Berry": {
        pageName: "Berry",
        pageContent: <BerrySection />,
        blendColor: "#541B3A",
        waypointColor: "#BF2378",
    },
    "Citrus": {
        pageName: "Citrus",
        pageContent: <CitrusSection />,
        blendColor: "#691C04",
        waypointColor: "#F25321",
        backgroundImage: CitrusBackground
    },
    "Leafy": {
        pageName: "Leafy",
        pageContent: <LeafySection />,
        blendColor: "#0F2E0B",
        waypointColor: "#35C226",
    },
    "Plum": {
        pageName: "Plum",
        pageContent: <PlumSection />,
        blendColor: "#541B3A",
        waypointColor: "#BF2378",
    },
    "Floral": {
        pageName: "Floral",
        pageContent: <FloralSection />,
        blendColor: "#740E2D",
        waypointColor: "#d81f63",
    },
    "Spicy": {
        pageName: "Spicy",
        pageContent: <SpicySection />,
        blendColor: "#8E0000",
        waypointColor: "#EF2632",
    }
}

export const ProductDetail = () => {
    const {t, i18n} = useTranslation();
    const { name } = useParams<{ name: string }>();

    const { setEnable, setShow, setTheme, setShowLogo } = useContext(NavBarContext);
    const {loading, cacheImages} = useContext(LoaderContext);

    useEffect(() => {
        
        ReactGA.pageview(window.location.pathname + window.location.search);

        cacheImages([
            SandalwoodBackground,
            CitrusBackground,
            BerryDeco,
            FloralDeco,
            LeafyDeco,
            PlumDecoTop,
            PlumDecoBottom,
            SpicyDeco,
            noisebkg,
            woodbkg,
            wrappedMagnolia,
            wrappedRoselle,
            magnoliaBottle,
            roselleBottle,
            magnoliaTitleChi,
            magnoliaTitleEng,
            roselleTitleChi,
            roselleTitleEng,
        ]);

        setEnable(true);
        setShow(true);
        setTheme("light");
        setShowLogo(true);
    }, []);

    return (
        <>
            <Helmet>
                <title>{t(`header.title.${name}`)}</title>
                <meta name="description" content={t(`header.des.${name}`)} />
            </Helmet>
            
            {/* <Redirector /> */}
            <DisclaimerPopup />

            <PromotionPopup />

            <LoaderComponent/>

            {!loading && <div className="product-detail-page">

                {
                    name === "magnolia" &&
                    <AnchorGroup configs={[
                        {
                            targetElementId: "magnolia-section-1"
                        },
                        {
                            targetElementId: "product-detail-journey-parent"
                        },
                        {
                            targetElementId: "magnolia-section-3"
                        }
                    ]}
                    />
                }

                {
                    name === "roselle" &&
                    <AnchorGroup configs={[
                        {
                            targetElementId: "roselle-section-1"
                        },
                        {
                            targetElementId: "product-detail-journey-parent"
                        },
                        {
                            targetElementId: "roselle-section-3"
                        }
                    ]}
                    />
                }

                {name === 'magnolia' && <Magnolia1 />}
                {name === 'magnolia' &&
                    <ProductDetailJourney
                        viewbox="0 0 1603.61 175.26"
                        line="M.4,169.4C167.1,171,338.12,183,414,133.49,510.18,70.77,523.19,1.56,635,1.56,744.4,1.56,791.66,81.61,847.7,82c58.48.45,75.4-43.7,125.36-43.7,43.72,0,98.13,45.88,201.73,77.58,31.1,9.51,50.52,7.92,295.15-3.66,59.5-2.82,107.11-5,134-6.22"
                        journeySections={
                            [
                                predifinedSections["Sandalwood"],
                                predifinedSections["Berry"],
                                predifinedSections["Citrus"],
                                predifinedSections["Leafy"],
                            ]

                        }
                        waypoints={
                            [
                                {
                                    x: 340,
                                    y: 160,
                                    activeColor: predifinedSections["Sandalwood"].waypointColor
                                },
                                {
                                    x: 650,
                                    y: 2,
                                    activeColor: predifinedSections["Berry"].waypointColor
                                },
                                {
                                    x: 940,
                                    y: 45,
                                    activeColor: predifinedSections["Citrus"].waypointColor
                                },
                                {
                                    x: 1300,
                                    y: 120,
                                    activeColor: predifinedSections["Leafy"].waypointColor
                                },
                            ]
                        }
                    />
                }
                {name === 'magnolia' && <Magnolia3 />}

                {name === 'roselle' && <Roselle1 />}
                {name === 'roselle' &&
                    <ProductDetailJourney
                        viewbox="0 0 1598.01 159.26"
                        line="M0,154.85c135.47-2.32,334.22,24,437.35-34.67C528.44,68.38,540.4-1,633,.53,736.71,2.27,779.71,59,897.59,69.73c51,4.63,62.83-18.6,112.67-18.6,49.07,0,59.61,28,112.88,32.77,59.83,5.36,73.37-19.05,130.66-13,45.75,4.79,65.43,29.46,101.65,38.89,44.37,11.55,177.52,7.17,242.56,7.17"
                        journeySections={
                            [
                                predifinedSections["Citrus"],
                                predifinedSections["Plum"],
                                predifinedSections["Floral"],
                                predifinedSections["Spicy"],
                            ]

                        }
                        waypoints={
                            [
                                {
                                    x: 340,
                                    y: 150,
                                    activeColor: predifinedSections["Citrus"].waypointColor
                                },
                                {
                                    x: 650,
                                    y: 2,
                                    activeColor: predifinedSections["Plum"].waypointColor
                                },
                                {
                                    x: 940,
                                    y: 66,
                                    activeColor: predifinedSections["Floral"].waypointColor
                                },
                                {
                                    x: 1300,
                                    y: 85,
                                    activeColor: predifinedSections["Spicy"].waypointColor
                                },
                            ]
                        }
                    />
                }
                {name === 'roselle' && <Roselle3 />}
                <NavLink to={`/${getLocaleUrl(i18n)}/product`} className="back-to-products">
                    <img src={pointerLeft} alt="" /> <h6 className="title-myanmar">{t('product_detail.back')}</h6>
                </NavLink>
            </div>}

            <GoToTop />
            <BackToTopButton />
        </>
    )
}