import './DottedLine.scss'

export const DottedLine = () => {
    return (
        <div className="dotted-line">
            <svg className="dotted-line__mobile" width="1000px" height="6px" viewBox="0 0 1000 6">

                <line x1="3" x2="1000" y1="3" y2="3" stroke="#5184AF" stroke-width="6" stroke-linecap="round" stroke-dasharray="0, 12"/>

            </svg>
            <svg className="dotted-line__desktop" width="6px" height="1000px" viewBox="0 0 6 1000">

                <line x1="3" x2="3" y1="3" y2="1000" stroke="#5184AF" stroke-width="6" stroke-linecap="round" stroke-dasharray="0, 12"/>

            </svg>
        </div>
    )
}