import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { getLocaleUrl } from "helpers/i18n/locale"
import './Used.scss'
import { useEffect } from 'react'

export const Used = () => {
    const {i18n} = useTranslation();
    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "auto";
        }
    }, []);
    return (
        <AnimatePresence>
            <motion.div className="promotion-used flex-layout flex-center flex-direction-column"
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
            >
                <h5>The promotion code has been used!</h5>
                <a href={`/${getLocaleUrl(i18n)}/promotion`}>Make an E-Ticket</a>
                <NavLink to={`/${getLocaleUrl(i18n)}/`}>Visit our Website</NavLink>
            </motion.div>
        </AnimatePresence>
    )
}