import React from "react"
import './HomeSection2.scss'
import Plant151 from "assets/images/home/home-plant15 1.svg"
import { FadableComponent } from "components/FadableComponent/FadableComponent"
import { useTranslation } from "react-i18next"
import { DecoStar } from 'components/DecoStar/DecoStar';

export const HomeSection2 = () => {

    const { t } = useTranslation();

    return (
        <>
            <section className="homesection2 fullpage__page">
                <div className="content">

                    <FadableComponent>
                        <h4 className="title title-bodoniModa">{t("home_section_2.title")}</h4>
                    </FadableComponent>
                    <DecoStar />
                    <p className="subtitle">{t("home_section_2.subtitle")}</p>
                    <h4 className="slogan title-bodoniModa">{t("home_section_2.slogan")}</h4>
                </div>
                <img src={Plant151} alt="" className={`scrolldown`} />
            </section>
        </>
    )
}