import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function GoToTop() {
	const routePath = useLocation();
	useEffect(() => {
		ScrollToTop()
	}, [routePath]);
	
	return null;
}

export const ScrollToTop = () => {
	document.documentElement.classList.add("scroll-behavior-auto");
	document.documentElement.scrollTo({ top: 0, behavior: 'auto' });
};
