import { ArticleListView } from "components/ArticleListView/ArticleListView";
import { BackToTopButton } from "components/BackToTopButton/BackToTopButton";
import { NavBarContext } from "components/Context/NavBarContext";
import { DisclaimerPopup } from "components/DisclaimerPopup/DisclaimerPopup";
import GoToTop from "components/GoToTop/GoToTop";
import { MenuThemeSetter } from "components/MenuThemeSetter/MenuThemeSetter";
import { PromotionPopup } from "components/PromotionPopup/PromotionPopup";
import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
// import { ArticleEntryType } from "types/RepublicRelationType";
import "./MediaCoverage.scss"
import ReactGA from 'react-ga';

// const dummyData: ArticleEntryType[] = [
//     {
//         article_title: "死不足惜？ ― 從威廉士對永生的判斷談起 死不足惜？ ― 從威廉士對永生的判斷談起 死不足惜？ ― 從威廉士對永生的判斷談起 死不足惜？ ― 從威廉士對永生的判斷談起",
//         article_type: "Mobile Magazine",
//         date: "APRIL 22, 2021"
//     },
//     {
//         article_title: "擺、脫繮繩的人們 - 記當代舞獨腳戲《牢∞性》",
//         article_type: "Mobile Magazine",
//         date: "APRIL 22, 2021"
//     },
//     {
//         article_title: "工藝復興和在地人文精神",
//         article_type: "Mobile Magazine",
//         date: "APRIL 22, 2021"
//     },
//     {
//         article_title: "The art school that gave early 20th-century Shanghai its ‘look’ The art school that gave early 20th-century Shanghai its ‘look’",
//         article_type: "Mobile Magazine",
//         date: "APRIL 22, 2021"
//     },
//     {
//         article_title: "死不足惜？ ― 從威廉士對永生的判斷談起",
//         article_type: "Mobile Magazine",
//         date: "APRIL 22, 2021"
//     },
//     {
//         article_title: "擺、脫繮繩的人們 - 記當代舞獨腳戲《牢∞性》",
//         article_type: "Mobile Magazine",
//         date: "APRIL 22, 2021"
//     },
//     {
//         article_title: "工藝復興和在地人文精神",
//         article_type: "Mobile Magazine",
//         date: "APRIL 22, 2021"
//     },
//     {
//         article_title: "The art school that gave early 20th-century Shanghai its ‘look’",
//         article_type: "Mobile Magazine",
//         date: "APRIL 22, 2021"
//     },
//     {
//         article_title: "死不足惜？ ― 從威廉士對永生的判斷談起",
//         article_type: "Mobile Magazine",
//         date: "APRIL 22, 2021"
//     },
//     {
//         article_title: "擺、脫繮繩的人們 - 記當代舞獨腳戲《牢∞性》",
//         article_type: "Mobile Magazine",
//         date: "APRIL 22, 2021"
//     }
// ]

export const MediaCoverage = () => {

    const { t } = useTranslation();
    const { setEnable, setShow, setTheme, setShowLogo } = useContext(NavBarContext);

    useEffect(() => {
        
        ReactGA.pageview(window.location.pathname + window.location.search);

        setEnable(true);
        setShow(true);
        setTheme("light");
        setShowLogo(true);
    });

    return (
        <>
            <Helmet>
                <title>{t('header.title.sober-thoughts')}</title>
                <meta name="description" content={t('header.des.sober-thoughts')} />
            </Helmet>
            
            {/* <Redirector /> */}
            <DisclaimerPopup />

            <PromotionPopup />

            <MenuThemeSetter theme="light" className="media-page">
                <div className="media-page-list-wrapper">
                    <ArticleListView targetUrl="sober-thought" title={t('footer.media')} />
                </div>
            </MenuThemeSetter>

            <GoToTop></GoToTop>
            <BackToTopButton />
        </>
    );
}