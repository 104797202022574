import React from "react";
import './ProductDetailJourneyPrologue.scss';

import { ReactComponent as PointerRight } from './../../../assets/images/icon-pointer-right.svg';
import { useTranslation } from "react-i18next";

export const ProductDetailJourneyPrologue = () => {

    const { t } = useTranslation();

    return (
        <div className="product-detail-journey-prologue-wrapper">
            <div className="product-detail-journey-prologue-caption">{t("product_detail_journey.prologue")}</div>
            <PointerRight className="product-detail-journey-prologue-pointer" />
        </div>
    )
}