import { FadableComponent } from 'components/FadableComponent/FadableComponent';
import './ProductCocktail.scss';

export const ProductCocktail = (props: {
    src: string,
    figcaption: string,
    recipe: {
        title: JSX.Element|string,
        content: JSX.Element|string,
    },
    tips: {
        title: JSX.Element|string,
        content: JSX.Element|string,
    }
}) => {

    const {src, figcaption, recipe, tips} = props;

    return (
        <div className={`product-cocktail`}>
            <div className="product-cocktail__content">
                <div className="product-cocktail__recipe">
                    {recipe.title}
                    {recipe.content}
                </div>
                <figure className="product-cocktail__photo">
                    <img src={src} alt={figcaption}/>
                    <FadableComponent className={`product-cocktail__caption pre-line title-xl font-shadow--6`}>{figcaption}</FadableComponent>
                </figure>
                <div className="product-cocktail__tips">
                    {tips.title}
                    {tips.content}
                </div>
            </div>
        </div>
    )
}