import './Story.scss'

import React from 'react'
import { FadableComponent } from 'components/FadableComponent/FadableComponent'

import bk1 from 'assets/images/about-us/about-us-bkg1 1.png'
import { MenuThemeSetter } from 'components/MenuThemeSetter/MenuThemeSetter'
import { useTranslation } from 'react-i18next'

export const StoryLifestyle = () => {

    const { t } = useTranslation();

    return (
        <div className="story-section fullpage">

            <MenuThemeSetter theme="light" className="lifestyle fullpage__page">
                <div className="lifestyle__content flex-layout flex-direction-column flex-center">

                    <div className="lifestyle__believe flex-layout justify-content-center align-items-start">
                        <FadableComponent>
                            <blockquote className="lifestyle-blockquote title-xs">{t("about_us_story.story_lifestyle")}</blockquote>
                        </FadableComponent>
                    </div>

                    <div className="lifestyle__background flex-layout flex-end">
                        <figure>
                            <img src={bk1} alt="About us background" />
                        </figure>
                    </div>
                </div>
            </MenuThemeSetter>
        </div>
    )
}