import { motion, useTransform, useViewportScroll } from "framer-motion";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { ProductDetailJourneyPrologue } from "../ProductDetailJourneyPrologue/ProductDetailJourneyPrologue";
import './ProductDetailJourney.scss'

// Bicycle guy
import BicycleGuyGif from "./../../../assets/images/product-detail/bicycle-guy-light.gif";
import { useMediaQuery } from "react-responsive";

import { CircleWaypointConfig, JourneySectionConfig } from "types/ProductDetailJourneyTypes";
import { JourneyPageContextProvider } from "components/Context/JourneyPageContext";

export const ProductDetailJourney = (props: { line: string, viewbox: string, journeySections: JourneySectionConfig[], waypoints: CircleWaypointConfig[] }) => {

    const totalPages = props.journeySections.length + 2;

    const scrollRef = useRef(null);
    const [journeyScrollX, setJourneyScrollX] = useState<number>(0);
    const [page, setPage] = useState<number>(0);

    const [scrollRange, setScrollRange] = useState(0);
    const { scrollYProgress } = useViewportScroll();

    const [scrollPercentageStart, setScrollPercentageStart] = useState<number | null>(null);
    const [scrollPercentageEnd, setScrollPercentageEnd] = useState<number | null>(null);

    useLayoutEffect(() => {
        scrollRef && setScrollRange((scrollRef.current as any).scrollWidth - window.innerWidth)
    }, [scrollRef]);

    useLayoutEffect(() => {
        // Get the distance from the start of the page to the element start
        const rect = (scrollRef.current as any).getBoundingClientRect();
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        const offsetStart = rect.top + scrollTop;
        const offsetEnd = offsetStart + totalPages * rect.height;

        // console.log(offsetStart, offsetEnd);

        const elementScrollStart = offsetStart / (document.body.clientHeight - window.innerHeight);
        const elementScrollEnd = offsetEnd / (document.body.clientHeight - window.innerHeight);

        setScrollPercentageStart(elementScrollStart);
        setScrollPercentageEnd(elementScrollEnd);
    }, [scrollRef]);

    const transform = useTransform(
        scrollYProgress,
        [scrollPercentageStart ?? 0, scrollPercentageEnd ?? 0],
        [0, -scrollRange]
    );

    useEffect(() => {
        //console.log(scrollPercentageStart, scrollPercentageEnd);
    }, [scrollPercentageStart, scrollPercentageEnd]);

    useEffect(() => {
        const unsubscribe = transform.onChange(s => { setJourneyScrollX(Math.abs(s)) });
        return () => unsubscribe();
    }, [])

    useEffect(() => {
        setPage(journeyScrollX / window.innerWidth);
    }, [journeyScrollX]);

    useEffect(() => {
        //console.log(Math.round(page * 100) / 100, Math.round(((page / (pages - 1)) * 10000) / 100) + "%");
    }, [page])


    return (
        <div id="product-detail-journey-parent" className="product-detail-journey-parent" style={{ height: `${(totalPages + 2.5) * 100}vh` }}>
            <div className="product-detail-journey-sticky-wrapper">
                <div className="product-detail-journey-relative-wrapper">

                    <JourneyPageContextProvider page={page} pageNames={props.journeySections.map(journeySection => journeySection.pageName)}>
                        <>
                            {
                                props.journeySections.map((journeySection, index) =>
                                    <JourneySection
                                        key={`journey_section_${index}`}
                                        toggle={(page >= index + 1) && ((props.journeySections.length === index + 1) ? true : page < index + 2)}
                                        blendColor={journeySection.blendColor}
                                        imageUrl={journeySection.backgroundImage}
                                        backgroundSize={journeySection.backgroundSize}
                                    >
                                        {journeySection.pageContent}
                                    </JourneySection>
                                )
                            }
                        </>
                    </JourneyPageContextProvider>

                    <JoruneyLine page={page} currentScrollProgressX={page / (totalPages - 1)} line={props.line} viewbox={props.viewbox} waypoints={props.waypoints} />

                    <motion.section
                        ref={scrollRef}
                        style={{ x: transform, width: `${totalPages * 100}%` }}
                        className="product-detail-journey-container"
                    >
                        <JourneyScrollSection totalPageAmount={totalPages}>
                            <ProductDetailJourneyPrologue />
                        </JourneyScrollSection>

                        {
                            [...new Array(props.journeySections.length - 1)].map(i => <JourneyScrollSection key={i} totalPageAmount={totalPages} children={undefined} />)
                        }

                    </motion.section>
                </div>
            </div>
        </div>
    )
}

const JourneyScrollSection = (props: { totalPageAmount: number, children: React.ReactNode }) => {

    return (
        <div className="product-detail-journey-scroll-section" style={{ width: `calc(100% / ${props.totalPageAmount})` }}>
            {props.children}
        </div>
    );
}

const JourneySection = (props: { children: React.ReactNode, toggle: boolean, blendColor: string, imageUrl?: string, backgroundSize?: string }) => {

    const config = {
        "show": {
            opacity: 1
        },
        "hide": {
            opacity: 0
        }
    }

    return (
        <motion.div
            className="product-detail-journey-section"
            initial={{ opacity: 0 }}
            variants={config}
            animate={props.toggle ? "show" : "hide"}
        >
            <div className="product-detail-journey-section-content-wrapper" style={
                props.imageUrl ?
                    {
                        background: `linear-gradient(${props.blendColor},${props.blendColor}), url(${props.imageUrl})`,
                        backgroundSize: props.backgroundSize ?? "contain",
                        backgroundBlendMode: "multiply",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat"
                    }
                    :
                    { backgroundColor: props.blendColor }
            }
            >
                {props.children}
            </div>
        </motion.div>
    );
}

const JoruneyLine = (props: { page: number, currentScrollProgressX: number, line: string, viewbox: string, waypoints: CircleWaypointConfig[] }) => {

    const isMedium = useMediaQuery({ query: "(min-width: 800px)" });
    const isLarge = useMediaQuery({ query: "(min-width: 1200px)" });

    const svgPathRef = useRef<SVGPathElement | null>(null);
    const bicycleGuyRef = useRef<HTMLImageElement | null>(null);

    const [length, setLength] = useState<number>();
    const [offsetStartHeight, setOffsetStartHeight] = useState<number>();

    const [bicycleStyle, setBicycleStyle] = useState<string>("");

    useEffect(() => {
        if (svgPathRef) {
            setLength(svgPathRef.current!.getTotalLength());

            setOffsetStartHeight((svgPathRef.current!.getPointAtLength(0).y) * window.innerWidth / 1440);
        }
    }, [svgPathRef]);

    useEffect(() => {
        if (bicycleGuyRef && bicycleGuyRef.current) {
            setBicycleStyle(`scale(${isLarge ? 1 : isMedium ? 0.75 : 0.5})`);
        }
    }, [bicycleGuyRef]);

    useEffect(() => {
        if (bicycleGuyRef.current && svgPathRef.current && length) {

            // Calculate rotation

            const scale = window.innerWidth / parseInt(props.viewbox.split(" ")[2]);

            const currentX = (svgPathRef.current!.getPointAtLength(length * (props.currentScrollProgressX)).x) * scale - 40;
            const currentY = (svgPathRef.current!.getPointAtLength(length * (props.currentScrollProgressX)).y) * scale + 15 * (isLarge ? 1 : isMedium ? 0.5 : 0.25);

            const nextX = (svgPathRef.current!.getPointAtLength(length * (props.currentScrollProgressX + 0.01)).x) * scale - 40;
            const nextY = (svgPathRef.current!.getPointAtLength(length * (props.currentScrollProgressX + 0.01)).y) * scale + 15 * (isLarge ? 1 : isMedium ? 0.5 : 0.25);

            const currentPoint = [currentX, currentY];
            const targetPoint = [nextX, nextY];

            const normalize = [targetPoint[0] - currentPoint[0], targetPoint[1] - currentPoint[1]];

            const deg = Math.atan2(normalize[1], normalize[0]) * (180 / Math.PI);

            setBicycleStyle(`translate(${currentX}px, ${currentY - (offsetStartHeight ?? 0)}px) rotate(${deg}deg) scale(${isLarge ? 1 : isMedium ? 0.75 : 0.5})`);
        }
    }, [props.currentScrollProgressX]);

    return (
        <>
            {/* <svg className="product-detail-journey-line-container" preserveAspectRatio="xMidYMax" viewBox="0 0 1440 189" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
            <svg className="product-detail-journey-line-container" preserveAspectRatio="xMidYMax" viewBox={props.viewbox} fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    ref={svgPathRef}
                    d={props.line}
                    stroke="white"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeOpacity="0.4"
                />

                {
                    props.waypoints.map((waypoint, index) =>
                        <CircleWaypoint
                            key={`circle_waypoint_${index}`}
                            x={waypoint.x}
                            y={waypoint.y}
                            toggle={(props.page > index + 1) && ((props.waypoints.length === index + 1) ? true : (props.page <= index + 2))}
                            activeColor={waypoint.activeColor}
                            isLarge={isLarge}
                            isMedium={isMedium}
                        />
                    )
                }

            </svg>

            {/* <BicycleGuy className="bicycle-guy" preserveAspectRatio="xMidYMax" ref={bicycleGuyRef} style={{ transformOrigin: "37px 101px" }} /> */}

            <img src={BicycleGuyGif} ref={bicycleGuyRef} className="bicycle-guy" style={{ transform: bicycleStyle }} />
        </>
    )
}

const CircleWaypoint = (props: { x: number, y: number, activeColor: string, isLarge: boolean, isMedium: boolean, toggle: boolean }) => (
    <motion.circle
        cx={props.x}
        cy={props.y}
        r={props.isLarge ? "5" : props.isMedium ? "7.5" : "10"}
        animate={
            props.toggle
                ? { fill: props.activeColor }
                : { fill: "#ffffff" }
        }
        transition={{ duration: 0.15 }}
    />
)