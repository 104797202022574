import "./NavigationBar.scss"
import { ReactComponent as PointerLeft } from './../../assets/images/icon-pointer-left.svg'
import { ReactComponent as PointerRight } from './../../assets/images/icon-pointer-right.svg'

import { ReactComponent as Facebook } from './../../assets/images/icon-facebook.svg'
import { ReactComponent as Instagram } from './../../assets/images/icon-instagram.svg'

import { ReactComponent as ShopIcon } from './../../assets/images/icon-shop.svg'
import { ReactComponent as MobileMenu } from './../../assets/images/icon-menu.svg'
import { ReactComponent as Close } from './../../assets/images/icon-close.svg'

import LogoLight from "./../../assets/images/logo-white.svg";
import LogoDark from "./../../assets/images/logo-black.svg";

import React, { useContext, useEffect, useState } from "react"
import { NavBarContext } from "../Context/NavBarContext"
import { AnimatePresence, motion } from "framer-motion"
import { NavigationMenu } from "components/NavigationMenu/NavigationMenu"
import { NavLink, useHistory, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { getLocaleUrl } from "helpers/i18n/locale"
import { useScrollState } from "hook/UseScrollState"
import { environment } from "environment/environment"
import { ShopEvent } from "helpers/google-analytic/ga"

export const NavigationBar = () => {
    const { i18n } = useTranslation();
    const { enable, show, theme, showLogo, setShow, menuOpen, setMenuOpen } = useContext(NavBarContext);

    const location = useLocation();
    const history = useHistory();
    const scrollDir = useScrollState();

    useEffect(() => {
        const locale = location.pathname.slice(1, 3);

        document.body.classList.remove(`locale-${getLocaleUrl(i18n)}`);
        if (locale === "zh") {
            i18n.changeLanguage("zh-HK");
        }
        else {
            i18n.changeLanguage("en-US");
        }
        document.body.classList.add(`locale-${getLocaleUrl(i18n)}`);

    }, [location.pathname]);

    useEffect(() => {
        if (scrollDir === "up" && !show) {
            setShow(true);
        }
        else if (scrollDir === "down" && show) {
            setShow(false);
        }
    }, [scrollDir]);

    const toggleMenuOpen = (open: boolean) => {
        // console.trace(open);
        setMenuOpen(open);
    }

    const toggleLanguage = () => {
        const locale = location.pathname.split("/")[1];
        document.body.classList.remove(`locale-${getLocaleUrl(i18n)}`);
        if (i18n.language === "en-US") {
            i18n.changeLanguage("zh-HK");
            history.push(location.pathname.replace(locale, "zh"));
        }
        else if (i18n.language === "zh-HK") {
            i18n.changeLanguage("en-US");
            history.push(location.pathname.replace(locale, "en"));
        }
        document.body.classList.add(`locale-${getLocaleUrl(i18n)}`);
    }

    const ShopHereSection = () => {
        return (
            <a href={`${environment.shopUrl}`} onClick={ShopEvent} target="_blank" rel="noreferrer noopener" className="shop-here-section">
                <PointerRight className={`nav-bar-icon point ${theme}`} />
                <span className={`nav-bar-text title-shadow left-text right-text ${theme}`}>SHOP</span>
            </a>
        )
    }

    const MenuSection = () => {
        return (
            <div className="menu-section" onClick={() => toggleMenuOpen(true)}>
                <span className={`nav-bar-text title-shadow right-text ${theme}`}>MENU</span>
            </div>
        )
    }

    const TranslationSection = () => {
        return (
            <div className="translation-section" onClick={toggleLanguage}>
                <span className={`nav-bar-text title-shadow right-text ${theme}`}>{i18n.language === "zh-HK" ? "EN" : "中"}</span>
            </div>
        )
    }

    const MobileTranslationSection = () => {
        return (
            <div onClick={toggleLanguage}>
                <span className={`nav-bar-text small-text right-text ${theme}`}>{i18n.language === "zh-HK" ? "EN" : "中"}</span>
            </div>
        )
    }

    const CloseMenuSection = () => {
        return (
            <div className="close-menu-section" onClick={() => toggleMenuOpen(false)}>
                <Close className={`nav-bar-icon light`} />
                <span className={`nav-bar-text title-shadow right-text light`}>CLOSE</span>
            </div>
        )
    }

    const MobileMenuSection = () => {
        return (
            <div onClick={() => toggleMenuOpen(true)}>
                <MobileMenu className={`nav-bar-mobile-icon ${theme}`} />
            </div>
        )
    }

    const MobileShopSection = () => {
        return (
            <a href={`${environment.shopUrl}`} onClick={ShopEvent} target="_blank" rel="noreferrer noopener">
                <ShopIcon className={`nav-bar-mobile-icon ${theme}`} />
            </a>
        )
    }

    const CloseMobileMenuSection = () => {
        return (
            <div>
                <Close className={`nav-bar-mobile-icon dark`} />
            </div>
        )
    }

    const LogoSection = () => {
        return (
            <NavLink to={`/${getLocaleUrl(i18n)}/`} onClick={() => { setMenuOpen(false) }}>
                <img src={theme === "light" ? LogoDark : LogoLight} className="nav-bar-logo" alt="" />
            </NavLink>
        )
    }

    const SocialMediaSection = () => {
        return (
            <div className="social-media-section">
                
                <a href={`${environment.facebookUrl}`}>
                    <Facebook className={`nav-bar-icon ${theme}`} id="facebook" />
                </a>
                <a href={`${environment.instagramUrl}`}>
                    <Instagram className={`nav-bar-icon ${theme}`} id="instagram" />
                </a>
            </div>
        )
    }

    const navConfig = {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: -50 }
    };

    const mobileNavConfig = {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: -50 }
    };


    return (
        <>
            <div>
                <AnimatePresence>
                    {enable && show && <motion.div className="nav-bar"
                        initial={{ y: "-200%", opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: "-200%", opacity: 0 }}
                        transition={{ duration: 0.5, type: "spring" }}
                    >
                        <motion.div
                            className="nav-section-wrapper flex-left"
                            variants={navConfig}
                            animate={menuOpen ? "hidden" : "visible"}
                            transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.4 }}
                            initial={false}
                        >
                            <MenuSection />
                            <TranslationSection />
                        </motion.div>

                        <motion.div
                            className="nav-section-wrapper"
                            variants={navConfig}
                            animate={showLogo ? "visible" : "hidden"}
                            transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.4 }}
                            initial={false}
                        >
                            <LogoSection />
                        </motion.div>

                        <motion.div
                            className="nav-section-wrapper flex-right"
                            variants={navConfig}
                            animate={menuOpen ? "visible" : "hidden"}
                            transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.4 }}
                            initial={false}
                        >
                            <CloseMenuSection />
                        </motion.div>

                        <motion.div
                            className="nav-section-wrapper flex-right"
                            variants={navConfig}
                            animate={menuOpen ? "hidden" : "visible"}
                            transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.4 }}
                            initial={false}
                        >
                            <ShopHereSection />
                            <SocialMediaSection />
                        </motion.div>

                        <motion.div
                            className="mobile-menu-section mobile-menu-section--left flex-layout flex-start"
                            variants={mobileNavConfig}
                            animate={menuOpen ? "hidden" : "visible"}
                            transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.4 }}
                            initial={false}
                        >
                            <MobileMenuSection />
                            <MobileTranslationSection />
                        </motion.div>

                        <motion.div
                            className="mobile-menu-section mobile-menu-section--right"
                            variants={mobileNavConfig}
                            animate={menuOpen ? "hidden" : "visible"}
                            transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.4 }}
                            initial={false}
                        >
                            <MobileShopSection />
                        </motion.div>

                        <motion.div
                            className="mobile-menu-section mobile-menu-section--right" onClick={() => toggleMenuOpen(false)}
                            variants={mobileNavConfig}
                            animate={menuOpen ? "visible" : "hidden"}
                            transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.4 }}
                            initial={false}
                        >
                            <CloseMobileMenuSection />
                        </motion.div>

                    </motion.div>}
                </AnimatePresence>

                <NavigationMenu />
            </div>
        </>
    )
}