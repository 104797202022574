import React, { useLayoutEffect, useRef } from "react"
import './ScrollingAnimator.scss'

import { useViewportScroll } from "framer-motion";

export const ScrollingAnimator = (props: {
    children: any,
    frameDistance: number,
    totalFrame: number,
    onFrameChange?: (currentFrame: number) => void,
    onFrameProgressChange?: (frameProgress: number) => void,
    debug?: boolean,
}) => {
    const {children, frameDistance, totalFrame, onFrameChange, onFrameProgressChange, debug} = props;

    const onFrameChangeRef = useRef(onFrameChange);
    const onFrameProgressChangeRef = useRef(onFrameProgressChange);
    const sectionRef = useRef<any>(null);
    const lastFrame = useRef(-1);

    const {scrollY} = useViewportScroll();

    useLayoutEffect(() => {
        const unsubscribe = scrollY.onChange((v) => {
            const element = sectionRef.current;
            const elementRect = element.getBoundingClientRect();

            if (elementRect.top > 0 && lastFrame.current >= 0) {
                if (onFrameChangeRef.current) onFrameChangeRef.current(0);
                if (onFrameProgressChangeRef.current) onFrameProgressChangeRef.current(0);
                lastFrame.current = 0;
                return;
            }
            if (elementRect.top < -elementRect.height && lastFrame.current < totalFrame) {
                if (onFrameChangeRef.current) onFrameChangeRef.current(totalFrame-1);
                if (onFrameProgressChangeRef.current) onFrameProgressChangeRef.current(1);
                lastFrame.current = totalFrame-1;
                return;
            }
            if (elementRect.top > 0 || elementRect.top < -elementRect.height) return; // out of view
            
            const curFrame = Math.floor(Math.abs(elementRect.top) / frameDistance);
            const frameProgress = Math.abs(elementRect.top) / frameDistance % 1;

            if (curFrame > totalFrame - 1) return;
            
            if (onFrameChangeRef.current && lastFrame.current !== curFrame) onFrameChangeRef.current(curFrame);
            if (onFrameProgressChangeRef.current) onFrameProgressChangeRef.current(frameProgress);
            lastFrame.current = curFrame;
        });

        // Initiate current frame
        scrollY.set(0);

        return () => {
            unsubscribe();
        };
    }, [scrollY])

    return (
        <div ref={sectionRef} className={`scrolling-animator`}>
            {children}
            <div className={`animation-scroll-buffer ${debug? 'debug':''}`} style={{height: `${frameDistance * (totalFrame - 0.5)}px`} }></div>
        </div>
    )
}