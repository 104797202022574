import './LinerComponent.scss'
import { useLayoutEffect, useRef } from "react"
import { useViewportScroll } from 'framer-motion';

export const LinerComponent = (props: {
    offset: number,
    className?: string,
    style?: React.CSSProperties | undefined
}) => {

    const containerRef = useRef<any>(null);
    const lineRef = useRef<any>(null);
    const {scrollY} = useViewportScroll();

    useLayoutEffect(() => {
        const unsub = scrollY.onChange(() => {
            const rect = containerRef.current.getBoundingClientRect() as DOMRect;
            if (rect.top <= window.innerHeight - props.offset) {
                (lineRef.current as HTMLDivElement).style.height = `${window.innerHeight - props.offset - rect.top}px`;
            } else if ((lineRef.current as HTMLDivElement).clientHeight !== 0) {
                (lineRef.current as HTMLDivElement).style.height = `0`;
            }
        })
        return () => {
            unsub();
        };
    }, [])

    return (
        <div ref={containerRef} className={`liner-component ${props.className}`} style={props.style}>
            <div ref={lineRef} className="liner-component__line"></div>
        </div>
    )
}