import './HomeSection3.scss'

import { useRef, useState } from 'react';
import { CustomValueType, motion } from 'framer-motion';
import { ScrollingAnimator } from 'components/ScrollingAnimator/ScrollingAnimator';
import { Frame } from 'components/ScrollingAnimator/Frame/Frame';

import aging1 from 'assets/images/home/aging-1.png';
import aging2 from 'assets/images/home/aging-2.png';
import aging3 from 'assets/images/home/aging-3.png';
import aging4 from 'assets/images/home/aging-4.png';
import aging5 from 'assets/images/home/aging-5.png';
import aging6 from 'assets/images/home/aging-6.png';
import aging7 from 'assets/images/home/aging-7.png';
import aging8 from 'assets/images/home/aging-8.png';
import liquidDrop from 'assets/images/home/liquid-drop2.png';
import glass1 from 'assets/images/home/glass-1.png';
import glass2 from 'assets/images/home/glass-2.png';
import { ReactComponent as PointerRight } from 'assets/images/icon-pointer-right.svg'

import ingredients1 from 'assets/images/home/ingredients/A_1.png';
import ingredients2 from 'assets/images/home/ingredients/B_1.png';
import ingredients3 from 'assets/images/home/ingredients/C_1.png';
import ingredients4 from 'assets/images/home/ingredients/D_1.png';
import ingredients5 from 'assets/images/home/ingredients/E_1.png';
import ingredients6 from 'assets/images/home/ingredients/F_1.png';
import ingredients7 from 'assets/images/home/ingredients/G_1.png';
import ingredients8 from 'assets/images/home/ingredients/H_1.png';
import ingredients9 from 'assets/images/home/ingredients/I_2.png';
import ingredients10 from 'assets/images/home/ingredients/J_1.png';
import ingredients11 from 'assets/images/home/ingredients/K_1.png';
import ingredients12 from 'assets/images/home/ingredients/home-ingredients-magnolia-1 1.png';
import ingredients13 from 'assets/images/home/ingredients/home-ingredients-magnolia-2.png';
import ingredients14 from 'assets/images/home/ingredients/home-ingredients-magnolia-3.png';
import ingredients15 from 'assets/images/home/ingredients/home-ingredients-magnolia-3-1.png';
import ingredients16 from 'assets/images/home/ingredients/home-ingredients-magnolia-3-2.png';
import ingredients17 from 'assets/images/home/ingredients/home-ingredients-magnolia-4.png';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getLocaleUrl } from 'helpers/i18n/locale';

export const HomeSection3 = () => {

    const { t, i18n } = useTranslation();

    const agingSrc = [
        aging1,
        aging2,
        aging3,
        aging4,
        aging5,
        aging6,
        aging7,
    ];

    const [currentFrame, setCurrentFrame] = useState(0);

    const contentRef = useRef<any>(null);

    return (
        <>
            <section className="homesection3 fullpage__page">

                <div ref={contentRef} className="content">
                    <ScrollingAnimator
                        frameDistance={window.innerHeight}
                        totalFrame={10}
                        onFrameChange={(f) => {
                            setCurrentFrame(f);
                        }}
                    >
                        <div className="bottle">
                            <div className="transform"
                                style={{
                                    transform: `${[0].includes(currentFrame) ? "translate(0, 30vh)" :
                                        currentFrame >= agingSrc.length + 2 ? "translate(0, -70vh)" :
                                            currentFrame >= agingSrc.length ? "translate(0, -55vh)" :
                                                "none"}`
                                }}
                            >
                                {agingSrc.map((value, index) => {
                                    return (
                                        <Frame
                                            key={index}
                                            currentFrame={currentFrame}
                                            activeFrames={[index, index + 1]}
                                            active={index === agingSrc.length - 1 && currentFrame >= index && currentFrame < agingSrc.length + 2}
                                        >
                                            <motion.img src={value} alt="Bottle"
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 1 }}
                                                exit={{ opacity: 0 }}
                                            />
                                        </Frame>
                                    )
                                })}
                                <Frame
                                    currentFrame={currentFrame}
                                    active={currentFrame === agingSrc.length + 1}
                                >
                                    <motion.img src={aging8} alt="Bottle"
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                    />
                                </Frame>

                                {/* Medicines */}
                                {
                                    ingredientConfigs.map((value, index) => {
                                        return (
                                            <Frame key={index}
                                                currentFrame={currentFrame}
                                                activeFrames={[0]}
                                            >
                                                <motion.img className={`medicines`} src={value.img} alt="Mediciens"
                                                    initial={{ opacity: 0, y: "-20vh" }}
                                                    animate={{ opacity: 1, y: "-130vh" }}
                                                    exit={{ opacity: 0, y: "-20vh" }}
                                                    style={{ x: value.x, width: value.width }}
                                                    transition={{ delay: index * 0.05, duration: 0.8, ease: "easeInOut" }}
                                                />
                                            </Frame>
                                        );
                                    })
                                }
                                <Frame
                                    currentFrame={currentFrame}
                                    activeFrames={[0]}
                                >
                                    <motion.img className={`medicines`} src={ingredients12} alt="Mediciens"
                                        initial={{ opacity: 0, y: "-20vh" }}
                                        animate={{ opacity: 1, y: "-116vh" }}
                                        exit={{ opacity: 0, y: "-20vh" }}
                                        style={{ x: "0", width: "17vh" }}
                                        transition={{ delay: ingredientConfigs.length * 0.05, duration: 0.8, ease: "easeInOut" }}
                                    />
                                </Frame>
                                {/* {
                                    img: ingredients12,
                                    width: "17vh",
                                    x: "0vh",
                                    y: "-116vh",
                                }, */}
                                {/* end of Medicines */}

                                <Frame
                                    currentFrame={currentFrame}
                                    activeFrames={[agingSrc.length, agingSrc.length + 1]}
                                >
                                    <motion.img className={`glass`} src={glass1} alt="Glass"
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                    />
                                </Frame>
                                <Frame
                                    currentFrame={currentFrame}
                                    activeFrames={[agingSrc.length + 1]}
                                >
                                    <motion.img className={`liquidDrop`} src={liquidDrop} alt=""
                                        initial={{ opacity: 0, scaleY: 1 }}
                                        animate={{ opacity: 1, scaleY: 1 }}
                                        exit={{ opacity: 0, scaleY: 1 }}
                                        style={{ width: "4vh", x: "-0.5vh", y: "20.5vh" }}
                                        transition={{ duration: .5 }}
                                    />
                                </Frame>
                                <Frame
                                    currentFrame={currentFrame}
                                    activeFrames={[agingSrc.length, agingSrc.length + 1]}
                                >
                                    <motion.img className={`glass`} src={glass1} alt="Glass"
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 0.5 }}
                                        exit={{ opacity: 0 }}
                                    />
                                </Frame>
                                <Frame
                                    currentFrame={currentFrame}
                                    activeFrames={[agingSrc.length + 2]}
                                >
                                    <motion.img className={`glass`} src={glass2} alt="Glass"
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                    />
                                </Frame>
                                <Frame
                                    currentFrame={currentFrame}
                                    activeFrames={[agingSrc.length + 2]}
                                >
                                    <motion.div className="action"
                                        initial={{ opacity: 0, y: "55vh" }}
                                        animate={{ opacity: 1, y: "50vh" }}
                                        exit={{ opacity: 0, y: "55vh" }}
                                        transition={{ duration: 0.6 }}
                                    >
                                        <NavLink to={`/${getLocaleUrl(i18n)}/product`}><h5><strong>{t("home_section_3.product_link")}</strong> <PointerRight /></h5></NavLink>
                                    </motion.div>
                                </Frame>
                            </div>
                        </div>

                    </ScrollingAnimator>
                </div>
            </section>
        </>
    )
}

const ingredientConfigs: HomeIngredientConfig[] = [
    {
        img: ingredients4,
        width: "7vh",
        x: "2vh",
        y: "-60vh",
    },
    {
        img: ingredients7,
        width: "12vh",
        x: "3vh",
        y: "-67vh",
    },
    {
        img: ingredients1,
        width: "10vh",
        x: "-7vh",
        y: "-70vh",
    },
    {
        img: ingredients6,
        width: "8vh",
        x: "5vh",
        y: "-70vh",
    },
    {
        img: ingredients3,
        width: "5vh",
        x: "4vh",
        y: "-80vh",
    },
    {
        img: ingredients11,
        width: "7vh",
        x: "-3vh",
        y: "-81vh",
    },
    {
        img: ingredients9,
        width: "5vh",
        x: "3vh",
        y: "-81vh",
    },
    {
        img: ingredients8,
        width: "9vh",
        x: "7vh",
        y: "-82vh",
    },
    {
        img: ingredients5,
        width: "9vh",
        x: "-4vh",
        y: "-86vh",
    },
    {
        img: ingredients2,
        width: "10vh",
        x: "0vh",
        y: "-90vh",
    },
    {
        img: ingredients10,
        width: "9vh",
        x: "-7vh",
        y: "-97vh",
    },
    {
        img: ingredients16,
        width: "3vh",
        x: "8vh",
        y: "-97vh",
    },
    {
        img: ingredients14,
        width: "4vh",
        x: "10vh",
        y: "-102vh",
    },
    {
        img: ingredients15,
        width: "4vh",
        x: "0vh",
        y: "-106vh",
    },
    {
        img: ingredients13,
        width: "4vh",
        x: "-10vh",
        y: "-110vh",
    },
    {
        img: ingredients17,
        width: "3vh",
        x: "7vh",
        y: "-110vh",
    },
    // {
    //     img: ingredients12,
    //     width: "17vh",
    //     x: "0vh",
    //     y: "-116vh",
    // },
]

interface HomeIngredientConfig {
    img: string,
    width: string | number | CustomValueType | undefined,
    x: string | number | CustomValueType | undefined,
    y: string | number | CustomValueType | undefined,
}
