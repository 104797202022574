import { motion } from "framer-motion"
import FloralImage from "./../../../assets/images/product-detail/journey-floral-image.png";
import FloralDeco from "./../../../assets/images/product-detail/floral-deco.png";
import { useContext, useEffect, useState } from "react";
import { JourneyPageContext } from "components/Context/JourneyPageContext";
import { useTranslation } from "react-i18next";

export const FloralSection = () => {
    const { t } = useTranslation();
    const { page, pageNames } = useContext(JourneyPageContext);

    const [thisPageIndex, setThisPageIndex] = useState<number>(0);
    const [internalPage, setInternalPage] = useState<number>(0);

    useEffect(() => {
        setInternalPage(page - thisPageIndex);
    }, [page]);

    useEffect(() => {
        if (pageNames !== []) {
            setThisPageIndex(pageNames.indexOf("Floral") + 1)
        }
    }, [pageNames]);

    const config = {
        "show": {
            opacity: 1
        },
        "hide": {
            opacity: 0
        }
    }

    const middleConfig = {
        "show": {
            y: 0,
            scale: 1,
            transition: {
                type: "tween",
                ease: "easeOut",
                duration: 0.3
            }
        },
        "hide": {
            y: 0,
            scale: 0,
            transition: {
                type: "tween",
                ease: "easeIn",
                duration: 0.3
            }
        }
    }

    return (
        <>
            <motion.div
                className="product-detail-journey-section-content-fade-in small-width"
                initial="hide"
                variants={middleConfig}
                animate={internalPage >= 0 && internalPage <= 1 ? "show" : "hide"}
                style={{ backgroundImage: `url(${FloralDeco})` }}
            >
            </motion.div>

            <motion.div
                className="product-detail-journey-section-content"
                initial="hide"
                variants={config}
                animate={internalPage >= 0.25 && internalPage <= 0.75 ? "show" : "hide"}
            >
                <img src={FloralImage} className="product-detail-journey-section-floral-image" />
                <div className="product-detail-journey-section-title-text floral-text">
                    {t("product_detail_journey.floral")}
                </div>
            </motion.div>
        </>
    )
}