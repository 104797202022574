import React, { useContext, useEffect, useRef } from "react"
import './HomeSection1.scss'
import logo from "assets/images/logo-full-color.svg"
import { useViewportScroll } from "framer-motion";
import { NavBarContext } from "components/Context/NavBarContext";
import { FadableComponent } from "components/FadableComponent/FadableComponent";
import { useTranslation } from "react-i18next";

export const HomeSection1 = () => {

    const { t } = useTranslation();

    const logoRef = useRef<HTMLImageElement>(null);

    const { scrollY } = useViewportScroll();
    const { showLogo, setEnable, setTheme, setShowLogo } = useContext(NavBarContext);

    const logoStateRef = useRef(showLogo);

    useEffect(() => {
        logoStateRef.current = showLogo
    }, [showLogo]);

    useEffect(() => {
        const unsubscribe = scrollY.onChange(handleScroll);

        setEnable(true);
        setTheme("dark");
        setShowLogo(false);

        return () => {
            unsubscribe();
        }
    }, []);

    const handleScroll = (y: number) => {

        if (!logoRef.current) return;

        if (y > logoRef.current.offsetHeight + 40) {
            if (!logoStateRef.current) {
                setShowLogo(true);
            }
        }
        else {
            if (logoStateRef.current) {
                setShowLogo(false);
            }
        }
    }
    return (
        <>
            <section className="homesection1 fullpage__page">
                <div className="content">
                    <FadableComponent>
                        <figure ref={logoRef} className="logo">
                            <img src={logo} alt="Magnolia logo" />
                        </figure>
                    </FadableComponent>

                    <FadableComponent delay={0.5}>
                        <h6 className="subtitle__darkgold title-bodoniModa">{t("home_section_1.subtitle_darkgold")}</h6>
                        <h6 className="subtitle title-bodoniModa">{t("home_section_1.subtitle")}</h6>  
                    </FadableComponent>
                </div>
            </section>
        </>
    )
}