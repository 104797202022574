import { DecoStar } from 'components/DecoStar/DecoStar';
import { Galaxy } from 'components/Galaxy/Galaxy';
import { HorizontalPage, HorizontalScroller } from 'components/HorizontalScroller/HorizontalScroller';
import { MenuThemeSetter } from 'components/MenuThemeSetter/MenuThemeSetter';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SVGAnimateExperimentalElement } from 'types/ThemeTypes';
import './Introduction.scss'

export const Introduction = () => {

    const { t } = useTranslation();

    const pageSize = 5;

    // 2521.39
    const [lineOffset, setLineOffset] = useState(0);
    const [progress, setProgress] = useState(0);
    const [curPage, setCurPage] = useState(0);

    const starToSquareAnimRef = useRef<SVGAnimateExperimentalElement | null>(null);
    const squareToStarAnimRef = useRef<SVGAnimateExperimentalElement | null>(null);
    const squareToCircleAnimRef = useRef<SVGAnimateExperimentalElement | null>(null);
    const circleToSquareAnimRef = useRef<SVGAnimateExperimentalElement | null>(null);

    const [shapeAnimationState, setShapeAnimationState] = useState<"square" | "circle" | "star">("star");

    const getPageProgress = (page: number) => {
        if (page < 0 || page >= pageSize - 1) return 0;

        return Math.max(Math.min(progress * pageSize - page, 1), 0);
    };

    useEffect(() => {
        // console.log(shapeAnimationState);
        if (shapeAnimationState === "star" && getPageProgress(2) >= 0.4 && starToSquareAnimRef && starToSquareAnimRef.current) {
            setShapeAnimationState("square");
            starToSquareAnimRef!.current!.beginElement();
        }
        else if (shapeAnimationState === "square" && getPageProgress(2) < 0.4 && squareToStarAnimRef && squareToStarAnimRef.current) {
            setShapeAnimationState("star");
            squareToStarAnimRef!.current!.beginElement();
        }
        else if (shapeAnimationState === "square" && getPageProgress(2) >= 0.7 && squareToCircleAnimRef && squareToCircleAnimRef.current) {
            setShapeAnimationState("circle");
            squareToCircleAnimRef!.current!.beginElement();
        }
        else if (shapeAnimationState === "circle" && getPageProgress(2) < 0.7 && circleToSquareAnimRef && circleToSquareAnimRef.current) {
            setShapeAnimationState("square");
            circleToSquareAnimRef!.current!.beginElement();
        }
    }, [progress])

    return (
        <div id={"introduction-line"}>
            <MenuThemeSetter theme="dark" className={`introduction ${curPage >= 3 && getPageProgress(3) >= 0.8 ? 'introduction--light-theme' : ''}`}>

                <HorizontalScroller
                    pageSize={pageSize}
                    onProgressChange={(page, progress) => {
                        setCurPage(page)
                        setProgress(progress);
                        setLineOffset(progress * pageSize)
                    }}
                >
                    <HorizontalPage pageSpan={2}>
                        <AnimatePresence>
                            {[0].includes(curPage) &&
                                <motion.div

                                    className={'introduction__line'}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                >
                                    <svg className="path" viewBox="0 0 2086 397" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <defs>
                                            <path d="M-435 396.441H136.19C391.85 393.811 446.47 304.091 662.19 81.2213C890.79 -154.959 994.61 208.711 1299.71 176.831C1299.71 176.831 1396.32 171.491 1543.34 81.4813C1712.15 -21.8687 1913.34 222.481 2086.39 222.481" id="p1" />
                                        </defs>

                                        <path
                                            className="line"
                                            d="M-435 396.441H136.19C391.85 393.811 446.47 304.091 662.19 81.2213C890.79 -154.959 994.61 208.711 1299.71 176.831C1299.71 176.831 1396.32 171.491 1543.34 81.4813C1712.15 -21.8687 1913.34 222.481 2086.39 222.481"
                                            stroke="white"
                                            strokeMiterlimit="10"
                                        />

                                        <text fill="white" className="title-m title-cormorant">
                                            <textPath id="text1" offset={5} startOffset={`${lineOffset * 75 + 25}%`} xlinkHref="#p1">
                                                {t("about_us_intro.slogan_1")}
                                            </textPath>
                                        </text>
                                    </svg>
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        transition={{ duration: 0 }}
                                    >
                                        <DecoStar />
                                    </motion.div>
                                </motion.div>}
                        </AnimatePresence>
                        <div className="call-to-scroll title-xs">Scroll For More</div>
                    </HorizontalPage>

                    <HorizontalPage>
                    </HorizontalPage>

                    <HorizontalPage>
                    </HorizontalPage>

                    <HorizontalPage>
                    </HorizontalPage>
                </HorizontalScroller>
                <AnimatePresence>
                    {curPage >= 1 && getPageProgress(3) < 0.5 &&
                        <motion.div
                            className={'introduction__star'}
                            initial={{ opacity: 0, scale: 0 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0 }}
                            transition={{ duration: 0 }}
                        >
                            <svg className={`deco-star ${`deco-star--`}`} width="57" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M 34.94 0 C 34.94 24.84 63.37 34.82 63.37 34.82 C 63.37 34.82 34.94 46.71 34.94 69.63 L 34.94 69.63 C 34.94 47.06 6.5 34.82 6.5 34.82 C 6.5 34.82 34.94 25.83 34.94 0"
                                >
                                    <animate
                                        ref={starToSquareAnimRef}
                                        attributeName="d"
                                        from="M 34.94 0 C 34.94 24.84 63.37 34.82 63.37 34.82 C 63.37 34.82 34.94 46.71 34.94 69.63 L 34.94 69.63 C 34.94 47.06 6.5 34.82 6.5 34.82 C 6.5 34.82 34.94 25.83 34.94 0"
                                        to="M 0 0 C 23.333 0 46.667 0 70 0 C 70 23.179 70 46.358 70 69.536 L 70 70 C 46.667 70 23.333 70 0 70 C 0 46.667 0 23.333 0 0"
                                        dur="0.2s"
                                        begin="indefinite"
                                        fill="freeze"
                                    />
                                    <animate
                                        ref={squareToStarAnimRef}
                                        attributeName="d"
                                        from="M 0 0 C 23.333 0 46.667 0 70 0 C 70 23.179 70 46.358 70 69.536 L 70 70 C 46.667 70 23.333 70 0 70 C 0 46.667 0 23.333 0 0"
                                        to="M 34.94 0 C 34.94 24.84 63.37 34.82 63.37 34.82 C 63.37 34.82 34.94 46.71 34.94 69.63 L 34.94 69.63 C 34.94 47.06 6.5 34.82 6.5 34.82 C 6.5 34.82 34.94 25.83 34.94 0"
                                        dur="0.2s"
                                        begin="indefinite"
                                        fill="freeze"
                                    />
                                    <animate
                                        ref={squareToCircleAnimRef}
                                        attributeName="d"
                                        from="M 0 25.879 C 0 40.586 0 55.293 0 70 C 0 70 0 70 0 70 C 23.333 70 46.667 70 70 70 C 70 69.845 70 69.691 70 69.536 C 70 57.455 70 45.374 70 33.293 C 70 22.196 70 11.098 70 0 C 58.334 0 46.667 0 35 0 C 23.333 0 11.666 0 0 0 C 0 8.626 0 17.252 0 25.879"
                                        to="M 0 35 C 0 44.279 3.69 53.187 10.251 59.749 C 16.813 66.31 25.721 70 35 70 C 35 70 35 70 35 70 C 44.279 70 53.187 66.31 59.749 59.749 C 66.31 53.187 70 44.279 70 35 C 70 25.721 66.31 16.813 59.749 10.251 C 53.187 3.69 44.279 0 35 0 C 25.721 0 16.813 3.69 10.251 10.251 C 3.69 16.813 0 25.721 0 35"
                                        dur="0.2s"
                                        begin="indefinite"
                                        fill="freeze"
                                    />
                                    <animate
                                        ref={circleToSquareAnimRef}
                                        attributeName="d"
                                        from="M 0 35 C 0 44.279 3.69 53.187 10.251 59.749 C 16.813 66.31 25.721 70 35 70 C 35 70 35 70 35 70 C 44.279 70 53.187 66.31 59.749 59.749 C 66.31 53.187 70 44.279 70 35 C 70 25.721 66.31 16.813 59.749 10.251 C 53.187 3.69 44.279 0 35 0 C 25.721 0 16.813 3.69 10.251 10.251 C 3.69 16.813 0 25.721 0 35"
                                        to="M 0 25.879 C 0 40.586 0 55.293 0 70 C 0 70 0 70 0 70 C 23.333 70 46.667 70 70 70 C 70 69.845 70 69.691 70 69.536 C 70 57.455 70 45.374 70 33.293 C 70 22.196 70 11.098 70 0 C 58.334 0 46.667 0 35 0 C 23.333 0 11.666 0 0 0 C 0 8.626 0 17.252 0 25.879"
                                        dur="0.2s"
                                        begin="indefinite"
                                        fill="freeze"
                                    />
                                </path>
                            </svg>

                            {curPage === 1 &&
                                <motion.div
                                    className={'introduction__subtitle'}
                                    initial={{ opacity: 0, y: "0vh" }}
                                    animate={{ opacity: 1, y: "-10vh" }}
                                    exit={{ opacity: 0, y: "0vh" }}
                                    transition={{ delay: 0.25, duration: 0.5 }}
                                >
                                    <h5 className="title-cormorant">{t("about_us_intro.slogan_2")}</h5>
                                </motion.div>}

                            {/* Add Galaxy Component */}
                            {curPage === 1 && <motion.div
                                className={`introduction__galaxy`}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ delay: 0.5, duration: 1 }}
                            >
                                <Galaxy />
                            </motion.div>}

                            {curPage >= 2 &&
                                <motion.div
                                    className={'introduction__subtitle'}
                                    initial={{ opacity: 0, y: "20vh" }}
                                    animate={{ opacity: 1, y: "10vh" }}
                                    exit={{ opacity: 0, y: "20vh" }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <h5 className="title-cormorant">{t("about_us_intro.slogan_3")}</h5>
                                </motion.div>}
                        </motion.div>}
                </AnimatePresence>

                <AnimatePresence>
                    {curPage === 3 && <motion.div
                        className={'introduction__overlay-cover'}
                    >
                        <div
                            className={`circle ${getPageProgress(3) >= 0.7 ? "active" : ""}`}
                            style={{ fontSize: `${getPageProgress(3) * getPageProgress(3) * 200}vw` }}
                        ><div className="blob"></div></div>

                        {(getPageProgress(3) >= 0.5) && <motion.div
                            className={'introduction__subtitle'}
                            initial={{ opacity: 0, y: "10vh" }}
                            animate={{ opacity: 1, y: "0vh" }}
                            exit={{ opacity: 0, y: "-10vh" }}
                        >
                        </motion.div>}
                    </motion.div>}
                </AnimatePresence>
            </MenuThemeSetter>
        </div>
    )
}
