import { motion, useViewportScroll } from "framer-motion";
import { useWindowSize } from "hook/UseWindowResize";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import './HorizontalScroller.scss';

export const HorizontalScroller = (props: {
    children?: React.ReactNode,
    pageSize: number,
    onProgressChange?: (page: number, progress: number) => void
}) => {
    const [scrollHeightBuff, setScrollHeightBuff] = useState(0);
    const [viewportWidth, setViewportWidth] = useState(0);
    const [scrollProgress, setScrollProgress] = useState(0);

    const scrollerRef = useRef<any>(null);
    const viewportRef = useRef<any>(null);
    const fullyViewed = useRef<boolean>(false);

    const {scrollY} = useViewportScroll();
    const windowResize = useWindowSize();

    useEffect(() => {
        setScrollHeightBuff(() => windowResize.height * (props.pageSize - 1));
        setViewportWidth(() => windowResize.width * props.pageSize);
    }, [windowResize])

    useLayoutEffect(() => {
        const unsubscribe = scrollY.onChange((v) => {
            const rect = scrollerRef.current.getBoundingClientRect();

            if (fullyViewed.current === (-rect.top < 0 || -rect.top > rect.height)) {
                fullyViewed.current = !fullyViewed.current;
            }

            if (fullyViewed.current) {
                const top = Math.min(Math.max(-rect.top, 0), rect.height);
                const scrollProgress = top / rect.height;
                setScrollProgress(scrollProgress);
                if (props.onProgressChange) {
                    const curPage = Math.floor(scrollProgress * props.pageSize);
                    props.onProgressChange(curPage, scrollProgress);
                }
            }
        });
        scrollY.set(0);
        return () => {
           unsubscribe(); 
        };
    }, [])

    return (
        <div ref={scrollerRef} className="horizontal-scroller">
            <div className="horizontal-scroller__sticky">
                <div className="horizontal-scroller__scroller-container">
                    <motion.div ref={viewportRef} 
                        className="horizontal-scroller__viewport" 
                        style={{width: `${viewportWidth}px`, x: (-scrollProgress * viewportWidth)}}
                    >
                        {props.children}
                    </motion.div>
                </div>
            </div>
            <div className="horizontal-scroller__scroll-buffer" style={{height: `${scrollHeightBuff}px`}}></div>
        </div>
    )
}

export const HorizontalPage = (props: {
    children?: React.ReactNode,
    pageSpan?: number,
    style?: React.CSSProperties | undefined,
}) => {
    return (
        <div className="horizontal-scroller__page" style={props.pageSpan ? {...props.style, width: `${props.pageSpan * 100}%`} : {...props.style}}>
            {props.children}
        </div>
    )
}