import "./Product.scss";
import React, { useContext, useEffect } from "react";
import { NavBarContext } from "../../components/Context/NavBarContext";

import magnoliaProduct from 'assets/images/product/bottle-magnolia-cps.png';
import roselleProduct from 'assets/images/product/bottle-roselle-cps.png';
import magnoliaTitleChi from 'assets/images/product/title-magnolia-chi.png';
import magnoliaTitleEng from 'assets/images/product/title-magnolia-eng.png';
import roselleTitleChi from 'assets/images/product/title-roselle-chi.png';
import roselleTitleEng from 'assets/images/product/title-roselle-eng.png';
import { Plants } from "./Plants/Plants";
import { NavLink } from "react-router-dom";
import GoToTop from "components/GoToTop/GoToTop";
import { MenuThemeSetter } from "components/MenuThemeSetter/MenuThemeSetter";
import { useTranslation } from "react-i18next";
import { getLocaleUrl } from "helpers/i18n/locale";
import { LoaderContext } from "components/Context/LoaderContext";
import { LoaderComponent } from "components/LoaderComponent/LoaderComponent";
import { PromotionPopup } from "components/PromotionPopup/PromotionPopup";
import { BackToTopButton } from "components/BackToTopButton/BackToTopButton";
import { DisclaimerPopup } from "components/DisclaimerPopup/DisclaimerPopup";
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';

export const Product = () => {

    const { t, i18n } = useTranslation();
    const lang = i18n.language === "en-US" ? "en" : "zh";
    const { setEnable, setShow, setTheme, setShowLogo } = useContext(NavBarContext);
    const {loading, cacheImages} = useContext(LoaderContext);

    useEffect(() => {
        
        ReactGA.pageview(window.location.pathname + window.location.search);

        cacheImages([
            magnoliaProduct,
            roselleProduct,
            magnoliaTitleChi,
            magnoliaTitleEng,
            roselleTitleChi,
            roselleTitleEng,
        ]);

        setEnable(true);
        setShow(true);
        setTheme("light");
        setShowLogo(true);
    }, []);

    return (
        <>
            <Helmet>
                <title>{t('header.title.product')}</title>
                <meta name="description" content={t('header.des.product')} />
            </Helmet>
            
            {/* <Redirector /> */}
            <DisclaimerPopup />

            <PromotionPopup />

            <LoaderComponent/>

            {!loading && <MenuThemeSetter theme="light" className="product-page">
                <div className="product-list">
                    <NavLink className="product" to={`/${getLocaleUrl(i18n)}/product/magnolia`}>
                        <figure>
                            <img src={magnoliaProduct} alt="Magnolia Product" />
                        </figure>
                        <Plants></Plants>
                        <div className="product__lable title-xl font-shadow--6">
                            {lang === "en" ? 
                                t("product.magnolia")
                                :
                                <img src={magnoliaTitleChi} alt={t("product.magnolia")} />
                            }
                            {/* {t("product.magnolia")} */}
                        </div>
                    </NavLink>
                    <NavLink className="product" to={`/${getLocaleUrl(i18n)}/product/roselle`}>
                        <figure>
                            <img src={roselleProduct} alt="Roselle Product" />
                        </figure>
                        <Plants type="Roselle"></Plants>
                        <div className="product__lable title-xl font-shadow--6">
                            {lang === "en" ? 
                                t("product.roselle")
                                :
                                <img src={roselleTitleChi} alt={t("product.roselle")} />
                            }
                            {/* {t("product.roselle")} */}
                        </div>
                    </NavLink>
                </div>
            </MenuThemeSetter>}

            <GoToTop></GoToTop>
            <BackToTopButton />
        </>
    )
}