import { formatDate } from "helpers/date/dateHelper";
import { useTranslation } from "react-i18next";
import { PostModel } from "types/BlogPostType";
import { ArticleEntryType } from "types/RepublicRelationType";
import "./ArticleListEntry.scss"

export const ArticleListEntry = (props: { article: PostModel }) => {
    const {i18n} = useTranslation();
    let isEn = i18n.language === "en-US";

    const author = isEn ? props.article.author : props.article.authorZH;
    const title = isEn ? props.article.title : props.article.titleZH;
    const content = isEn ? props.article.content : props.article.contentZH;

    return (
        <div className="article-list-entry-wrapper">
            <div className={`article-list-entry-left-sub-wrapper ${!author && "no-right-type"}`}>
                <div className="article-list-entry-date">
                    {formatDate(props.article.publishAt)}
                </div>
                <div className="article-list-entry-title">
                    {content ? 
                    <a href={content} target="_blank" rel="noreferrer">{title}</a>
                    :
                    <span>{title}</span>
                    }
                </div>
            </div>

            {
                author &&
                <div className="article-list-entry-article-type">
                    {author}
                </div>
            }

        </div>
    );
}