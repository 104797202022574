import { AnchorGroup } from "components/Anchor/AnchorGroup";
import { BackToTopButton } from "components/BackToTopButton/BackToTopButton";
import { NavBarContext } from "components/Context/NavBarContext";
import { DisclaimerPopup } from "components/DisclaimerPopup/DisclaimerPopup";
import GoToTop from "components/GoToTop/GoToTop";
import { PromotionPopup } from "components/PromotionPopup/PromotionPopup";
import { Redirector } from "components/Redirector/Redirector";
import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import './AboutUs.scss'
import { AboutUsSection1 } from "./AboutUsSection1/AboutUsSection1";
import { AboutUsSection2 } from "./AboutUsSection2/AboutUsSection2";
import { AboutUsSection3 } from "./AboutUsSection3/AboutUsSection3";
import { FounderDennis } from "./FounderDennis/FounderDennis";
import { FounderJames } from "./FounderJames/FounderJames";
import { Introduction } from "./Introduction/Introduction";
import { Story } from "./Story/Story";
import { StoryBorn } from "./Story/StoryBorn";
import { StoryLifestyle } from "./Story/StoryLifestyle";
import { StoryMeet } from "./Story/StoryMeet";
import ReactGA from 'react-ga';

export const AboutUs = () => {
    const {t} = useTranslation();
    const { setEnable, setShow, setTheme, setShowLogo } = useContext(NavBarContext);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        setEnable(true);
        setShow(true);
        setTheme("light");
        setShowLogo(true);
    }, []);

    return (
        <>
            <Helmet>
                <title>{t('header.title.about-us')}</title>
                <meta name="description" content={t('header.des.about-us')} />
            </Helmet>
            
            {/* <Redirector /> */}
            <DisclaimerPopup />

            <PromotionPopup />

            <div className="about-us-page">
                <AnchorGroup configs={[
                    {
                        targetElementId: "introduction-line"
                    },
                    {
                        targetElementId: "about-us-section1"
                    },
                    {
                        targetElementId: "founder-dennis-section",
                    },
                    {
                        targetElementId: "about-us-section3"
                    }
                ]}
                />
                <Introduction />
                <AboutUsSection1 />
                <StoryMeet />
                <FounderDennis />
                <StoryLifestyle />
                <FounderJames />
                <StoryBorn />
                <AboutUsSection3 />
            </div>

            <GoToTop></GoToTop>
            <BackToTopButton />
        </>
    )
}