
import { useTranslation } from 'react-i18next';
import { getLocaleUrl } from "helpers/i18n/locale"
import { useEffect, useState, useRef } from 'react';
import {ReactComponent as PointerLeft} from 'assets/images/icon-pointer-left.svg';
import {ReactComponent as PointerRight} from 'assets/images/icon-pointer-right.svg';
import Stamp1 from 'assets/images/promotion/stamp1.png';
import Stamp2 from 'assets/images/promotion/stamp2.png';
import GodHand from 'assets/images/promotion/godhand-promotion.png';
import { ReactComponent as Close } from 'assets/images/icon-close.svg';
import './PromotionPopup.scss'
import { AnimatePresence, motion } from 'framer-motion';
import moment from 'moment';

type PromotionType = {
    timestamp: number
}

export const PromotionPopup = () => {
    const {t,i18n} = useTranslation();

    const [showHand, setShowHand] = useState(false);
    const [show, setShow] = useState(false);

    const showHandRef = useRef(setShowHand);

    const handleClose = (amount: moment.DurationInputArg1, unit: moment.unitOfTime.DurationConstructor | undefined) => {
        localStorage.setItem("promotionPopup", JSON.stringify(generateStorageDataObject(amount, unit)));
    }

    const generateStorageDataObject = (amount?: moment.DurationInputArg1, unit?: moment.unitOfTime.DurationConstructor | undefined) => {
        return {
            timestamp: moment().add(amount, unit).valueOf()
        }
    }
    
    const checkPromotionTimeout = () => {
        const data = localStorage.getItem("promotionPopup");
        if (data !== null) {
            try {
                const dataObject = JSON.parse(data) as PromotionType;
                if (!(dataObject.timestamp >= Date.now())) {
                    return true;
                }
                return false;
            }
            catch (e) {
                return true;
            }
        }
        else {
            return true;
        }
    }

    useEffect(() => {
        if (show)
        {
            document.body.style.overflow = "hidden";
        }
        else
        {
            document.body.style.overflow = "auto";
        }
    }, [show])

    useEffect(() => {
        const isTimeout = checkPromotionTimeout();
        const timeout = isTimeout ? setTimeout(() => {
            showHandRef.current(true);
        }, 3000):null;
        return () => {
            document.body.style.overflow = "auto";
            if (timeout) clearTimeout(timeout);
        }
    }, []);

    return (
        <AnimatePresence>
            <div className="promotion-popup">
                {show && <motion.div className="promotion-popup__background"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{duration: 0.5}}
                ></motion.div>}

                {show && <motion.div className="promotion-popup__wrapper"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{duration: 0.5}}
                >
                    <div className="promotion-popup__wrapper__stamps">
                        <img className="stamp" src={Stamp1} alt="Stamp" />
                        <img className="stamp" src={Stamp2} alt="Stamp" />
                    </div>
                    <h5 className="subtitle title-copperplate light">{t('promotion.subtitle')}</h5>
                    <h1 className="title title-copperplate bold">{t('promotion.title')}</h1>
                    <a href={`/${getLocaleUrl(i18n)}/promotion`} className="ready title-shadow"
                        onClick={() => {
                            handleClose(1, "h");
                        }}
                    >
                        <PointerRight/>
                        <span>Your ticket is ready</span>
                        <PointerLeft/>
                    </a>
                    <div className="promotion-popup__wrapper__close" onClick={() => {
                        setShow(false);
                        setShowHand(false);
                        handleClose(5, "minutes");
                    }}>
                        <Close /> <span>Close</span>
                    </div>
                </motion.div>}

                {showHand && <motion.div className="promotion-popup__hand"
                    initial={{opacity: 0, x: "100px"}}
                    animate={{opacity: 1, x: "0px"}}
                    exit={{opacity: 0, x: "100px"}}

                    onClick={() => setShow(true)}
                >
                    <img src={GodHand} alt="Open promotion popup" />
                </motion.div>}
            </div>
        </AnimatePresence>
    );
}