import './Story.scss'

import React from 'react'
import { DecoStar } from 'components/DecoStar/DecoStar'
import { FadableComponent } from 'components/FadableComponent/FadableComponent'

import godhand1 from 'assets/images/about-us/home-godhand1 1.png'
import godhand2 from 'assets/images/about-us/home-godhand2 1.png'
import { MenuThemeSetter } from 'components/MenuThemeSetter/MenuThemeSetter'
import { useTranslation } from 'react-i18next'
import { LinerComponent } from 'components/LinerComponent/LinerComponent'

export const StoryMeet = () => {

    const { t } = useTranslation();

    return (
        <div className="story-section fullpage">
            <MenuThemeSetter theme="dark" className="when-met fullpage__page flex-layout flex-direction-column align-items-center justify-content-space-between">

                <LinerComponent offset={200} style={{height: "25vh"}} />

                <div className="flex-layout flex-direction-column flex-center" style={{maxWidth: "100%"}}>
                    <figure className={`godhand godhand--right`}><img src={godhand1} alt="God Hand" /></figure>
                    <div className="story-section__title">
                        <FadableComponent>
                            <h4 className="title-bodoniModa pre-line">{t("about_us_story.meet")}</h4>
                        </FadableComponent>
                            <h6 className="about-us-story-p">{t("about_us_story.meet_desc")}</h6>
                        <DecoStar />
                    </div>
                    <figure className={`godhand godhand--left`}><img src={godhand2} alt="God Hand" /></figure>
                </div>

                <LinerComponent offset={200} style={{height: "30vh"}} />

            </MenuThemeSetter>
        </div>
    )
}