import { JourneyPageContext } from "components/Context/JourneyPageContext";
import { motion } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CitrusImage from "./../../../assets/images/product-detail/journey-citrus-image.png";

export const CitrusSection = () => {
    const { t } = useTranslation();
    const { page, pageNames } = useContext(JourneyPageContext);

    const [thisPageIndex, setThisPageIndex] = useState<number>(0);
    const [internalPage, setInternalPage] = useState<number>(0);

    useEffect(() => {
        setInternalPage(page - thisPageIndex);
    }, [page]);

    useEffect(() => {
        if (pageNames !== []) {
            setThisPageIndex(pageNames.indexOf("Citrus") + 1)
        }
    }, [pageNames]);

    const config = {
        "show": {
            opacity: 1
        },
        "hide": {
            opacity: 0
        }
    }

    return (
        <motion.div
            className="product-detail-journey-section-content"
            initial="hide"
            variants={config}
            animate={internalPage >= 0.25 && internalPage <= 0.75 ? "show" : "hide"}
        >
            <img src={CitrusImage} className="product-detail-journey-section-citrus-image" />
            <div className="product-detail-journey-section-title-text citrus-text">
                {t("product_detail_journey.citrus")}
            </div>
        </motion.div>
    )
}