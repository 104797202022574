import React, { useEffect, useState } from "react";
import { JourneyPageProviderProps } from "types/ProductDetailJourneyTypes";

export const JourneyPageContext = React.createContext({ page: 0, pageNames: [] } as JourneyPageProviderProps);

export const JourneyPageContextProvider = (props: { page: number, pageNames: string[], children: React.ReactNode }) => {
    return (
        <JourneyPageContext.Provider value={{ page: props.page, pageNames: props.pageNames }}>
            {props.children}
        </JourneyPageContext.Provider>
    )
}