import { BackToTopButton } from 'components/BackToTopButton/BackToTopButton';
import { NavBarContext } from 'components/Context/NavBarContext';
import { DisclaimerPopup } from 'components/DisclaimerPopup/DisclaimerPopup';
import GoToTop from 'components/GoToTop/GoToTop';
import { MenuThemeSetter } from 'components/MenuThemeSetter/MenuThemeSetter';
import { PromotionPopup } from 'components/PromotionPopup/PromotionPopup';
import { environment } from 'environment/environment';
import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import './ContactUs.scss'
import ReactGA from 'react-ga';

export const ContactUs = () => {
    const {t} = useTranslation();
    const { setEnable, setShow, setShowLogo } = useContext(NavBarContext);

    useEffect(() => {
        
        ReactGA.pageview(window.location.pathname + window.location.search);

        setEnable(true);
        setShow(true);
        setShowLogo(true);
    }, []);

    return (
        <>
            <Helmet>
                <title>{t('header.title.contact-us')}</title>
                <meta name="description" content={t('header.des.contact-us')} />
            </Helmet>
            
            {/* <Redirector /> */}
            <DisclaimerPopup />

            <PromotionPopup />
            
            <MenuThemeSetter theme="light" className="contact-us">
                <div className="contact-us__page flex-layout flex-center flex-direction-column">
                    <div className="flex-layout flex-direction-column">
                        <p>
                            <strong>{t("footer.contact_email_title")}</strong><br/>
                            <a href={`mailto:${environment.emailAddress}?subject=Mail from Magnolia Site`}>{environment.emailAddress}</a>
                        </p>
                        <p>
                            <strong>{t("footer.contact_tel_title")}</strong><br/>
                            <span>{environment.phone}</span>
                        </p>
                        <p>
                            <strong>{t("footer.contact_address_title")}</strong><br/>
                            <span>{t("footer.contact_address")}</span>
                        </p>
                    </div>

                    {/* <div className="contact-us__map">
                        <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3690.8501783618244!2d114.20854631541573!3d22.321505247764478!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3404013460d73af5%3A0x311a5a9f65d5cbc7!2z55Kw55CD5bel5ZWG5aSn5buI!5e0!3m2!1szh-TW!2sus!4v1621101558006!5m2!1szh-TW!2sus" style={{border:0}} allowFullScreen={true} loading="lazy"></iframe>
                    </div> */}
                </div>
            </MenuThemeSetter>
            
            <GoToTop></GoToTop>
            <BackToTopButton />
        </>
    );
}
