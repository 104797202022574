import React, { useContext, useEffect } from "react";
import {Helmet} from "react-helmet";
import './Home.scss'

import { HomeSection1 } from "./HomeSection1/HomeSection1";
import { HomeSection2 } from "./HomeSection2/HomeSection2";
import { HomeSection3 } from "./HomeSection3/HomeSection3";

import { Illustrations } from "./Illustrations/Illustrations";
import GoToTop from "components/GoToTop/GoToTop";
import { MenuThemeSetter } from "components/MenuThemeSetter/MenuThemeSetter";
import { useIsMobile } from "hook/UseIsMobile";
import { LoaderComponent } from "components/LoaderComponent/LoaderComponent";
import { LoaderContext } from "components/Context/LoaderContext";

import aging1 from 'assets/images/home/aging-1.png';
import aging2 from 'assets/images/home/aging-2.png';
import aging3 from 'assets/images/home/aging-3.png';
import aging4 from 'assets/images/home/aging-4.png';
import aging5 from 'assets/images/home/aging-5.png';
import aging6 from 'assets/images/home/aging-6.png';
import aging7 from 'assets/images/home/aging-7.png';
import aging8 from 'assets/images/home/aging-8.png';
import glass1 from 'assets/images/home/glass-1.png';
import glass2 from 'assets/images/home/glass-2.png';
import carbet from "assets/images/100cabinet-origin.jpg";
import noisebgk from "assets/images/noise-bkg.png";
import { PromotionPopup } from "components/PromotionPopup/PromotionPopup";
import { BackToTopButton } from "components/BackToTopButton/BackToTopButton";
import { DisclaimerPopup } from "components/DisclaimerPopup/DisclaimerPopup";
import { useTranslation } from "react-i18next";
import ReactGA from 'react-ga';


export const Home = () => {

    const {t} = useTranslation();

    const isMobile = useIsMobile();
    const {loading, cacheImages} = useContext(LoaderContext);

    useEffect(() => {
        
        ReactGA.pageview(window.location.pathname + window.location.search);

        cacheImages([
            carbet,
            noisebgk,
            aging1,
            aging2,
            aging3,
            aging4,
            aging5,
            aging6,
            aging7,
            aging8,
            glass1,
            glass2,
        ]);
    }, [])

    return (
        <>
            <Helmet>
                <title>{t('header.title.home')}</title>
                <meta name="description" content={t('header.des.home')} />
            </Helmet>
            
            {/* <Redirector /> */}
            <DisclaimerPopup />

            <PromotionPopup />

            <LoaderComponent/>

            {!loading && <div>

                <div className="sticky-background">
                    <div className="sticky-background__background"></div>
                </div>
                <div className="homepage">

                        <div className="fullpage hideoverflow">
                            <MenuThemeSetter theme="dark" className="fullpage__page">
                                <HomeSection1 />
                                <Illustrations position={"atBottom"} godhands={!isMobile} />
                            </MenuThemeSetter>
                            
                            <MenuThemeSetter theme="dark" className="fullpage__page">
                                <HomeSection2 />
                                <Illustrations position={"atTop"} sideFlower={true} />
                            </MenuThemeSetter>
                        </div>

                        <div className="fullpage">
                            <MenuThemeSetter theme="light" className="fullpage_page">
                                <HomeSection3 />
                            </MenuThemeSetter>
                        </div>

                </div>

            </div>}

            <GoToTop></GoToTop>
            <BackToTopButton />
        </>
    )
}