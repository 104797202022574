import { NavBarContext } from 'components/Context/NavBarContext';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './BlogPost.scss';

import { ReactComponent as PointerRight } from './../../../assets/images/icon-pointer-right.svg';
import { BlogPostPreview } from 'components/BlogPostPreview/BlogPostPreview';
import GoToTop from 'components/GoToTop/GoToTop';
import { DisclaimerPopup } from 'components/DisclaimerPopup/DisclaimerPopup';
import { PromotionPopup } from 'components/PromotionPopup/PromotionPopup';
import { useQuery, useQueryClient } from 'react-query';
import { getPostById } from 'helpers/api/blog/post';
import { formatDate } from 'helpers/date/dateHelper';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';

export const BlogPost = (props: {}) => {
    const { id } = useParams<{ id: string }>();
    const { t} = useTranslation();

    const { setEnable, setTheme, setShowLogo } = useContext(NavBarContext);

    const postContentQuery = useQuery(["post", parseInt(id)], () => getPostById(parseInt(id)));

    useEffect(() => {
        
        ReactGA.pageview(window.location.pathname + window.location.search);

        setEnable(true);
        setTheme("dark");
        setShowLogo(true);
    }, []);

    return (
        <>
            <Helmet>
                <title>{t('header.title.news')}</title>
                <meta name="description" content={t('header.des.news')} />
            </Helmet>
            
            {/* <Redirector /> */}
            <DisclaimerPopup />

            <PromotionPopup />

            <div className="blog-post-page">
                <div className="blog-post-thumbnail-container">
                    <img src={(postContentQuery.data && postContentQuery.data.post.backgroundImage) ?? undefined} className="blog-post-thumbnail" />
                    <div className="blog-post-thumbnail-mask" />

                    <div className="blog-post-thumbnail-info">
                        <div className="blog-post-info-date-tags-wrapper">
                            <div className="blog-post-info-date">
                                {postContentQuery.data && formatDate(postContentQuery.data.post.publishAt)}
                            </div>

                            <div className="blog-post-info-divider">

                            </div>

                            <div className="blog-post-info-tags">
                                {postContentQuery.data && postContentQuery.data.post.tags?.map(tag => tag.name).join(", ")}
                            </div>
                        </div>

                        <div className="blog-post-info-title title-bodoniModa pre-line">
                            {postContentQuery.data && postContentQuery.data.post.title}
                        </div>

                        <PointerRight className="blog-post-info-pointer" />
                    </div>
                </div>

                <div className="blog-post-body">
                    {/* <p className="blog-post-body-first-p">
                        {postContentQuery.data && postContentQuery.data.content}
                    </p> */}
                    <div className="blog-post-body-content-wrapper">

                        {postContentQuery.data && postContentQuery.data.post.content &&
                            <div dangerouslySetInnerHTML={{ __html: postContentQuery.data.post.content }} />
                        }
                        {/* <p>{dummyObject.desc}</p><br />
                        <p>{dummyObject.desc}</p><br />
                        <p>{dummyObject.desc}</p><br />
                        <img src={"https://picsum.photos/800"} />
                        <p style={{ fontSize: 14, lineHeight: "21px" }}>{dummyObject.desc}</p><br />
                        <br />
                        <p>{dummyObject.desc}</p><br />
                        <p>{dummyObject.desc}</p><br />
                        <p>{dummyObject.desc}</p><br /> */}
                    </div>
                </div>

                {postContentQuery?.data?.relatedPosts && postContentQuery.data.relatedPosts.length > 0 &&
                    <div className="blog-post-footer">
                        <div className="blog-post-footer-title-wrapper">
                            {/* <img src={DecoStar} className="about-us-section1-star" /> */}
                            <div className="blog-post-footer-title title-shadow">
                                RELATED POSTS
                            </div>
                            {/* <img src={DecoStar} className="about-us-section1-star" /> */}
                        </div>


                        <div className="blog-post-footer-related-post">
                            {postContentQuery.data.relatedPosts.map((relatedPost, index) => <BlogPostPreview content={relatedPost} big={true} seed={Math.random() * (index + 1)} />)}
                        </div>

                    </div>
                }
            </div>

            <GoToTop></GoToTop>
        </>
    )
}