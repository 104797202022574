import React, { useEffect, useState } from "react";
import { NavBarProps, NavBarProviderProps, UITheme } from "../../types/ThemeTypes";

export const NavBarContext = React.createContext({
    theme: "dark",
    showLogo: false,
    enable: true,
    show: true,
    menuOpen: false,
    setEnable: (enable: boolean, animate?: boolean) => { },
    setTheme: (theme: UITheme) => { },
    setShowLogo: (show: boolean) => { },
    setMenuOpen: (enable: boolean) => { },
} as NavBarProviderProps);

export const NavBarContextProvider = (props: { children: React.ReactNode }) => {

    const setEnable = (e: boolean) => {
        setConfig(prev => { return prev.enable !== e ? { ...prev, enable: e } : { ...prev } });
    }

    const setShow = (e: boolean) => {
        setConfig(prev => { return prev.show !== e ? { ...prev, show: e } : { ...prev } });
    }

    const setTheme = (t: UITheme) => {
        setConfig(prev => { return prev.theme !== t ? { ...prev, theme: t } : { ...prev } });
    }

    const setShowLogo = (show: boolean) => {
        setConfig(prev => { return prev.showLogo !== show ? { ...prev, showLogo: show } : { ...prev } });
    }

    const setMenuOpen = (e: boolean) => {
        setConfig(prev => { return prev.menuOpen !== e ? { ...prev, menuOpen: e } : { ...prev } });
    }

    const initState: NavBarProviderProps = {
        theme: "dark" as UITheme,
        showLogo: false,
        enable: true,
        show: true,
        menuOpen: false,
        setEnable: setEnable,
        setShow: setShow,
        setTheme: setTheme,
        setShowLogo: setShowLogo,
        setMenuOpen: setMenuOpen
    }

    const [config, setConfig] = useState<NavBarProviderProps>(initState);

    return (
        <NavBarContext.Provider value={config}>
            {props.children}
        </NavBarContext.Provider>
    )
}