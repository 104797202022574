import './ParallaxGrass.scss'

import plant2 from 'assets/images/home/home-plant2.png'
import plant3 from 'assets/images/home/home-plant3.png'
import plant5 from 'assets/images/home/home-plant5.png'
import plant51 from 'assets/images/home/home-plant5-1.png'
import plant7 from 'assets/images/home/home-plant7.png'
import plant8 from 'assets/images/home/home-plant8.svg'
import plant9 from 'assets/images/home/home-plant9.svg'
import plant10 from 'assets/images/home/home-plant10.png'
import plant11 from 'assets/images/home/home-plant11.png'
import plant121 from 'assets/images/home/home-plant12.png'
import plant131 from 'assets/images/home/home-plant13.png'
import plant141 from 'assets/images/home/home-plant14 1.svg'
import plant16 from 'assets/images/home/home-plant16.svg'
import plant17 from 'assets/images/home/home-plant17.svg'
import plant18 from 'assets/images/home/home-plant18.svg'
import plant19 from 'assets/images/home/home-plant19.svg'
import plant20 from 'assets/images/home/home-plant20.svg'

import React, { useLayoutEffect, useRef, useState } from 'react'
import { motion, useMotionValue, useTransform, useViewportScroll } from 'framer-motion'
import { normalise } from 'helpers/MathHelper/MathHelper'

export type ParallaxGrassPosition = 'atTop' | 'atBottom';

export const ParallaxGrass = (props: {
    position: ParallaxGrassPosition,
    animateIn?: boolean,
}) => {
    const [elementTop, setElementTop] = useState(0);

    const ref = useRef<any>(null);
    const {scrollY} = useViewportScroll();
    const viewRate = useMotionValue(1);
    const viewRateRef = useRef(viewRate);

    const yBottom = useTransform(scrollY, [elementTop, elementTop + 1], [0, 2], {
        clamp: false
    });
    const yTop = useTransform(viewRate, [0, 1], ["100%", "0%"]);

    const opacity = useMotionValue(1);
    const opacityRef = useRef(opacity);

    const getViewInfo = () => {
        const ele = ref.current;
        const eleRect = ele.getBoundingClientRect();

        const offsetTop = eleRect.height * 0.5;
        const offsetBottom = 0;

        const rate = 1 - normalise(eleRect.top, offsetTop, offsetBottom);
        return {
            isInView: rate > 0 && rate < 1,
            rate: rate,
        };
    }

    const updateAnimation = () => {
        const { isInView, rate } = getViewInfo();
        
        opacityRef.current.set(rate > 0 ? 1:0);
        if (isInView || rate >= 1) {
            viewRateRef.current.set(rate);
        }
    };

    useLayoutEffect(() => {
        updateAnimation();

        const element = ref.current;

        setElementTop(element.offsetTop);

        if (props.position !== "atTop") return;
        const unsubscribe = scrollY.onChange(updateAnimation);
        
        scrollY.set(0);

        return () => {
            unsubscribe();
        };
    }, [ref]);

    return (
        <>
            <div ref={ref} className={`parallax-grass ${props.position}`}>
                <div className="overlay-container">
                    <motion.div className="overlay" 
                        style={{ y: (props.position === "atBottom" ? yBottom:yTop), opacity }}
                        
                        initial={{y:"100%"}}
                        animate={{y:0}}
                        transition={{type: "spring", duration: 1}}
                    >

                        <div className="back">
                            <div className="left">
                                <img src={plant51} alt="" />
                                <img src={plant51} alt="" />
                                <img src={plant3} alt="" />
                                <img className="homeplant8" src={plant8} alt="" />
                            </div>
                            <div className="right">
                                <img className="homeplant9" src={plant9} alt="" />
                                <img src={plant11} alt="" />
                                <img src={plant131} alt="" />
                            </div>
                        </div>
                        
                        <div className="front">
                            <div className="left">
                                <img className="homeplant5" src={plant5} alt="" />
                                <img src={plant7} alt="" />
                                <img src={plant2} alt="" />
                            </div>
                            <div className="right">
                                <img src={plant10} alt="" />
                                <img className="homeplant12" src={plant121} alt="" />
                            </div>
                            <div className="special">
                                <img src={plant141} alt="" />
                                <div className="special__animation">
                                    <img className={`homeplant18`} src={plant18} alt="" />
                                    <img className={`homeplant19`} src={plant19} alt="" />
                                    <img className={`homeplant20`} src={plant20} alt="" />
                                </div>
                            </div>
                            <div className="particles">
                                <img src={plant16} alt="" className="homeplant16"/>
                                <img src={plant17} alt="" className="homeplant17"/>
                            </div>
                        </div>

                    </motion.div>

                    <motion.div className="overlay overlay--dark" style={{ y: (props.position === "atBottom" ? yBottom:yTop), opacity }}></motion.div>
                </div>
                
                
            </div>
        </>
    );
}