
import { FooterContext } from 'components/Context/FooterContext';
import { NavBarContext } from 'components/Context/NavBarContext';
import { Disclaimer } from 'components/Disclaimer/Disclaimer'
import { getLocaleUrl } from 'helpers/i18n/locale';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import './DisclaimerPage.scss'

export const DisclaimerPage = () => {
    const {i18n} = useTranslation();

    const location = useLocation();
    const history = useHistory();
    

    const { setEnable } = useContext(NavBarContext);
    const footerContext = useContext(FooterContext);

    useEffect(() => {
        setEnable(false);
        footerContext.setEnable(false);
        return () => {
            footerContext.setEnable(true);
        }
    }, []);
    
    return (
        <>
            <Disclaimer onEnterSite={() => {
                history.push((location as any).state ? (location as any).state.detail : `/{${getLocaleUrl(i18n)}}/`);
            }} />
        </>
    )
}