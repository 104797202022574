import { NavBarContext } from "components/Context/NavBarContext";
import { useContext, useRef } from "react";
import InView from "react-intersection-observer";
import { UITheme } from "types/ThemeTypes";

export const MenuThemeSetter = (props: {
    children?: (JSX.Element|string)|(JSX.Element|string)[], 
    className?: string,
    id?: string,
    theme: UITheme;
}) => {
    const {setTheme} = useContext(NavBarContext);
    const ref = useRef<any>(null);
    
    return (
        <div
            ref={ref}
            className={`${props.className ?? ""}`}
            style={{position: "relative"}}
            id={props.id}
        >
            <InView threshold={1}
                onChange={(isInView) => {
                    if (isInView && ref.current.clientHeight >= window.innerHeight) {
                        setTheme(props.theme);
                    }
                }}
                style={{
                    width: "100%",
                    height: "80vh",
                    maxHeight: "100%",
                    position: "absolute",
                    left: 0,
                    top: 0,
                    zIndex: 0,
                    pointerEvents: "none",
                }}
            >
            </InView>
            {props.children}
            <InView threshold={1}
                onChange={(isInView) => {
                    if (isInView && ref.current.clientHeight >= window.innerHeight) {
                        setTheme(props.theme);
                    }
                }}
                style={{
                    width: "100%",
                    height: "120px",
                    maxHeight: "100%",
                    position: "absolute",
                    left: 0,
                    bottom: 0,
                    zIndex: 0,
                    pointerEvents: "none",
                }}
            >
            </InView>
        </div>
    )
}