import React, { useContext, useLayoutEffect, useRef } from "react"
import './Roselle3.scss'

import { useViewportScroll } from "framer-motion";
import { NavBarContext } from "components/Context/NavBarContext";

import { ProductCocktail } from "../ProductCocktail/ProductCocktail";

import roselleProduct from 'assets/images/product/bottle-roselle-cps.png';
import roselleRocks from "assets/images/product/Roselle on rock.png";
import roselleTonic from "assets/images/product/Roselle soda.png";
import { Plants } from "pages/Product/Plants/Plants";
import { FadableComponent } from "components/FadableComponent/FadableComponent";
import { useTranslation } from "react-i18next";


export const Roselle3 = () => {
    const { t } = useTranslation();

    const ref = useRef<any>(null);
    const { scrollY } = useViewportScroll();
    const { setTheme } = useContext(NavBarContext);

    useLayoutEffect(() => {
        const unsubscribe = scrollY.onChange(() => {
            const rect = ref.current.getBoundingClientRect();

            if (rect.top <= 0 && -rect.top <= rect.height) {
                setTheme("light");
            }
        })
        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <div ref={ref} id="roselle-section-3" className="roselle-section-3">
            <div className="enjoy-with">
                <div className="enjoy-with__bottle">
                    <figure>
                        <img src={roselleProduct} alt="Roselle Bottle" />
                        <FadableComponent className={`enjoy-with__caption title-xl font-shadow--6`}>{t('product_detail_enjoy.title')}</FadableComponent>
                    </figure>
                    <Plants type="Roselle" active={true}></Plants>
                </div>
            </div>

            <ProductCocktail
                src={roselleRocks}
                figcaption={`Roselle\n on the Rocks`}
                recipe={{
                    title: (
                        <h5 className={`title-shadow`}>Recipes</h5>
                    ),
                    content: (
                        <p className={'font-m pre-line'}>{t('product_detail_enjoy.roselle.on_the_rock')}</p>
                    ),
                }}
                tips={{
                    title: (
                        <h5 className={`title-shadow`}>Tips</h5>
                    ),
                    content: (
                        <p className={'font-m pre-line'}>{t('product_detail_enjoy.roselle.on_the_rock_tips')}</p>
                    )
                }}
            ></ProductCocktail>

            <ProductCocktail
                src={roselleTonic}
                figcaption={`Roselle Soda`}
                recipe={{
                    title: (
                        <h5 className={`title-shadow`}>Recipes</h5>
                    ),
                    content: (
                        <p className={'font-m pre-line'}>{t('product_detail_enjoy.roselle.tonic')}</p>
                    ),
                }}
                tips={{
                    title: (
                        <h5 className={`title-shadow`}>Tips</h5>
                    ),
                    content: (
                        <p className={'font-m pre-line'}>{t('product_detail_enjoy.roselle.tonic_tips')}</p>
                    )
                }}
            ></ProductCocktail>
        </div>
    )
}