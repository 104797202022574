import './AboutUsSection1.scss'
import { FadableComponent } from 'components/FadableComponent/FadableComponent'
import React from 'react'
import { DecoStar } from 'components/DecoStar/DecoStar'
import { MenuThemeSetter } from 'components/MenuThemeSetter/MenuThemeSetter'
import { useTranslation } from 'react-i18next'
import { LinerComponent } from 'components/LinerComponent/LinerComponent'

export const AboutUsSection1 = () => {

    const { t } = useTranslation();

    return (
        <MenuThemeSetter theme="light" className="about-us-section1-wrapper" id="about-us-section1">
            <div className="about-us-section1-page">
                <FadableComponent className="about-us-section1-title-container">
                    <DecoStar />
                    <div className="about-us-section1-title">
                        <FadableComponent>
                            {t("about_us_section_1.slogan_1")}
                        </FadableComponent>
                    </div>
                    <DecoStar />
                </FadableComponent>
            </div>

            <LinerComponent offset={200} style={{ height: "40vh" }} />

            <div className="about-us-section1-page">
                <div className="about-us-section1-title-container">
                    <div className="about-us-section1-p">
                        {t("about_us_section_1.p")}
                    </div>
                </div>
                <FadableComponent className="about-us-section1-title-container">
                    <h4 className={`title-bodoniModa`}>{t("about_us_section_1.caption")}</h4>
                </FadableComponent>
            </div>

            <LinerComponent offset={200} style={{ height: "30vh" }} />

        </MenuThemeSetter>
    )
}