import './Illustrations.scss'
import React, { useLayoutEffect, useRef, useState } from "react";
import { ParallaxGrass, ParallaxGrassPosition } from "../ParallaxGrass/ParallaxGrass";
import { motion, useTransform, useViewportScroll } from 'framer-motion';

import Godhand1 from "assets/images/home/home-godhand1 1.png";
import Godhand2 from "assets/images/home/home-godhand2 1.png";
import homeplant1 from "assets/images/home/home-plant1.svg";
import homeplant4 from "assets/images/home/home-plant4.png";
import homeplant21 from "assets/images/home/home-plant21.png";
import homeplant22 from "assets/images/home/home-plant22.png";

export const Illustrations = (props: {position: ParallaxGrassPosition, godhands?: boolean, sideFlower?: boolean}) => {

    const {scrollY} = useViewportScroll();
    
    const [elementTop, setElementTop] = useState(0);
    const [sfelementTop, setSFElementTop] = useState(0);
    const ref = useRef<any>(null);
    const sfref = useRef<any>(null);
    
    const x = useTransform(scrollY, [elementTop, elementTop + 1], [0, -0.5], {
        clamp: false
    });
    const xN = useTransform(scrollY, [elementTop, elementTop + 1], [0, 0.5], {
        clamp: false
    });
    const y = useTransform(scrollY, [elementTop, elementTop + 1], [0, 1], {
        clamp: false
    });
    const sfx = useTransform(scrollY, [sfelementTop, sfelementTop + 1, sfelementTop + window.innerHeight], [-0.5, 0, 0], {
        clamp: false
    });

    useLayoutEffect(() => {
        if (props.godhands) {
            const element = ref.current;
            setElementTop(element.offsetTop);
        }
        if (props.sideFlower) {
            const element = sfref.current;
            setSFElementTop(element.offsetTop + window.innerHeight);
        }
      }, [ref, sfref]);

    return (
        <>
            <div className="illustrations">
                {props.godhands && 
                    <div ref={ref} className="godhands">
                        <motion.div 
                            style={{x, y}}
                            initial={{x:"-100%"}}
                            animate={{x:0}}
                            transition={{type: "spring", duration: 1}}
                        >
                            <img src={Godhand2} alt="illustration" style={{opacity: 0.6}} />
                            <img className={`homeplant1`} src={homeplant1} alt="illustration"/>
                            <div className="homeplat-hand-flower">
                                <img className={`homeplant21`} src={homeplant21} alt="illustration"/>
                                <img className={`homeplant22`} src={homeplant22} alt="illustration"/>
                                <img className={`homeplant4`} src={homeplant4} alt="illustration"/>
                            </div>
                        </motion.div>
                        
                        <motion.div 
                            style={{x: xN, y}}
                            initial={{x:"100%"}}
                            animate={{x:0}}
                            transition={{type: "spring", duration: 1}}
                        >
                            <img src={Godhand1} alt="illustration" style={{opacity: 0.6}} />
                        </motion.div>
                    </div>
                }
                {props.sideFlower && 
                    <div ref={sfref} className="sideFlower">
                        <motion.div style={{x: sfx}}>
                            <div className={`transform`}>
                                <img className={`homeplant1`} src={homeplant1} alt="illustration"/>
                                <div className="homeplat-hand-flower">
                                    <img className={`homeplant21`} src={homeplant21} alt="illustration"/>
                                    <img className={`homeplant22`} src={homeplant22} alt="illustration"/>
                                    <img className={`homeplant4`} src={homeplant4} alt="illustration"/>
                                </div>
                            </div>
                        </motion.div>
                        
                    </div>
                }
                
                <ParallaxGrass position={props.position} />
                
            </div>
        </>
    )
}