import React, { useState } from "react";
import { LoaderProviderProps } from "types/ThemeTypes";

export const LoaderContext = React.createContext({ 
    loading: false,
    setLoading: (loading: boolean) => {},
    cacheImages: (srcArray) => {}
} as LoaderProviderProps);

export const LoaderContextProvider = (props: { children: React.ReactNode }) => {

    const setLoading = (e: boolean) => {
        setConfig(prev => { return prev.loading !== e ? { ...prev, loading: e } : {...prev} });
    }

    const initState: LoaderProviderProps = {
        loading: true,
        setLoading: setLoading,
        cacheImages: (srcArray) => {cacheImages({srcArray, setLoading})}
    }
    const [config, setConfig] = useState<LoaderProviderProps>(initState);

    return (
        <LoaderContext.Provider value={config}>
            {props.children}
        </LoaderContext.Provider>
    )
}

const cacheImages = async (props: {
    srcArray: string[], 
    delay?: number, 
    setLoading: (loading: boolean) => void
}) => {
    const {srcArray, delay, setLoading} = props;
    if (srcArray.length === 0) return;
    
    setLoading(true);
    const promises = await srcArray.map((src) => {
        return new Promise(function (resolve, reject) {
            const img = new Image();
    
            img.src = src;
            img.onload = resolve;
            img.onerror = reject;
        });
    });

    await Promise.all(promises);
    await new Promise( resolve => setTimeout(resolve, delay ?? 0) )

    setLoading(false);
}
