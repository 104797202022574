import { motion, useViewportScroll } from "framer-motion";
import { useEffect, useRef, useState } from "react"
import { AnchorConfig } from "types/AnchorTypes"
import "./Anchor.scss"

export const AnchorNode = (props: { nodeConfig: AnchorConfig, onSelfActivate: (activate: boolean) => void, controlActivate: boolean }) => {

    const element = useRef<HTMLElement | null>(null);
    const activateRef = useRef<boolean>(false);

    const [activate, setActivate] = useState<boolean>(false);
    const { scrollY } = useViewportScroll();

    useEffect(() => {
        const listener = scrollY.onChange((y) => {
            if (activateRef.current === false && element.current && element.current.getBoundingClientRect().top <= 5) {
                props.onSelfActivate(true);
                setActivate(true);
            }
            else if (activateRef.current === true && element.current && element.current.getBoundingClientRect().top > 5) {
                props.onSelfActivate(false);
                setActivate(false);
            }
        });

        return () => listener();
    }, []);

    useEffect(() => {
        activateRef.current = activate;
    }, [activate])

    useEffect(() => {
        element.current = document.getElementById(props.nodeConfig.targetElementId);
    }, []);

    const handleClick = () => {
        element?.current?.scrollIntoView({ block: "start", behavior: "smooth" });
    }

    return (
        <motion.div
            className="anchor-node"
            onClick={handleClick}
            initial={{ backgroundColor: "rgba(252, 232, 144, 0.15)" }}
            animate={{ backgroundColor: `rgba(252, 232, 144, ${props.controlActivate ? 1 : 0.15})` }}
        />
    )
}