import { useViewportScroll } from "framer-motion";
import { useEffect, useRef, useState } from "react";

export function useScrollState(): "up" | "down" | null {
    const { scrollY } = useViewportScroll();

    const [scrollYState, setScrollYState] = useState<number>(0);
    const [scrollDir, setScrollDir] = useState<"up" | "down" | null>(null);

    const lastScrollY = usePrevious(scrollY.get());

    useEffect(() => {
        const listener = scrollY.onChange((y) => setScrollYState(y));
        return () => listener();
    }, [])

    useEffect(() => {
        if (scrollYState > 0 && scrollYState < document.body.clientHeight - window.innerHeight)
        {
            setScrollDir(scrollYState === (lastScrollY ?? 0) ? null : scrollYState > (lastScrollY ?? 0) ? "down" : "up");
        }
    }, [scrollYState]);

    return scrollDir;
}

function usePrevious(value: any) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}