import React from "react"
import Particles from "react-tsparticles"
import star from "assets/images/about-us/deco-star.svg"
import './Galaxy.scss'
import { useWindowSize } from "hook/UseWindowResize"

export const Galaxy = () => {
    return (
        <div className="galaxy">
            <Particles 
                height={"100vh"}
                params={{
                    "particles": {
                        "number": {
                            "value": 8,
                        },
                        "line_linked": {
                            "enable": false
                        },
                        "move": {
                            "speed": 0.1,
                            "out_mode": "bounce"
                        },
                        "shape": {
                            "type": [
                                "image"
                            ],
                            "image": [
                                {
                                    "src": star,
                                    "height": 70,
                                    "width": 57
                                },
                            ]
                        },
                        "size": {
                            "value": 15,
                            "random": false,
                            "anim": {
                                "enable": true,
                                "speed": 4,
                                "size_min": 10,
                                "sync": false
                            }
                        }
                    },
                    "retina_detect": false
                }}/>
        </div>
    )
}