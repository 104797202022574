import { BackToTopButton } from "components/BackToTopButton/BackToTopButton";
import { NavBarContext } from "components/Context/NavBarContext";
import { DisclaimerPopup } from "components/DisclaimerPopup/DisclaimerPopup";
import GoToTop from "components/GoToTop/GoToTop";
import { MenuThemeSetter } from "components/MenuThemeSetter/MenuThemeSetter";
import { PromotionPopup } from "components/PromotionPopup/PromotionPopup";
import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import "./PrivacyPolicy.scss"
import ReactGA from 'react-ga';

export const PrivacyPolicy = () => {

    const {t}=useTranslation();
    const { setEnable, setShow, setTheme, setShowLogo } = useContext(NavBarContext);

    useEffect(() => {
        
        ReactGA.pageview(window.location.pathname + window.location.search);

        setEnable(true);
        setShow(true);
        setTheme("light");
        setShowLogo(true);
    }, []);

    return (
        <>
            <Helmet>
                <title>{t('header.title.home')}</title>
                <meta name="description" content={t('header.des.home')} />
            </Helmet>
            
            {/* <Redirector /> */}
            <DisclaimerPopup />

            <PromotionPopup />

            <MenuThemeSetter theme="light" className="privacy-policy-page">
                <div className="privacy-policy-page__wrapper">
                <h1>Magnolia Concept Limited Privacy Policy</h1>

                <p>This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from  (the “Site”).</p>

                <h2>PERSONAL INFORMATION WE COLLECT</h2>

                <p>When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information.”</p>

                <p>We collect Device Information using the following technologies:</p>
                <ul>
                    <li>“Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. </li>
                    <li>“Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.</li>
                    <li>“Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Site.</li>
                </ul>

                <p>Additionally when you make a purchase or attempt to make a purchase through the Site, we collect certain information from you, including your name, billing address, shipping address, payment information (including credit card numbers), email address, and phone number.  We refer to this information as “Order Information.”</p>

                <p>When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information and Order Information.</p>

                <h2>HOW DO WE USE YOUR PERSONAL INFORMATION?</h2>

                <p>We use the Order Information that we collect generally to fulfill any orders placed through the Site (including processing your payment information, arranging for shipping, and providing you with invoices and/or order confirmations).  Additionally, we use this Order Information to:<br/>
                Communicate with you;<br/>
                Screen our orders for potential risk or fraud; and<br/>
                When in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services;<br/>
                Drive Marketing Campaigns;<br/>
                Advertise<br/>
                We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns)</p>
                
                <h2>SHARING YOUR PERSONAL INFORMATION</h2>

                <p>We share your Personal Information with third parties to help us use your Personal Information, as described above. We also use Google Analytics to help us understand how our customers use the Site--you can read more about how Google uses your Personal Information here:  https://www.google.com/intl/en/policies/privacy/.  You can also opt-out of Google Analytics here:  https://tools.google.com/dlpage/gaoptout.</p>

                <p>Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.</p>

                <h2>BEHAVIOURAL ADVERTISING</h2>
                <p>As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you.  For more information about how targeted advertising works, you can visit the Network Advertising Initiative’s (“NAI”) educational page at http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.</p>

                <p>You can opt out of targeted advertising by:<br/>
                [[  FACEBOOK - https://www.facebook.com/settings/?tab=ads<br/>
                    GOOGLE - https://www.google.com/settings/ads/anonymous
                ]]</p>

                <p>Additionally, you can opt out of some of these services by visiting the Digital Advertising Alliance’s opt-out portal at:  http://optout.aboutads.info/.</p>

                <h2>DO NOT TRACK</h2>
                <p>Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser.</p>

                <h2>DATA RETENTION</h2>
                <p>When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information.</p>

                <h2>MINORS</h2>
                <p>The Site is not intended for individuals under the age of 18.</p>

                <h2>CHANGES</h2>
                <p>We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.</p>

                <h2>CONTACT US</h2>
                <p>For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at info@magnolia-lab.com</p>
                 
                <h1>隱私政策</h1>

                <p>本隱私政策描述了您訪問（“本網站”）或從中進行購買時如何收集，使用和共享您的個人信息。</p>

                <h2>我們收集的個人信息</h2>

                <p>當您訪問本網站時，我們會自動收集有關您設備的某些信息，包括有關您的Web瀏覽器，IP地址，時區以及設備上安裝的某些Cookie的信息。此外，在您瀏覽網站時，我們收集有關您查看的各個網頁或產品的信息，哪些網站或搜索字詞將您引薦到該網站以及有關您如何與該網站進行交互的信息。我們將此自動收集的信息稱為“設備信息”。</p>

                <p>我們使用以下技術收集設備信息：</p>
                <ul>
                    <li>“ Cookie”是放置在設備或計算機上的數據文件，通常包含匿名唯一標識符。</li>
                    <li>“日誌文件”跟踪網站上發生的操作，並收集數據，包括您的IP地址，瀏覽器類型，Internet服務提供商，引薦/退出頁面和日期/時間。</li>
                    <li>“網絡信標”，“標籤”和“像素”是用於記錄有關您如何瀏覽網站的信息的電子文件。</li>
                </ul>

                <p>此外，當您進行購買或嘗試通過本網站進行購買時，我們會從您那裡收集某些信息，包括您的姓名，賬單地址，送貨地址，付款信息（包括信用卡號），電子郵件地址和電話號碼。我們將此信息稱為“訂單信息”。</p>

                <p>當我們在本隱私政策中談論“個人信息”時，我們同時在談論“設備信息”和“訂單信息”。</p>

                <h2>我們如何使用您的個人信息？</h2>

                <p>我們使用通常收集的訂單信息來完成通過網站下達的所有訂單（包括處理您的付款信息，安排運輸以及向您提供發票和/或訂單確認）。此外，我們使用此訂單信息執行以下操作：<br/>
                與您交流；<br/>
                篩選我們的訂單以發現潛在的風險或欺詐行為；和<br/>
                根據您與我們分享的偏好，向您提供與我們的產品或服務有關的信息或廣告；<br/>
                推動營銷活動；<br/>
                刊登廣告等。<br/>
                我們使用收集到的設備信息來幫助我們篩查潛在的風險和欺詐行為（特別是您的IP地址），並且更廣泛地改善和優化我們的網站（例如，通過生成有關客戶如何瀏覽和互動的分析）網站，並評估我們的營銷和廣告活動的成功）<br/>
                分享您的個人信息</p>

                <p>如上所述，我們與第三方共享您的個人信息，以幫助我們使用您的個人信息。我們還使用Google Analytics（分析）來幫助我們了解客戶如何使用本網站-您可以在以下網址閱讀有關Google如何使用您的個人信息的更多信息：https：//www.google.com/intl/zh-CN/policies/privacy/。您還可以在此處選擇退出Google Analytics（分析）：https：//tools.google.com/dlpage/gaoptout。</p>

                <p>最後，我們也可能會分享您的個人信息，以遵守適用的法律和法規，回應傳票，搜查令或其他合法要求，以獲取我們收到的信息，或以其他方式保護我們的權利。</p>

                <h2>行為定向廣告</h2>
                <p>如上所述，我們使用您的個人信息為您提供我們認為您可能感興趣的有針對性的廣告或營銷傳播。有關定向廣告如何工作的更多信息，您可以訪問網絡廣告計劃（Network Advertising Initiative，NAI）的教育頁面，網址為http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work。</p>

                <p>您可以通過以下方式選擇退出定向廣告：<br/>
                [[FACEBOOK-https://www.facebook.com/settings/?tab=ads<br/>
                    GOOGLE-https://www.google.com/settings/ads/anonymous
                ]]</p>

                <p>此外，您可以通過訪問Digital Advertising Alliance 的退出門戶網站（http://optout.aboutads.info/）退出某些服務。</p>

                <h2>不跟踪</h2>
                <p>請注意，當您從瀏覽器中看到“不跟踪”信號時，我們不會改變我們網站的數據收集和使用慣例。</p>

                <h2>數據保留</h2>
                <p>當您通過網站下訂單時，除非您要求我們刪除此信息，否則我們將保留您的訂單信息作為記錄。</p>

                <h2>年齡限制</h2>
                <p>本網站不適用於18歲以下的用家。</p>

                <h2>更新政策</h2>
                <p>我們可能會不時更新本隱私政策，以反映（例如）我們的做法發生變化或出於其他運營，法律或法規方面的原因。</p>

                <h2>聯絡我們</h2>
                <p>有關我們的隱私慣例的更多信息，如果您有任何疑問或想投訴，請通過電子郵件與我們聯繫，電子郵件為info@magnolia-lab.com。</p>
                </div>
            </MenuThemeSetter>
            
            <GoToTop></GoToTop>
            <BackToTopButton />
        </>
    );
}