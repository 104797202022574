import { LoaderContext } from 'components/Context/LoaderContext';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './LoaderComponent.scss'

import bicycle from 'assets/images/bicycle-guy-dark.gif';

export const LoaderComponent = () => {

    const {t} = useTranslation();
    const {loading, setLoading} = useContext(LoaderContext);

    const test = () => {
        // setLoading(false);
    }

    return (
        <AnimatePresence>
            {loading && <motion.div 
                className="loader flex-layout flex-center flex-direction-column"
                initial={{opacity: 1}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{duration: 0.3}}
            >
                <figure className="loader__bicycle"><img src={bicycle} alt="Loading" /></figure>
                <h4 className="loader__text title-shadow" onClick={() => test()}>{t('loading')}...</h4>
            </motion.div>}
        </AnimatePresence>
    );
}