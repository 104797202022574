import './BackToTopButton.scss'
import { ScrollToTop } from "components/GoToTop/GoToTop"
import { ReactComponent as PointerUp } from 'assets/images/icon-pointer-up.svg'
import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion, useViewportScroll } from 'framer-motion';

export const BackToTopButton = () => {
    const {scrollY} = useViewportScroll();
    const [scrolledDown, setScrolledDown] = useState(false);
    
    useEffect(() => {
        const checkShow = (v: number) => {
            if (v > window.innerHeight)
            {
                setScrolledDown(true);
            } else {
                setScrolledDown(false);
            }
        }
        const unsubscribe = scrollY.onChange((v)=>{
            checkShow(v);
        })
        checkShow(0);
        return () => {
            unsubscribe();
        }
    }, [])
    
    return (
        <>
            <AnimatePresence>
                {scrolledDown && <motion.div
                    className="back-to-top"
                    onClick={() => {
                        ScrollToTop();
                    }}
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                >
                    <div className="back-to-top__button flex-layout flex-direction-column flex-center">
                        <PointerUp />
                        <span>Back To</span>
                        <span>Top</span>
                    </div>
                </motion.div>}
            </AnimatePresence>
        </>  
        );
    }
    