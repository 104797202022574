import './FaqEntry.scss'
import { ReactComponent as Expand } from './../../assets/images/icon-arrow-down.svg'
import React, { useRef, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion';

export const FaqEntry = (props: { question: string, answer: string }) => {

    const [isExpand, setIsExpand] = useState<boolean>();
    const expandDiv = useRef<HTMLDivElement>(null);

    const arrowConfig = {
        up: { rotate: 180 },
        down: { rotate: 0 }
    }

    return (
        <>
            {(props.question !== "") && <motion.div className="faq-entry-wrapper">

                <div className="faq-entry-header" onClick={() => setIsExpand(prev => !prev)}>
                    <div className="faq-question title-shadow title-xs">
                        {props.question}
                    </div>
                    <motion.div
                        className="faq-expand-arrow-container"
                        variants={arrowConfig}
                        animate={isExpand ? "up" : "down"}
                        transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.4 }}
                        initial={false}
                    >
                        <Expand className="faq-expand-arrow" />
                    </motion.div>
                </div>

                <div className="faq-divider" />

                <AnimatePresence>
                    {
                        isExpand &&
                        <motion.div
                            ref={expandDiv}
                            className="faq-answer-container"
                            layout
                            initial={{ opacity: 0, y: -25, height: 0 }}
                            animate={{ opacity: 1, y: 0, height: "auto", transition: { duration: 0.25 } }}
                            exit={{ opacity: 0, y: -25, height: 0, transition: { duration: 0.25 } }}
                        >
                            <p className="faq-answer-paragraph">
                                {props.answer}
                            </p>
                        </motion.div>
                    }
                </AnimatePresence>
            </motion.div>}
        </>
    )
}