import { FooterContext } from 'components/Context/FooterContext';
import { NavBarContext } from 'components/Context/NavBarContext';
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom';
import { getLocaleUrl } from "helpers/i18n/locale"
import queryString from 'query-string';
import moment from 'moment';

import React, { useContext, useEffect, useRef, useState } from 'react';
import './Promotion.scss'

import godhandSand from 'assets/images/promotion/godhand-sand.png';
import godhandRed from 'assets/images/promotion/godhand-red.png';
import stampEmbossed from 'assets/images/promotion/stamp-embossed.png';
import {ReactComponent as PointerDown} from 'assets/images/icon-pointer-down.svg';
import {ReactComponent as PointerLeft} from 'assets/images/icon-pointer-left.svg';
import {ReactComponent as PointerRight} from 'assets/images/icon-pointer-right.svg';
import {ReactComponent as DecoStar} from 'assets/images/about-us/deco-star.svg';
import {ReactComponent as LogoBlack} from 'assets/images/logo-black.svg';
import { SubmitHandler, useForm } from 'react-hook-form';
import { create, createPremium, validateCode } from 'helpers/api/promotion/promotion';
import { Thankyou } from './Thankyou/Thankyou';
import { Used } from './Used/Used';
import { DottedLine } from './DottedLine/DottedLine';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';

type TicketPage = {
    theme: TicketPageTheme,
    content: JSX.Element|string,
    className?: string,
    style?: React.CSSProperties,
}

type TicketPageTheme = "light" | "dark";

export interface PromotionForm {
	senderName: string,
	senderEmail: string,
	receiverName: string,
	receiverEmail: string,
	content: string,
	subscribed: boolean
}
export interface PromotionPremiumForm {
	code: string,
	name: string,
	email: string,
	content: string,
	address: string,
	phone: string,
	subscribed: boolean
}

export const Promotion = () => {
    const {t, i18n } = useTranslation();
    const { setEnable } = useContext(NavBarContext);
    const footerContext = useContext(FooterContext);
    const location = useLocation();
    const history = useHistory();

    const [showThankyou, setShowThankyou] = useState(false);

    const [showUsed, setShowUsed] = useState(false);
    const showUsedRef = useRef(setShowUsed);

    const [loading, setLoading] = useState(false);
    const loadingRef = useRef(setLoading);

    const [isPremium, setIsPremium] = useState(false);

    const params = queryString.parse(location.search);

    // const scrollRef = useHorizontalScroll();

    useEffect(() => {
        
        ReactGA.pageview(window.location.pathname + window.location.search);

        setEnable(false);
        footerContext.setEnable(false);

        const checkCode = async (code: string) => {
            loadingRef.current(true);
            try {
                await validateCode(code);
            } catch (err) {
                // console.log(err);
                showUsedRef.current(true);
            }
            loadingRef.current(false);
        };
        
        if (params.code !== undefined)
        {
            setIsPremium(true);
            checkCode(params.code as string);
        }

        return () => {
            footerContext.setEnable(true);
        }
    }, []);

    const toggleLanguage = (language: string) => {
        const locale = location.pathname.split("/")[1];
        document.body.classList.remove(`locale-${getLocaleUrl(i18n)}`);
        if (language === "zh-HK") {
            i18n.changeLanguage("zh-HK");
            history.push(location.pathname.replace(locale, "zh")+location.search);
        }
        else if (language === "en-US") {
            i18n.changeLanguage("en-US");
            history.push(location.pathname.replace(locale, "en")+location.search);
        }
        document.body.classList.add(`locale-${getLocaleUrl(i18n)}`);
    }

    return (
        <>
            <Helmet>
                <title>{t(`header.title.promotion`)}</title>
                <meta name="description" content={t(`header.des.promotion`)} />
            </Helmet>

            <div className={`promotion-page ${isPremium ? "premium":""}`}>
                <div className="promotion-page__header">
                    <div className="illustration">
                        <div className="language title-cormorant">
                            <div className={`${i18n.language === "en-US" ? "active":""}`} onClick={() => {toggleLanguage("en-US");}}>EN</div>
                            <div className={`${i18n.language === "zh-HK" ? "active":""}`} onClick={() => {toggleLanguage("zh-HK");}}>中</div>
                        </div>
                        {isPremium ?
                        <img src={godhandSand} alt="God Hand Illustraction" />
                        :
                        <img src={godhandRed} alt="God Hand Illustraction" />}
                    </div>
                </div>
                <div className="promotion-page__content">
                    <div className="promotion-page__instruction">
                        <h5 className="subtitle title-cormorant">{t('promotion.subtitle')}</h5>
                        <h3 className="title title-copperplate bold">{t('promotion.title')}</h3>
                        <div className="scroll-right">
                            <PointerRight />
                        </div>
                        <div className="instruction-content">
                            <p className="pre-line title-cormorant">{t('promotion.content')}</p>
                            <div className="deco">
                                <img src={stampEmbossed} alt="Stemp Embossed" />
                            </div>
                        </div>
                        
                        <div className="scroll-down">
                            <div className="font-xs title-shadow">Scroll down</div>
                            <PointerDown />
                        </div>
                    </div>

                    {isPremium ?
                    <PremiumTicketForm loading={loading} code={params.code as string} onSubmit={() => setShowThankyou(true)} />
                    :
                    <TicketForm loading={loading} onSubmit={() => setShowThankyou(true)} />
                    }
                </div>
                {showThankyou && <Thankyou />}
                {showUsed && <Used />}
            </div>
        </>
    )
}

const PremiumTicketForm = (props: {
    loading?: boolean,
    code: string,
    onSubmit?: () => void,
}) => {

    const [loading, setLoading] = useState(false);
    const {t, i18n} = useTranslation();
    const { register, watch, formState: { errors }, handleSubmit } = useForm<PromotionPremiumForm>();
    const watchName = watch("name", "");
    const watchContent = watch("content", "");

    const elementRef = useRef<HTMLDivElement>(null);
    const scrollToEle = () => {
        elementRef.current?.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
    }

    const onSubmit: SubmitHandler<PromotionPremiumForm> = async data => {
        // console.log(data);
        setLoading(true);
        try {
            data.subscribed = true;
            const result = await createPremium(data);
            props.onSubmit?.();
        } catch (err) {
            // console.log(err);
        }
        setLoading(false);
    }
    
    return (
        <form onSubmit={handleSubmit(onSubmit)} className="promotion-page__form flex-layout flex-direction-row">
            <MobileTicket
                className={`promotion-page__ticket`}
                pages={[
                {
                    theme: "light",
                    content: (
                        <>
                            <div className="ticket-section1">
                                <ul className="star-list">
                                    <li className={`title-cormorant title-s ${i18n.language==="zh-HK"?"":"italic"}`}><DecoStar className="list-star" /> {t('promotion.ticket.question1')} </li>
                                    <li className={`title-cormorant title-s ${i18n.language==="zh-HK"?"":"italic"}`}><DecoStar className="list-star" /> {t('promotion.ticket.question2')} </li>
                                    <li className={`title-cormorant title-s ${i18n.language==="zh-HK"?"":"italic"}`}><DecoStar className="list-star" /> {t('promotion.ticket.question3')} </li>
                                </ul>
                                <hr />
                                <p className="instruction title-cormorant"> {t('promotion.ticket.instruction_premium')} </p>
                                <div className="click-here">
                                    <button className="button button--grey button--rounded title-shadow" type="button" onClick={scrollToEle}>
                                        <span>Click Here</span>
                                        <PointerRight/>
                                    </button>
                                </div>
                            </div>
                        </>
                    ),
                },
                {
                    theme: "light",
                    content: (
                        <div className="ticket-section2">
                            <div className="dear title-copperplate">
                                <span>{t('promotion.ticket.dear')}</span>
                                <input className={`title-cormorant bold italic`} maxLength={50} {...register("name", {required: true, maxLength: 50})} type="text" placeholder="Your name" />
                                <span>,</span>
                            </div>
                            <div className="post-card-content">
                                <textarea className={`title-cormorant ${i18n.language==="zh-HK"?"":"italic"}`} required {...register("content", {required: true, maxLength: 400})} maxLength={400} placeholder={t('promotion.ticket.placeholder_premium')} />
                                <div className="word-count">Characters counter: {watchContent.length}/400</div>
                            </div>
                            <div className="post-card-last-row flex-layout justify-content-space-between align-items-end">
                                <div className={`from-date title-cormorant ${i18n.language==="zh-HK"?"":"italic"}`}>
                                    From <span className="from-date__name">{watchName}</span>
                                    <br/>
                                    <span className="from-date__month">{moment().format("MMM YYYY")}</span>
                                </div>
                                <div className="logo">
                                    <LogoBlack />
                                </div>
                            </div>
                            <div className="deco">
                                <img src={stampEmbossed} alt="Stemp Embossed" />
                            </div>
                        </div>
                    ),
                },
                {
                    theme: "dark",
                    content: (
                        <div ref={elementRef} className="ticket-section3">
                            <Departure />
                            <div className="input-field">
                                <input className="title-cormorant" {...register("address", {required: true})} type="text" placeholder={t('promotion.ticket.address')} />
                                {errors.address && <span className="input-field__error">Please enter your addresss</span> }
                            </div>
                            <div className="input-field">
                                <input className="title-cormorant" {...register("email", {required: true, pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/})} type="text" placeholder={t('promotion.ticket.email')} />
                                {errors.email && <span className="input-field__error">Please enter a valid email address</span> }
                            </div>
                            <p className={`input-tips font-xs title-cormorant ${i18n.language==="zh-HK"?"":"italic"}`}>{t('promotion.ticket.emailTips')}</p>
                            <div className="flex-layout flex-center flex-direction-column">
                                <button className="button button--light button--rounded title-shadow" type="submit" disabled={loading || props.loading}>
                                    <PointerRight/>
                                    <span>{t('promotion.ticket.send')}</span>
                                    <PointerLeft/>
                                </button>
                                <span className={`font-xs title-cormorant bold ${i18n.language==="zh-HK"?"":"italic"}`}>Promotion Code: {props.code}</span>
                                <input type="hidden" {...register("code")} value={props.code} />
                            </div>
                        </div>
                    ),
                }
            ]} />
            <div ref={elementRef} className="ticket-end"></div>
        </form>
    );
}

const TicketForm = (props: {
    loading?: boolean,
    onSubmit?: () => void,
}) => {

    const [loading, setLoading] = useState(false);
    const {t, i18n} = useTranslation();
    const { register, watch, formState: { errors }, handleSubmit } = useForm<PromotionForm>();
    const watchName = watch("senderName", "");
    const watchContent = watch("content", "");
    
    const elementRef = useRef<HTMLDivElement>(null);
    const scrollToEle = () => {
        elementRef.current?.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
    }

    const onSubmit: SubmitHandler<PromotionForm> = async data => {
        // console.log(data);
        setLoading(true);
        try {
            data.receiverName = data.senderName;
            data.receiverEmail = data.senderEmail;
            data.subscribed = true;
            const result = await create(data);
            props.onSubmit?.();
        } catch (err) {
            // console.log(err);
        }
        setLoading(false);
    }
    
    return (
        <form onSubmit={handleSubmit(onSubmit)} className="promotion-page__form flex-layout flex-direction-row">
            <MobileTicket
                className={`promotion-page__ticket`}
                pages={[
                {
                    theme: "light",
                    content: (
                        <>
                            <div className="ticket-section1">
                                <ul className="star-list">
                                    <li className={`title-cormorant title-s ${i18n.language==="zh-HK"?"":"italic"}`}><DecoStar className="list-star" /> {t('promotion.ticket.question1')} </li>
                                    <li className={`title-cormorant title-s ${i18n.language==="zh-HK"?"":"italic"}`}><DecoStar className="list-star" /> {t('promotion.ticket.question2')} </li>
                                    <li className={`title-cormorant title-s ${i18n.language==="zh-HK"?"":"italic"}`}><DecoStar className="list-star" /> {t('promotion.ticket.question3')} </li>
                                </ul>
                                <hr />
                                <p className="instruction title-cormorant"> {t('promotion.ticket.instruction')} </p>
                                <div className="click-here">
                                    <button className="button button--grey button--rounded title-shadow" type="button" onClick={scrollToEle}>
                                        <span>Click Here</span>
                                        <PointerRight/>
                                    </button>
                                </div>
                            </div>
                        </>
                    ),
                },
                {
                    theme: "light",
                    content: (
                        <div className="ticket-section2">
                            <div className="dear title-copperplate">
                                <span>{t('promotion.ticket.dear')}</span>
                                <input className={`title-cormorant bold italic`} maxLength={50} {...register("senderName", {required: true, maxLength: 50})} type="text" placeholder="Your name" />
                                <span>,</span>
                            </div>
                            <div className="post-card-content">
                                <textarea className={`title-cormorant ${i18n.language==="zh-HK"?"":"italic"}`} required {...register("content", {required: true, maxLength: 400})} maxLength={400} placeholder={t('promotion.ticket.placeholder')} />
                                <div className="word-count">Characters counter: {watchContent.length}/400</div>
                            </div>
                            <div className="post-card-last-row flex-layout justify-content-space-between align-items-end">
                                <div className={`from-date title-cormorant ${i18n.language==="zh-HK"?"":"italic"}`}>
                                    From <span className="from-date__name">{watchName}</span>
                                    <br/>
                                    <span className="from-date__month">{moment().format("MMM YYYY")}</span>
                                </div>
                                <div className="logo">
                                    <LogoBlack />
                                </div>
                            </div>
                            <div className="deco">
                                <img src={stampEmbossed} alt="Stemp Embossed" />
                            </div>
                        </div>
                    ),
                },
                {
                    theme: "dark",
                    content: (
                        <div className="ticket-section3">
                            <Departure />
                            <div className="input-field">
                                <input className={`title-cormorant ${i18n.language==="zh-HK"?"":"bold italic"}`} {...register("senderEmail", {required: true, pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/})} type="text" placeholder={t('promotion.ticket.email')} />
                                {errors.senderEmail && <span className="input-field__error">Please enter a valid email address</span> }
                            </div>
                            <p className={`input-tips font-xs title-cormorant ${i18n.language==="zh-HK"?"":"italic"}`}>{t('promotion.ticket.emailTips')}</p>
                            <div className="flex-layout flex-center flex-direction-column">
                                <button className="button button--light button--rounded title-shadow" type="submit" disabled={loading || props.loading}>
                                    <PointerRight/>
                                    <span>{t('promotion.ticket.send')}</span>
                                    <PointerLeft/>
                                </button>
                            </div>
                        </div>
                    ),
                }
            ]} />
            <div ref={elementRef} className="ticket-end"></div>
        </form>
    );
}

const Departure = () => {
    const {t} = useTranslation();
    return (
        <div className="departure">
            <div className="departure__legend title-copperplate bold">{t('promotion.ticket.departure')}</div>
            <div className="departure__wrapper">
                <div className="departure__content">
                    <div className="departure__corners">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div className="flex-layout flex-center flex-direction-column title-copperplate bold">
                        <div className="departure__year">{moment().add(1, "year").format("YYYY")}</div>
                        <div className="departure__month-grid">{
                            moment.monthsShort().map((month, index) => <div className={moment().format("MMM") === month ? "active":""}>{month}</div>)
                        }</div>
                    </div>
                </div>
            </div>
            <div className="departure__onewayticket title-copperplate bold">{t('promotion.ticket.onewayticket')}</div>
        </div>
    );
}

const MobileTicket = (props:{
    className?: string,
    style?: React.CSSProperties,
    pages: TicketPage[],
}) => {
    const {pages, className, style} = props;
    const {t } = useTranslation();

    return (
        <div className={`mobile-ticket ${className}`} style={style}>
            {pages.map((page, index) => {
                return (
                    <div className={`mobile-ticket__page ${page.theme} ${page.className}`} style={page.style} key={`page-${index}`}>
                        <div className={`mobile-ticket__edge mobile-ticket__edge--${page.theme==="dark"?"light":"dark"}`}>
                            <DottedLine />
                        </div>
                        <div className="mobile-ticket__corners">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        <div className="mobile-ticket__content">
                            {page.content}
                        </div>
                        {index === pages.length - 1 && <div className="mobile-ticket__tail title-cormorant">{t('promotion.subtitle')}</div>}
                        {index === pages.length - 1 && <div className={`mobile-ticket__edge mobile-ticket__edge--light`}>
                            <DottedLine />
                        </div>}
                    </div>
                );
            })}
        </div>
    );
}
