import React, { useState } from "react";
import { FooterProviderProps } from "types/ThemeTypes";

export const FooterContext = React.createContext({ 
    enable: true,
    setEnable: (enable: boolean) => {}
} as FooterProviderProps);

export const FooterContextProvider = (props: { children: React.ReactNode }) => {

    const setEnable = (e: boolean) => {
        setConfig(prev => { return prev.enable !== e ? { ...prev, enable: e } : {...prev} });
    }

    const initState: FooterProviderProps = {
        enable: true,
        setEnable: setEnable,
    }
    const [config, setConfig] = useState<FooterProviderProps>(initState);

    return (
        <FooterContext.Provider value={config}>
            {props.children}
        </FooterContext.Provider>
    )
}