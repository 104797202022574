import "./Disclaimer.scss";
import React from 'react';
import { ReactComponent as Logo } from "assets/images/logo-color-white.svg";
import moment from "moment";

export const Disclaimer = (props: {
    onEnterSite?: () => void,
}) => {

    const handleClickEnterSite = () => {
        localStorage.setItem("disclaimer", JSON.stringify(generateStorageDataObject()));
        props.onEnterSite?.();
    }

    const generateStorageDataObject = () => {
        return {
            confirmed: true,
            timestamp: moment().add(1, "h").valueOf()
        }
    }

    return (
        <>
            <div className="disclaimer-layout">
                <div className="disclaimer">
                    <div className="content">
                        <div className="logo">
                            <Logo />
                        </div>
                        <div className="passages">
                            <p className="title-bodoniModa italic">By clicking the “enter site” button, you confirm that you are the required age of your country to visit our website.
                            Under the law of Hong Kong, intoxicating liquor must not be sold or supplied to a minor
                        in the course of business.</p>
                            <p>你必須年滿當地合法年齡，才能進入此網站。點擊「進入網站」即表示你確認已年滿當地合法年齡。
                            根據香港法律，不得在業務過程中，
                        向未成年人售賣或供應令人醺醉的酒類。</p>
                        </div>
                        <div className="action">
                            <button className="button" onClick={handleClickEnterSite}><span className="title-bodoniModa italic">Enter Site</span> <span>進入網站</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}