import React, { useLayoutEffect } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Home } from './pages/Home/Home';
import { Product } from './pages/Product/Product';
import { NavBarContextProvider } from './components/Context/NavBarContext';
import { NavigationBar } from './components/NavigationBar/NavigationBar';
import { Faq } from 'pages/Faq/Faq';
import { Blog } from 'pages/Blog/Blog';
import { AboutUs } from 'pages/AboutUs/AboutUs';
import { BlogPost } from 'pages/Blog/BlogPost/BlogPost';
import { ProductDetail } from 'pages/Product/ProductDetail/ProductDetail';
import { Footer } from 'components/Footer/Footer';
import { ContactUs } from 'pages/ContactUs/ContactUs';
import { MediaCoverage } from 'pages/MediaCoverage/MediaCoverage';
import { PressRelease } from 'pages/PressRelease/PressRelease';
import { FooterContextProvider } from 'components/Context/FooterContext';
import { PrivacyPolicy } from 'pages/PrivatePolicy/PrivacyPolicy';
import { LoaderContextProvider } from 'components/Context/LoaderContext';
import { Promotion } from 'pages/Promotion/Promotion';
import { DisclaimerPage } from 'pages/DisclaimerPage/DisclaimerPage';
import { QueryClient, QueryClientProvider } from 'react-query';

import { ReactQueryDevtools } from 'react-query/devtools'
import ReactGA from 'react-ga';
import { environment } from 'environment/environment';


const baseLang = "/:locale(zh|en)";
const history = createBrowserHistory();
const queryClient = new QueryClient();

const App = () => {

  useLayoutEffect(() => {

    ReactGA.initialize(`${environment.GATrackingID}`);

    return () => {
    }
  }, [])

  return (
    <NavBarContextProvider>
      <FooterContextProvider>
        <LoaderContextProvider>
          <QueryClientProvider client={queryClient}>
            <Router history={history}>
              <NavigationBar />
              <Switch>
                <Route exact path={`${baseLang}/`} component={Home} />
                <Route exact path={`${baseLang}/faq`} component={Faq} />
                <Route exact path={`${baseLang}/product`} component={Product} />
                <Route exact path={`${baseLang}/about-us`} component={AboutUs} />
                <Route exact path={`${baseLang}/news/:page`} component={Blog} />
                <Route exact path={`${baseLang}/contact-us`} component={ContactUs} />
                <Route exact path={`${baseLang}/disclaimer`} component={DisclaimerPage} />
                <Route path={`${baseLang}/blog/post/:id`} component={BlogPost} />
                <Route path={`${baseLang}/product/:name`} component={ProductDetail} />
                <Route path={`${baseLang}/sober-thoughts/:page`} component={MediaCoverage} />
                <Route path={`${baseLang}/press-room/:page`} component={PressRelease} />
                <Route exact path={`${baseLang}/privacy`} component={PrivacyPolicy} />

                <Route exact path={`${baseLang}/promotion`} component={Promotion} />
                <Route path={`/promotion`} render={({ location }) => <Redirect to={`/en${location.pathname}${location.search}`} />} />

                <Redirect from={`${baseLang}/news`} to={`${baseLang}/news/1`} />
                <Redirect from={`${baseLang}/sober-thoughts`} to={`${baseLang}/sober-thoughts/1`} />
                <Redirect from={`${baseLang}/press-room`} to={`${baseLang}/press-room/1`} />

                <Redirect from={`/zh`} to={`/zh/`} />
                <Redirect from={`/en`} to={`/en/`} />
                <Redirect from={`/`} to={`/en/`} />

              </Switch>
              <Footer />
            </Router>
            <ReactQueryDevtools initialIsOpen={true} />
          </QueryClientProvider>
        </LoaderContextProvider>
      </FooterContextProvider>
    </NavBarContextProvider>
  );
}

export default App;
