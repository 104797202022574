import React, { useEffect, useRef, useState } from 'react';
import './FadableComponent.scss';

export const FadableComponent = (props: {
    children: (JSX.Element|string)|(JSX.Element|string)[], 
    className?: string,
    delay?: number,
}) => {
    const [isVisible, setVisible] = useState(false);
    const domRef = useRef<any>();
    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => setVisible(entry.isIntersecting));
        });
        observer.observe(domRef.current);
        domRef.current.style.setProperty('--delay', `${props.delay ?? 0}s`);
        return () => {
            if (domRef.current)
                observer.unobserve(domRef.current);
        }
    }, []);
    return (
        <div
            className={`${props.className ?? ""} fade-in-section ${isVisible ? 'is-visible' : ''}`}
            ref={domRef}
        >
        {props.children}
        </div>
    );
}