import { getMonthString } from 'helpers/date/dateHelper';
import { getLocaleUrl } from 'helpers/i18n/locale';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { PostModel } from 'types/BlogPostType';
import './BlogPostPreview.scss';

export const BlogPostPreview = (props: { content?: PostModel, big: boolean, seed: number }) => {

    const { i18n } = useTranslation();
    const locale = getLocaleUrl(i18n);

    const post = {
        title: locale == "en" ? props.content?.title : props.content?.titleZH,
        content: locale == "en" ? props.content?.content : props.content?.contentZH,
        backgroundImage: locale == "en" ? props.content?.backgroundImage : props.content?.backgroundImageZH,
        publishAt: props.content?.publishAt,
        tags: props.content?.tags,
    };

    const filterHTMLTags = (text: string | null) => {
        if (!text) return "";

        return text.replace(/(<([^>]+)>)/ig, '');
    }

    return (
        <>
            <div className={`blog-post-preview-wrapper ${props.big ? "big-thumbnail" : ""}`}>
                <div className="blog-post-preview-thumbnail-container">
                    <a href={`/blog/${getLocaleUrl(i18n)}/${props.content?.postUrl}`}>
                        {/* <Skeleton className="blog-post-preview-thumbnail-skeleton" /> */}
                        <img src={(props.content && post.backgroundImage) ?? undefined} className="blog-post-preview-image" />
                        <div className="blog-post-preview-thumbnail-mask" />
                    </a>
                </div>

                <div className="blog-post-preview-text-wrapper">
                    <div className="blog-post-preview-text-left title-shadow">
                        <div className="blog-post-preview-text-month">
                            {props.content && getMonthString(post.publishAt?.slice(5, 7)).toUpperCase()}
                        </div>
                        <div className="blog-post-preview-text-date">
                            {props.content && post.publishAt?.slice(8, 10)}
                        </div>
                    </div>
                    <div className="blog-post-preview-text-right title-myanmar">
                        <a href={`/blog/${getLocaleUrl(i18n)}/${props.content?.postUrl}`} className="blog-post-preview-text-title title-bodoniModa pre-line">
                            {props.content && post.title}
                        </a>
                        <div className="blog-post-preview-text-desc-container">
                            {props.content && <div 
                                className="blog-post-preview-text-desc title-myanmar" 
                                dangerouslySetInnerHTML={{ __html: filterHTMLTags(post.content ?? "") }}
                            ></div>}
                        </div>
                    </div>
                </div>

                <div className="blog-post-preview-hr"></div>

                <div className="blog-post-preview-tags">
                    {props.content ? post.tags?.map(tag => tag.name).join(", ") : "No tags"}
                </div>

                <div className="blog-post-preview-bottom-hr"></div>
            </div>
        </>
    )
}