import { FadableComponent } from 'components/FadableComponent/FadableComponent';
import React from 'react';
import './FounderDennis.scss'

import dennis from 'assets/images/about-us/dennis-bkg.png';
import dennisDeco from 'assets/images/about-us/dennis-deco.png';
import { MenuThemeSetter } from 'components/MenuThemeSetter/MenuThemeSetter';
import { useTranslation } from 'react-i18next';

export const FounderDennis = () => {

    const { t, i18n } = useTranslation();
    const lang = i18n.language === "en-US" ? "en" : "zh";

    return (
        <MenuThemeSetter theme="light" className="founder-dennis-section fullpage">
            <div id="founder-dennis-section" className="founder-dennis-section__background page-container">
                <div className="sticky-founder-image">
                    <img src={dennis} alt="Founder Dennis" />
                </div>
            </div>

            <div className="fullpage__page">
                <div className="page-container">
                    <div className="founder-dennis-section__description">
                        <div className={`founder-dennis-section__content founder-quote ${lang}`}>
                            <div className="p font-l">{t("about_us_dennis.caption")}</div>
                            <FadableComponent>
                                <h4 className={`title-bodoniModa`}>{t("about_us_dennis.quote")}</h4>
                            </FadableComponent>
                            <div className="p font-l">{t("about_us_dennis.caption_2")}</div>
                            {/* <div>
                                <FadableComponent className="italic title-m title-bodoniModa">{t("about_us_dennis.quote")}</FadableComponent>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="fullpage__page">
                <div className="page-container">
                    <div className="founder-dennis-section__history">
                        <div className="founder-dennis-section__content">
                            <div>
                                <h6 className="founder-dennis-section__title">
                                    <span className="title-s title-shadow">{t("about_us_dennis.name")}</span>
                                    <p className="font-l">CO-FOUNDER</p>
                                    <br />
                                    {/* <span className="font-s">調酒師 / 創業家 / 前投資銀行交易員</span> */}
                                    <div className="line-decoration line-decoration--left">
                                        <img src={dennisDeco} alt="deco" />
                                    </div>
                                </h6>
                            </div>
                            <div>
                                <div className="caption pre-line">{t("about_us_dennis.desc_1")}</div>
                                {/* <div className="p font-l">{t("about_us_dennis.desc_2")} </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MenuThemeSetter>
    );
}