import React, { useContext, useLayoutEffect, useRef } from "react"
import './Magnolia3.scss'

import { useViewportScroll } from "framer-motion";
import { NavBarContext } from "components/Context/NavBarContext";

import { ProductCocktail } from "../ProductCocktail/ProductCocktail";

import magnoliaProduct from 'assets/images/product/bottle-magnolia-cps.png';
import magnoliaRocks from "assets/images/product/Magnolia on rock.png";
import magnoliaTonic from "assets/images/product/Magnolia tonic.png";
import { Plants } from "pages/Product/Plants/Plants";
import { FadableComponent } from "components/FadableComponent/FadableComponent";
import { useTranslation } from "react-i18next";


export const Magnolia3 = () => {
    const { t } = useTranslation();

    const ref = useRef<any>(null);
    const { scrollY } = useViewportScroll();
    const { setTheme } = useContext(NavBarContext);

    useLayoutEffect(() => {
        const unsubscribe = scrollY.onChange(() => {
            const rect = ref.current.getBoundingClientRect();

            if (rect.top <= 0 && -rect.top <= rect.height) {
                setTheme("light");
            }
        })
        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <div ref={ref} className="magnolia-section-3" id="magnolia-section-3">
            <div className="enjoy-with">
                <div className="enjoy-with__bottle">
                    <figure>
                        <img src={magnoliaProduct} alt="Magnolia Bottle" />
                        <FadableComponent className={`enjoy-with__caption title-xl font-shadow--6`}>{t('product_detail_enjoy.title')}</FadableComponent>
                    </figure>
                    <Plants active={true}></Plants>
                </div>
            </div>

            <ProductCocktail
                src={magnoliaRocks}
                figcaption={`Magnolia\n on the Rocks`}
                recipe={{
                    title: (
                        <h5 className={`title-shadow`}>Recipes</h5>
                    ),
                    content: (
                        <p className={'font-m pre-line'}>{t('product_detail_enjoy.magnolia.on_the_rock')}</p>
                    ),
                }}
                tips={{
                    title: (
                        <h5 className={`title-shadow`}>Tips</h5>
                    ),
                    content: (
                        <p className={'font-m pre-line'}>{t('product_detail_enjoy.magnolia.on_the_rock_tips')}</p>
                    )
                }}
            ></ProductCocktail>

            <ProductCocktail
                src={magnoliaTonic}
                figcaption={`Magnolia Tonic`}
                recipe={{
                    title: (
                        <h5 className={`title-shadow`}>Recipes</h5>
                    ),
                    content: (
                        <p className={'font-m pre-line'}>{t('product_detail_enjoy.magnolia.tonic')}</p>
                    ),
                }}
                tips={{
                    title: (
                        <h5 className={`title-shadow`}>Tips</h5>
                    ),
                    content: (
                        <p className={'font-m pre-line'}>{t('product_detail_enjoy.magnolia.tonic_tips')}</p>
                    )
                }}
            ></ProductCocktail>
        </div>
    )
}