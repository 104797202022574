export const getMonthString = (month: string | undefined): string => {
    switch (month) {
        case "01": return "Jan";
        case "02": return "Feb";
        case "03": return "Mar";
        case "04": return "Apr";
        case "05": return "May";
        case "06": return "Jun";
        case "07": return "Jul";
        case "08": return "Aug";
        case "09": return "Sep";
        case "10": return "Oct";
        case "11": return "Nov";
        case "12": return "Dec";
        default: return "???";
    }
}

export const formatDate = (dateString: string | null | undefined): string => {
    if (!dateString) return ""

    const year = dateString.slice(0, 4);
    const month = dateString.slice(5, 7);
    const date = dateString.slice(8, 10);

    return `${getMonthString(month)} ${date}, ${year}`;
}