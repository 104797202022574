import { useEffect, useState } from "react";
import { ArticleEntryType } from "types/RepublicRelationType";
import { ArticleListEntry } from "./ArticleListEntry";
import "./ArticleListView.scss"

import { ReactComponent as PageButton } from './../../assets/images/button-page.svg'
import { useMediaQuery } from "react-responsive";
import { isPropertySignature } from "typescript";
import { useQuery, useQueryClient } from "react-query";
import { getPublicPost } from "helpers/api/public/public";
import { useHistory, useParams } from "react-router-dom";
import { getLocaleUrl } from "helpers/i18n/locale";
import { useTranslation } from "react-i18next";

export const ArticleListView = (props: { targetUrl: string, title: string }) => {

    const { page } = useParams<{ page: string }>();
    const history = useHistory();
    const { i18n } = useTranslation();

    const queryClient = useQueryClient();
    const postsQuery = useQuery([props.targetUrl, parseInt(page)], () => getPublicPost(props.targetUrl, parseInt(page)));

    useEffect(() => {
        if (postsQuery.data?.totalPage && postsQuery.data?.totalPage < parseInt(page))
            handlePageChange(postsQuery.data.totalPage);
    }, [postsQuery.data])

    const handlePageChange = (page: number) => {
        if (page < 1) return;
        history.push(`/${getLocaleUrl(i18n)}/${props.targetUrl}/${page}`);
    }

    return (
        <>
            {props.title && <div className="article-list-view-title title-shadow">{props.title}</div>}
            {postsQuery.data && postsQuery.data.posts.map((entry, index) =>
                <>
                    <ArticleListEntry article={entry} key={`article${parseInt(page)}-${index}`} />
                    <div key={`divider-${parseInt(page)}-${index}`} className="article-list-entry-divider" />
                </>
            )}
            <ArticleListPagination totalPage={postsQuery.data ? postsQuery.data.totalPage : 0} currentPage={parseInt(page)} onChangePage={handlePageChange} />
        </>
    );
}

export const ArticleListPagination = (props: { totalPage: number, currentPage: number, onChangePage: (page: number) => void }) => {

    const isMedium = useMediaQuery({ query: "(min-width: 800px)" });

    const renderPaginationPageButtons = () => {

        const totalOtherPageShowMaximum = isMedium ? 4 : 2;

        const showFirstEllipsis = isMedium ? (props.currentPage > 3) : (props.currentPage > 2);
        const showLastEllipsis = isMedium ? (props.totalPage >= props.currentPage + 3) : (props.totalPage >= props.currentPage + 2);

        const preCalcPagesShowBeforeCurrentPage = Math.min(totalOtherPageShowMaximum, props.currentPage - 1);
        const preCalcPagesShowAfterCurrentPage = Math.min(totalOtherPageShowMaximum, props.totalPage - props.currentPage);

        const pagesShowBeforeCurrentPage = preCalcPagesShowBeforeCurrentPage > totalOtherPageShowMaximum / 2 && preCalcPagesShowAfterCurrentPage > totalOtherPageShowMaximum / 2
            ? Math.ceil(totalOtherPageShowMaximum / 2)
            : preCalcPagesShowBeforeCurrentPage === totalOtherPageShowMaximum
                ? totalOtherPageShowMaximum - preCalcPagesShowAfterCurrentPage
                : preCalcPagesShowBeforeCurrentPage;

        const pagesShowAfterCurrentPage = preCalcPagesShowBeforeCurrentPage > totalOtherPageShowMaximum / 2 && preCalcPagesShowAfterCurrentPage > totalOtherPageShowMaximum / 2
            ? Math.ceil(totalOtherPageShowMaximum / 2)
            : preCalcPagesShowAfterCurrentPage === totalOtherPageShowMaximum
                ? totalOtherPageShowMaximum - preCalcPagesShowBeforeCurrentPage
                : preCalcPagesShowAfterCurrentPage;

        const renderContent: JSX.Element[] = [];

        if (showFirstEllipsis) {
            renderContent.push(<ArticleListPaginationPageButton key={"page-1-button"} text={"1"} onClick={() => props.onChangePage(1)} />);
            renderContent.push(<ArticleListPaginationPageButton key={"first-ellipsis"} text={"..."} />);
        }

        // Render before
        for (let pointer = props.currentPage - pagesShowBeforeCurrentPage; pointer < props.currentPage; pointer++) {
            renderContent.push(<ArticleListPaginationPageButton key={`page-${pointer}-button`} text={pointer.toString()} onClick={() => props.onChangePage(pointer)} />);
        }

        renderContent.push(<ArticleListPaginationPageButton key={`page-${props.currentPage}-button`} text={props.currentPage.toString()} isCurrentPage />);

        // Render after
        for (let pointer = props.currentPage + 1; pointer <= props.currentPage + pagesShowAfterCurrentPage; pointer++) {
            renderContent.push(<ArticleListPaginationPageButton key={`page-${pointer}-button`} text={pointer.toString()} onClick={() => props.onChangePage(pointer)} />);
        }

        if (showLastEllipsis) {
            renderContent.push(<ArticleListPaginationPageButton key={"last-ellipsis"} text={"..."} />);
            renderContent.push(<ArticleListPaginationPageButton key={`page-${props.totalPage}-button`} text={props.totalPage.toString()} onClick={() => props.onChangePage(props.totalPage)} />);
        }

        return renderContent;
    }

    return (
        <div className="article-list-view-pagination-wrapper">
            <div className="article-list-view-pagination-previous-button">
                <PageButton width={18} height={18} onClick={() => props.onChangePage(props.currentPage - 1)} />
            </div>

            {renderPaginationPageButtons()}

            <div className="article-list-view-pagination-next-button">
                <PageButton width={18} height={18} onClick={() => props.onChangePage(props.currentPage + 1)} />
            </div>
        </div>
    )
}

export const ArticleListPaginationPageButton = (props: { text: string, isCurrentPage?: boolean, onClick?: () => void }) => {

    const handleClick = () => {
        if (!props.onClick) return;
        props.onClick!();
    }

    return (
        <div
            className={`article-list-view-pagination-page-number-button ${props.onClick !== undefined && "clickable"} ${props.isCurrentPage && "current-page"}`}
            onClick={handleClick}
        >
            {props.text}
        </div >
    )
}