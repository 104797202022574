import './IngredientPopup.scss'
import { AnimatePresence, motion } from "framer-motion"
import React from 'react'

import { ReactComponent as Close } from 'assets/images/icon-close.svg'

import angelica from "assets/images/product/ingredients--angelica.svg"
import ginger from "assets/images/product/ingredients--ginger.svg"
import jujube from "assets/images/product/ingredients--jujube.svg"
import magnolia from "assets/images/product/ingredients--magnolia.svg"
import mulberries from "assets/images/product/ingredients--mulberries.svg"
import roselle from "assets/images/product/ingredients--roselle.svg"
import sandalwood from "assets/images/product/ingredients--sandalwood.svg"
import tangerine from "assets/images/product/ingredients--tangerine.svg"
import { useTranslation } from 'react-i18next'

export const IngredientPopup = (props: {
    visible: boolean,
    config: IngredientPopupConfig,
    onClose?: () => void,
}) => {
    const {t} = useTranslation();
    const {config} = props;

    return (
        <>
            <AnimatePresence>
                {props.visible && <motion.div className={`ingredient-popup flex-layout flex-center`}
                    initial={{opacity: 0, pointerEvents: "none"}}
                    animate={{opacity: 1, pointerEvents: "auto"}}
                    exit={{opacity: 0, pointerEvents: "none"}}
                >
                    <div className="ingredient-popup__container flex-layout flex-center">
                        <figure><img src={config.img} alt={t(`product_detail_ingredient.${config.label}`)} /></figure>
                        <div className="ingredient-popup__content flex-layout flex-direction-column flex-start">
                            <h4 className="title-shadow">{t(`product_detail_ingredient.${config.label}`)}</h4>
                            <span className="font-s">{config.subtitle}</span>
                            <p className="font-m">{t(`product_detail_ingredient.${config.label}_desc`)}</p>
                        </div>
                        <div className="close" onClick={() => {
                            if (props.onClose) props.onClose();
                        }}>
                            <Close className="close__icon" />
                            <span className="close__text">Close</span>
                        </div>
                    </div>
                </motion.div>}
            </AnimatePresence>
        </>
    )
}

export interface IngredientPopupConfig {
    label: string,
    img: string,
    imgAlt: string,
    title: string,
    subtitle: string,
    content: string,
}

export const IngredientConfigs = {
    magnolia: {
        label: "magnolia",
        img: magnolia,
        imgAlt: "Magnolia Berries",
        title: "Magnolia Berries",
        subtitle: "",
        content: "Magnolia berries, a.k.a. “five flavour fruit”, is a unique vine plant native to the forests of Northeast Asia that packs all five primary flavours: sweetness, saltiness, tartness, bitterness and pungency. In Chinese culture, the five tastes are an allegory for the vicissitudes of life and represent the five elements of Nature. Magnolia berries are therefore considered in oriental herbology to be of great health benefits, such as the nourishment of the lungs, kidneys and liver, strengthening of the immune system and calming of the nerves. ",
    },
    mulberries: {
        label: "mulberries",
        img: mulberries,
        imgAlt: "Mulberries",
        title: "Mulberries",
        subtitle: "",
        content: "Juicy with a delightful balance of sweet and tart flavours, mulberries are a tasty treat eaten fresh as well as dried. They are a good source of iron and vitamin C and offer a wide range of health benefits, including nourishing the heart, liver, kidney and eyes, anti-aging, weight loss, enhancing blood production and improving sleep quality.",
    },
    tangerine: {
        label: "tangerine",
        img: tangerine,
        imgAlt: "Sun-Dried Tangerine Peels",
        title: "Sun-Dried Tangerine Peels",
        subtitle: "",
        content: "With its rich citrus aroma and distinct bitter aftertaste, aged tangerine peel is commonly used as a spice in Cantonese cuisine. Aged for extended periods from years to decades, dried tangerine peel boasts health benefits such as aiding digestion and relieving cough as well as bloating, and good vintages can be pricey. Aged tangerine peel used in Magnolia Lab’s products is between five to 15 years old.",
    },
    sandalwood: {
        label: "sandalwood",
        img: sandalwood,
        imgAlt: "Sandalwood",
        title: "Sandalwood",
        subtitle: "",
        content: "Exuding a warm and exotic sweet aroma with a hint of spice, sandalwood has been used as a fragrance, especially in temples and shrines, for centuries for its purifying and calming qualities. It is also believed to remove the stuffiness of the chest and coldness in the body, as well as boosts circulation and relieves pain. ",
    },
    roselle: {
        label: "roselle",
        img: roselle,
        imgAlt: "Roselle",
        title: "Roselle",
        subtitle: "",
        content: "The brightly coloured calyces of roselle possess a sweet and sour taste and are often processed into jams, preserves or pickles, and drinks consumed for relief during hot weather. Roselle is also believed to aid digestion and weight loss, lower blood pressure, prevent cardiovascular diseases and possess anti-aging qualities.",
    },
    jujube: {
        label: "jujube",
        img: jujube,
        imgAlt: "Jujube",
        title: "Jujube",
        subtitle: "",
        content: "With its rich sweetness and nourishing qualities, dried jujube is a common ingredient in Asian and Middle Eastern cuisine and used in everything from desserts, snacks and drinks to soups and dishes. According to oriental herbology, jujube nourishes the blood, improves the complexion and has anti-aging qualities.",
    },
    ginger: {
        label: "ginger",
        img: ginger,
        imgAlt: "Ginger",
        title: "Ginger",
        subtitle: "",
        content: "One of the most common spices in Asian cuisine, ginger is often used to spice up a dish or remove unpleasant odours in fish or meat. Unlike the juicier and lighter young ginger, dried old ginger root is much spicier and packs a lot of heat. Its warming attribute is believed to nourish the stomach and uterus, relieve pain, nausea and symptoms of the common cold, and calm the nerves.",
    },
    angelica: {
        label: "angelica",
        img: angelica,
        imgAlt: "Chinese Angelica Root",
        title: "Chinese Angelica Root",
        subtitle: "",
        content: "With its powerful earthy, warm and bittersweet aroma, Chinese angelica root (angelica sinensis) is often used in Asian cuisine as a spice and local varieties are used in the West to produce fragrances, and essential oils. Chinese angelica root is known for its healing qualities and considered highly beneficial for women’s health for its effect on blood circulation as well as discomfort associated with menstruation and menopause.",
    },
};
