import { NavBarContext } from "components/Context/NavBarContext";
import { NavigationMenuItem } from "components/NavigationMenuListItem/NavigationMenuItem";
import { motion } from "framer-motion";
import { useContext, useEffect, useRef } from "react";
import "./NavigationMenu.scss"
import { ReactComponent as Facebook } from './../../assets/images/icon-facebook.svg'
import { ReactComponent as Instagram } from './../../assets/images/icon-instagram.svg'
import { useMediaQuery } from "react-responsive";
import { getLocaleUrl } from "helpers/i18n/locale";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { environment } from "environment/environment";
import { ShopEvent } from "helpers/google-analytic/ga";

export const NavigationMenu = () => {

    const { t, i18n } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const { menuOpen } = useContext(NavBarContext);

    const breakpoint = useMediaQuery({ query: "(min-width: 1200px)" });

    const toggleLanguage = () => {
        const locale = location.pathname.split("/")[1];
        document.body.classList.remove(`locale-${getLocaleUrl(i18n)}`);
        if (i18n.language === "en-US") {
            i18n.changeLanguage("zh-HK");
            history.push(location.pathname.replace(locale, "zh"));
        }
        else if (i18n.language === "zh-HK") {
            i18n.changeLanguage("en-US");
            history.push(location.pathname.replace(locale, "en"));
        }
        document.body.classList.add(`locale-${getLocaleUrl(i18n)}`);
    }

    const variants = {
        visible: {
            opacity: 1,
            height: window.innerHeight,
            display: "block",
        },
        hidden: {
            opacity: 0,
            height: 0,
            transitionEnd: {
                display: "none",
            },
        }
    };

    useEffect(() => {
        if (menuOpen) {
            // console.log("Disable scroll");
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [menuOpen]);

    return (
        <motion.div
            className="menu-background"
            variants={variants}
            animate={menuOpen ? "visible" : "hidden"}
            transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.4 }}
            initial={false}
        >
            <div className="menu-container">
                <NavigationMenuItem name={t('menu.about_us')} href={`/${getLocaleUrl(i18n)}/about-us`} color="dark" animationDelay={0.25} />
                <NavigationMenuItem name={t('menu.product')} href={`/${getLocaleUrl(i18n)}/product`} color="dark" animationDelay={0.3} />
                <NavigationMenuItem name={t('menu.news')} href={`/${getLocaleUrl(i18n)}/news`} color="dark" animationDelay={0.35} />
                <NavigationMenuItem name={t('menu.contact')} href={`/${getLocaleUrl(i18n)}/contact-us`} color="dark" animationDelay={0.4} />
                <NavigationMenuItem name={t('menu.shop')} href={`${environment.shopUrl}`} onClick={ShopEvent} target="_blank" color="light" animationDelay={0.45} />
                {/* {!breakpoint && <NavigationMenuItem name={i18n.language === "zh-HK" ? "Eng" : "中文"} onClick={toggleLanguage} color="dark" animationDelay={0.5} />} */}
                {
                    !breakpoint &&
                    (
                        <div className="mobile-social-media-section">
                            <a href={`${environment.facebookUrl}`}>
                                <Facebook id="facebook" />
                            </a>
                            <a href={`${environment.instagramUrl}`}>
                                <Instagram id="instagram" />
                            </a>
                        </div>
                    )
                }
            </div>
        </motion.div>
    )
}