import { BackToTopButton } from "components/BackToTopButton/BackToTopButton";
import { NavBarContext } from "components/Context/NavBarContext";
import { DisclaimerPopup } from "components/DisclaimerPopup/DisclaimerPopup";
import { FaqEntry } from "components/FaqEntry/FaqEntry";
import GoToTop from "components/GoToTop/GoToTop";
import { MenuThemeSetter } from "components/MenuThemeSetter/MenuThemeSetter";
import { PromotionPopup } from "components/PromotionPopup/PromotionPopup";
import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import './Faq.scss'
import ReactGA from 'react-ga';

export const Faq = () => {
    const { setEnable, setShow, setTheme, setShowLogo } = useContext(NavBarContext);

    const { t } = useTranslation();

    useEffect(() => {
        
        ReactGA.pageview(window.location.pathname + window.location.search);

        setEnable(true);
        setShow(true);
        setTheme("light");
        setShowLogo(true);
    }, []);

    return (
        <>
            <Helmet>
                <title>{t('header.title.faq')}</title>
                <meta name="description" content={t('header.des.faq')} />
            </Helmet>
            
            {/* <Redirector /> */}
            <DisclaimerPopup />
            
            <PromotionPopup />
            
            <MenuThemeSetter theme="light" className="faq-page">
                <div className="faq-group">
                    {[...new Array(4)].map((n, index) => {
                        return (
                            <FaqEntry
                                question={t(`faq.q${index + 1}`)}
                                answer={t(`faq.a${index + 1}`)}
                            />
                        );
                    })}
                </div>
            </MenuThemeSetter>

            <GoToTop></GoToTop>
            <BackToTopButton />
        </>
    )
}