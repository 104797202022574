import { motion } from "framer-motion"
import SpicyImage from "./../../../assets/images/product-detail/journey-spicy-image.png";
import SpicyDeco from "./../../../assets/images/product-detail/spicy-deco.png";
import { useContext, useEffect, useState } from "react";
import { JourneyPageContext } from "components/Context/JourneyPageContext";
import { useTranslation } from "react-i18next";

export const SpicySection = () => {
    const { t } = useTranslation();
    const { page, pageNames } = useContext(JourneyPageContext);

    const [thisPageIndex, setThisPageIndex] = useState<number>(0);
    const [internalPage, setInternalPage] = useState<number>(0);

    useEffect(() => {
        setInternalPage(page - thisPageIndex);
    }, [page]);

    useEffect(() => {
        if (pageNames !== []) {
            setThisPageIndex(pageNames.indexOf("Spicy") + 1)
        }
    }, [pageNames]);

    const config = {
        "show": {
            opacity: 1
        },
        "hide": {
            opacity: 0
        }
    }

    const bottomConfig = {
        "show": {
            y: 0,
            transition: {
                type: "tween",
                ease: "easeOut"
            }
        },
        "hide": {
            y: 1000,
            transition: {
                type: "tween",
                ease: "easeIn"
            }
        }
    }

    return (
        <>
            <motion.div
                className="product-detail-journey-section-content-fade-in background-cover"
                initial="hide"
                variants={bottomConfig}
                animate={internalPage >= 0 ? "show" : "hide"}
                style={{ backgroundImage: `linear-gradient(#8E0000, #8E0000), url(${SpicyDeco})` }}
            >
            </motion.div>

            <motion.div
                className="product-detail-journey-section-content"
                initial="hide"
                variants={config}
                animate={internalPage >= 0.25 && internalPage <= 0.75 ? "show" : "hide"}
            >
                <img src={SpicyImage} className="product-detail-journey-section-spicy-image" />
                <div className="product-detail-journey-section-title-text spicy-text">
                    {t("product_detail_journey.spicy")}
                </div>
            </motion.div>
        </>
    )
}