import { DecoStar } from 'components/DecoStar/DecoStar';
import React from 'react';
import { TimelineNodeConfig } from "types/TimelineTypes";
import './Timeline.scss'

import { ReactComponent as FlowerTopRight } from './../../../assets/images/about-us/liquor-journey-intro4.svg'
import FlowerTopLeft2 from './../../../assets/images/about-us/liquor-journey-intro3.png'
import FlowerTopLeft from './../../../assets/images/about-us/liquor-journey-intro5.png'
import FlowerBottom from './../../../assets/images/about-us/liquor-journey-intro6.png'
import FlowerBottomRight from './../../../assets/images/about-us/liquor-journey-intro7.png'
import { LinerComponent } from 'components/LinerComponent/LinerComponent';
import { FadableComponent } from 'components/FadableComponent/FadableComponent';

export const Timeline = (props: { nodes: TimelineNodeConfig[] }) => {

    return (
        <div className="center-timeline">

            <LinerComponent className="scrolldown-line" offset={200} style={{ height: "100%" }} />

            <div className="timeline-nodes-container">
                {
                    props.nodes.map((node, index) => (
                        <TimeLineNode key={"timeline-node-" + index} config={node} seed={Math.random() * (index + 1)} />
                    ))
                }
            </div>

            <FlowerTopRight className="timeline-top-right-flower" />
            <img src={FlowerTopLeft} alt="" className="timeline-top-left-flower" />
            <img src={FlowerTopLeft2} alt="" className="timeline-top-left-flower2" />
            <img src={FlowerBottom} alt="" className="timeline-bottom-flower" />
            <img src={FlowerBottomRight} alt="" className="timeline-bottom-right-flower" />


        </div>
    );
}

export const TimeLineNode = (props: { config: TimelineNodeConfig, seed: number }) => {
    return (
        <FadableComponent className={`timeline-node-wrapper ${props.config.side === "left" ? "left-node" : "right-node"}`}>
            <figure className="timeline-node-image">
                <img src={props.config.src} alt="" />
            </figure>

            <div className="timeline-node-star"><DecoStar /></div>

            <div className={`timeline-node-text-wrapper`}>
                <div className={`timeline-node-title ${props.config.side === "left" ? "left-node" : "right-node"}`}>
                    {props.config.title}
                </div>
                <div className={`timeline-node-desc pre-line ${props.config.side === "left" ? "left-node" : "right-node"}`}>
                    {props.config.desc}
                </div>
            </div>
        </FadableComponent>
    );
}