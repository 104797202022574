import { PromotionForm, PromotionPremiumForm } from '../../../pages/Promotion/Promotion';
import { Api } from '../api';
import { environment } from "environment/environment";

export const create = (form: PromotionForm): Promise<Response> =>
{
    const api = new Api();
    return api.post(`${environment.apiUrl}/postcard/e`, {
        senderName: form.senderName,
        senderEmail: form.senderEmail,
        receiverName: form.receiverName,
        receiverEmail: form.receiverEmail,
        content: form.content,
        subscribed: form.subscribed ? "1":"0",
    });
}
export const createPremium = (form: PromotionPremiumForm): Promise<Response> =>
{
    const api = new Api();
    return api.post(`${environment.apiUrl}/postcard/hard`, {
        code: form.code,
        name: form.name,
        email: form.email,
        content: form.content,
        address: form.address,
        phone: form.phone,
        subscribed: form.subscribed ? "1":"0",
    });
}
export const validateCode = (code: string): Promise<Response> =>
{
    const api = new Api();
    return api.get(`${environment.apiUrl}/validate/code/${code}`);
}