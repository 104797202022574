import { environment } from 'environment/environment';
export class Api {
    private cors = {
        'Access-Control-Allow-Origin': `${environment.apiUrl}`,
    };

    public get(url: string, params: any = undefined) {
        return fetch(
            `${url}${params ? `?${new URLSearchParams(params)}` : ""}`,
            {
                method: "GET",
                headers: new Headers({
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    ...this.cors,
                }),
            }
        )
            .then(res => {
                if (!res.ok) throw new Error("Bad server response");
                return res;
            })
            .then(res => res.json());
    }

    public post(url: string, body?: { [key: string]: string }) {

        return fetch(
            `${url}`,
            {
                method: "POST",
                headers: new Headers({
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    ...this.cors,
                }),
                body: JSON.stringify(body),
            }
        )
            .then(res => {
                if (!res.ok) throw new Error("Bad server response");
                return res;
            })
            .then(res => res.json());
    }

    public patch(url: string, body?: { [key: string]: string }) {

        return fetch(
            `${url}`,
            {
                method: "PATCH",
                headers: new Headers({
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    ...this.cors,
                }),
                body: JSON.stringify(body),
            }
        )
            .then(res => {
                if (!res.ok) throw new Error("Bad server response");
                return res;
            })
            .then(res => res.json());
    }

    public delete(url: string) {

        return fetch(
            `${url}`,
            {
                method: "DELETE",
                headers: new Headers({
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    ...this.cors,
                }),
            }
        )
            .then(res => {
                if (!res.ok) throw new Error("Bad server response");
                return res;
            })
            .then(res => res.json());
    }
}