import { motion } from "framer-motion"
import PlumImage from "./../../../assets/images/product-detail/journey-plum-image.png";
import PlumDecoTop from "./../../../assets/images/product-detail/plum-deco-top.png";
import PlumDecoBottom from "./../../../assets/images/product-detail/plum-deco-bottom.png";
import { useContext, useEffect, useState } from "react";
import { JourneyPageContext } from "components/Context/JourneyPageContext";
import { useTranslation } from "react-i18next";

export const PlumSection = () => {
    const { t } = useTranslation();
    const { page, pageNames } = useContext(JourneyPageContext);

    const [thisPageIndex, setThisPageIndex] = useState<number>(0);
    const [internalPage, setInternalPage] = useState<number>(0);

    useEffect(() => {
        setInternalPage(page - thisPageIndex);
    }, [page]);

    useEffect(() => {
        if (pageNames !== []) {
            setThisPageIndex(pageNames.indexOf("Plum") + 1)
        }
    }, [pageNames]);

    const config = {
        "show": {
            opacity: 1
        },
        "hide": {
            opacity: 0
        }
    }

    const bottomConfig = {
        "show": {
            y: 0,
            transition: {
                type: "tween",
                ease: "easeOut"
            }
        },
        "hide": {
            y: 800,
            transition: {
                type: "tween",
                ease: "easeIn"
            }
        }
    }

    const topConfig = {
        "show": {
            y: 0,
            transition: {
                type: "tween",
                ease: "easeOut"
            }
        },
        "hide": {
            y: -800,
            transition: {
                type: "tween",
                ease: "easeIn"
            }
        }
    }

    return (
        <>
            <motion.div
                className="product-detail-journey-section-content-fade-in top-fade-in"
                initial="hide"
                variants={topConfig}
                animate={internalPage >= 0 && internalPage <= 1 ? "show" : "hide"}
                style={{ backgroundImage: `url(${PlumDecoTop})` }}
            >
            </motion.div>

            <motion.div
                className="product-detail-journey-section-content-fade-in bottom-fade-in"
                initial="hide"
                variants={bottomConfig}
                animate={internalPage >= 0 && internalPage <= 1 ? "show" : "hide"}
                style={{ backgroundImage: `url(${PlumDecoBottom})` }}
            >
            </motion.div>

            <motion.div
                className="product-detail-journey-section-content"
                initial="hide"
                variants={config}
                animate={internalPage >= 0.25 && internalPage <= 0.75 ? "show" : "hide"}
            >
                <img src={PlumImage} className="product-detail-journey-section-plum-image" />
                <div className="product-detail-journey-section-title-text plum-text">
                    {t("product_detail_journey.plum")}
                </div>
            </motion.div>
        </>
    )
}