import { useEffect, useState } from 'react';
import './DisclaimerPopup.scss'
import { AnimatePresence, motion } from 'framer-motion';
import { Disclaimer } from 'components/Disclaimer/Disclaimer';
import { DisclaimerType } from 'types/DisclaimerTypes';

export const DisclaimerPopup = () => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (show)
        {
            document.body.style.overflow = "hidden";
        }
        else
        {
            document.body.style.overflow = "auto";
        }
    }, [show])

    useEffect(() => {
        checkConfirmedDisclaimer();
        return () => {
            document.body.style.overflow = "auto";
        }
    }, []);

    const checkConfirmedDisclaimer = () => {
        const data = localStorage.getItem("disclaimer");
        if (data !== null) {
            try {
                const dataObject = JSON.parse(data) as DisclaimerType;
                if (!(dataObject.confirmed && dataObject.timestamp >= Date.now())) {
                    setShow(true);
                }
            }
            catch (e) {
                setShow(true);
            }
        }
        else {
            setShow(true);
        }
    }

    return (
        <AnimatePresence>
            {show && <motion.div className="disclaimer-popup"
                initial={{}}
                exit={{opacity: 0}}
                transition={{duration: 1}}
            >
                <Disclaimer onEnterSite={() => setShow(false)} />
            </motion.div>}
        </AnimatePresence>
    );
}