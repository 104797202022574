import { ArticleListPagination } from "components/ArticleListView/ArticleListView";
import { BackToTopButton } from "components/BackToTopButton/BackToTopButton";
import { BlogPostPreview } from "components/BlogPostPreview/BlogPostPreview";
import { NavBarContext } from "components/Context/NavBarContext";
import { DisclaimerPopup } from "components/DisclaimerPopup/DisclaimerPopup";
import GoToTop from "components/GoToTop/GoToTop";
import { PromotionPopup } from "components/PromotionPopup/PromotionPopup";
import { getPosts } from "helpers/api/blog/post";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useMediaQuery } from "react-responsive";
import { useHistory, useParams } from "react-router-dom";
import { getLocaleUrl } from 'helpers/i18n/locale';
import './Blog.scss'
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';

export const Blog = () => {

    const isMedium = useMediaQuery({ query: "(min-width: 800px)" });
    const isLarge = useMediaQuery({ query: "(min-width: 1200px)" });

    const history = useHistory();
    const { t, i18n } = useTranslation();

    const { page } = useParams<{ page: string }>();

    const postsQuery = useQuery(["blogPage", parseInt(page)], () => getPosts(parseInt(page)));

    const { setEnable, setShow, setTheme, setShowLogo } = useContext(NavBarContext);

    useEffect(() => {
        
        ReactGA.pageview(window.location.pathname + window.location.search);

        setEnable(true);
        setShow(true);
        setTheme("light");
        setShowLogo(true);
    }, []);

    const determineIsBig = (index: number): boolean => {
        if (isLarge) {
            const remainder = index % 10;
            return remainder === 0 || remainder === 6;
        }
        else if (isMedium) {
            return index % 3 === 0;
        }
        else {
            return true;
        }
    }

    useEffect(() => {
        if (postsQuery.data?.totalPage && postsQuery.data?.totalPage < parseInt(page))
            handlePageChange(postsQuery.data.totalPage);
    }, [postsQuery.data])

    const handlePageChange = (page: number) => {
        if (page < 1) return;
        history.push(`/${getLocaleUrl(i18n)}/news/${page}`);
    }

    return (
        <>
            <Helmet>
                <title>{t('header.title.news')}</title>
                <meta name="description" content={t('header.des.news')} />
            </Helmet>
            
            {/* <Redirector /> */}
            <DisclaimerPopup />

            <PromotionPopup />

            <div className="blog-page">
                {(!postsQuery.data) && <div className="coming-soon">
                    <h4>Loading...</h4>
                </div>}
                {(postsQuery.data && postsQuery.data.posts.length === 0) && <div className="coming-soon">
                    <h4>Coming Soon...</h4>
                </div>}
                {postsQuery.data && <>
                    <div className="blog-group">
                        {postsQuery.data.posts.map((post, index) => <BlogPostPreview content={post} big={determineIsBig(index)} seed={Math.random() * (index + 1)} />)}
                    </div>
                    <ArticleListPagination totalPage={postsQuery.data ? postsQuery.data.totalPage : 0} currentPage={parseInt(page)} onChangePage={handlePageChange} />
                </>}
            </div>
            <GoToTop></GoToTop>
            <BackToTopButton />
        </>
    )
}