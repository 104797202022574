import { JourneyPageContext } from "components/Context/JourneyPageContext";
import { motion } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LeafyImage from "./../../../assets/images/product-detail/journey-leafy-image.png";
import LeafyDeco from "./../../../assets/images/product-detail/leafy-deco.png";

export const LeafySection = () => {
    const { t } = useTranslation();
    const { page, pageNames } = useContext(JourneyPageContext);

    const [thisPageIndex, setThisPageIndex] = useState<number>(0);
    const [internalPage, setInternalPage] = useState<number>(0);

    useEffect(() => {
        setInternalPage(page - thisPageIndex);
    }, [page]);

    useEffect(() => {
        if (pageNames !== []) {
            setThisPageIndex(pageNames.indexOf("Leafy") + 1)
        }
    }, [pageNames]);

    const config = {
        "show": {
            opacity: 1
        },
        "hide": {
            opacity: 0
        }
    }

    const bottomConfig = {
        "show": {
            y: 0,
            transition: {
                type: "tween",
                ease: "easeOut"
            }
        },
        "hide": {
            y: 800,
            transition: {
                type: "tween",
                ease: "easeIn"
            }
        }
    }

    return (
        <>
            <motion.div
                className="product-detail-journey-section-content-fade-in"
                initial="hide"
                variants={bottomConfig}
                animate={internalPage >= 0 ? "show" : "hide"}
                style={{ backgroundImage: `linear-gradient(#0F2E0B, #0F2E0B), url(${LeafyDeco})` }}
            >
            </motion.div>

            <motion.div
                className="product-detail-journey-section-content"
                initial="hide"
                variants={config}
                animate={internalPage >= 0.25 && internalPage <= 0.75 ? "show" : "hide"}
            >
                <img src={LeafyImage} className="product-detail-journey-section-leafy-image" />
                <div className="product-detail-journey-section-title-text leafy-text">
                    {t("product_detail_journey.leafy")}
                </div>
            </motion.div>
        </>
    )
}