import { Api } from '../api';
import { environment } from "environment/environment";
import { PostModel, RawPostContentModel } from 'types/BlogPostType';

export const getPosts = async (page: number, limit: number = 10): Promise<{ totalPage: number, posts: PostModel[] }> => {
    const api = new Api();

    return api.get(`${environment.apiUrl}/post/news`, {
        page: page,
        limit: limit
    })
        .then((response) => {
            return {
                totalPage: response.result.last_page,
                posts: response.result.data as PostModel[]
            };
        })
        .catch((err) => {
            throw err;
        });
}

export const getPostById = async (postId: number): Promise<RawPostContentModel> => {
    const api = new Api();

    return api.get(`${environment.apiUrl}/post/${postId}`)
        .then((response) => {
            return {
                post: response.result as PostModel,
                relatedPosts: response.result.relatedPosts as PostModel[],
            };
        })
        .catch((err) => {
            throw err;
        });
}

export const getRelatedPost = async (tagIds: number[] | undefined, limit: number = 3): Promise<PostModel[]> => {
    if (!tagIds) return [];

    const api = new Api();

    const tasks = tagIds.map(tag => api.get(`${environment.apiUrl}/tag/${tag}/posts`));

    return Promise.all(tasks)
        .then((response) => {
            return response.map(entry => entry.result as PostModel).flat().slice(-3);
        })
        .catch((err) => {
            throw err;
        });
}