import { JourneyPageContext } from "components/Context/JourneyPageContext";
import { motion } from "framer-motion"
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SandalwoodImage from "./../../../assets/images/product-detail/journey-sandalwood-image.png";

export const SandalwoodSection = () => {
    const { t } = useTranslation();
    const { page, pageNames } = useContext(JourneyPageContext);

    const [thisPageIndex, setThisPageIndex] = useState<number>(0);
    const [internalPage, setInternalPage] = useState<number>(0);

    useEffect(() => {
        setInternalPage(page - thisPageIndex);
    }, [page]);

    useEffect(() => {
        if (pageNames !== []) {
            setThisPageIndex(pageNames.indexOf("Sandalwood") + 1)
        }
    }, [pageNames]);

    const config = {
        "show": {
            opacity: 1
        },
        "hide": {
            opacity: 0
        }
    }

    return (
        <motion.div
            className="product-detail-journey-section-content"
            initial="hide"
            variants={config}
            animate={internalPage >= 0.25 && internalPage <= 0.75 ? "show" : "hide"}
        >
            <img src={SandalwoodImage} className="product-detail-journey-section-sandalwood-image" />
            <div className="product-detail-journey-section-title-text sandalwood-text">
                {t("product_detail_journey.sandalwood")}
            </div>
        </motion.div>
    )
}