import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const zhTranslations = {
    translation: {
        "header": {
            "title": {
                "home": "東方草本利口酒 | 五味雜陳",
                "about-us": "品牌故事 | 五味雜陳",
                "product": "我們的利口酒 | 五味雜陳",
                "magnolia": "我們的利口酒：五味雜陳 | 五味雜陳",
                "roselle": "我們的利口酒：閉月羞花 | 五味雜陳",
                "news": "最新資訊、綱誌及推廣優惠 | 五味雜陳",
                "contact-us": "聯絡我們 | 五味雜陳",
                "faq": "常見問題 | 五味雜陳",
                "promotion": "給下一站的我 | 五味雜陳──酒醒之時",
                "press-room": "新聞稿及媒體報道 | 五味雜陳",
                "sober-thoughts": "酒醒之時 | 五味雜陳",
            },
            "des": {
                "home": "在五味雜陳的時代，喝一口像人生的酒。",
                "about-us": "當調酒師遇上中醫師，二人決定延續歷史悠久的浸泡酒傳統，讓更多人能欣賞中式草本的豐富味道；一系列草本利口酒就此誕生。",
                "product": "快來試試的我們的兩款利口酒：「五味雜陳」和「閉月羞花」，認識當中主要草本成份、其養生功效，以及調酒小貼士。",
                "magnolia": "「五味雜陳」以五味子、陳皮、桑椹、檀香等草本材料浸泡而成，味道複雜富層次，單喝或調配雞尾酒皆宜。",
                "roselle": "「閉月羞花」以洛神花  紅棗  乾薑  當歸等草本材料浸泡而成，有養顏暖宮之效，可謂女生chill out之選，單喝或調配雞尾酒皆宜。 ",
                "news": "不要錯過各類有關調酒、浸酒、品酒的最新資訊、文章及推廣優惠！",
                "contact-us": "歡迎透過電話或電郵與「五味雜陳」聯繫，我們會盡快點覆你。",
                "faq": "浸泡酒和蒸餾、發酵酒有何分別？「五味雜陳」和「閉月羞花」建議如何飲用為佳？中醫不是認為喝酒傷身嗎？「五味雜陳」採用的草本材料來自哪裡？來源可靠嗎？",
                "promotion": "在五味雜陳的時代，你可有片言隻字想寫給未來的自己，立此存照？請寫下信息，到時我們會透過「五味雜陳」獨家電子明信片寄到你手上，讓你回味人生旅程的這一站。",
                "press-room": "歡迎下載新聞稿及瀏覽媒體對我們的報道",
                "sober-thoughts": "透過「酒醒之時」，我們將邀請了不同音樂人、藝術家透過聯承創作為我們分享他們對人生五味的體會，請密切留意！",
            }
        },
        "promotion": {
            "subtitle": "「五味雜陳」酒醒之時",
            "title": "給下一站的我",
            "content": "受疫情籠罩的年多來，每天打開新聞，世界各地充斥着令人焦慮的消息。上一代可以定下五年、十年的人生規劃，而面對今天的亂流，365天以後的世界或許已經難以預見。\n\n人生如一趟冒險旅行，緊握自己人生的單程車票，不管世事如何變化，你的列車每年都得往下一站出發。「五味雜陳」希望為今年這一站的你留下剪影，來年不管你身處何方，我們會超越時空，為你寄上來自今天這個你的片言隻字，為這一年的旅程留下見證。",
            "ticket": {
                "question1": "一年後，你會增添了甚麼經歷、過着怎樣的生活？",
                "question2": "這年的努力，能令你無悔驕傲嗎？",
                "question3": "到時你的稜角已消失還是堅持猶在？回望時，你希望看見怎樣的自己？",
                "instruction": "請寫下你想對將來的自己想說的話，我們會於一年後為你寄出「五味雜陳」獨家電子車票 (明信片)，讓你回味人生旅程的這一站。",
                "instruction_premium": "請寫下你想對將來的自己想說的話，我們會於一年後為你寄出「五味雜陳」獨家車票 (明信片)，讓你回味人生旅程的這一站。",
                "dear": "給",
                "placeholder": "請寫下你想對將來的自己想說的話，我們會於一年後為你寄出「五味雜陳」獨家電子車票 (明信片)，讓你回味人生旅程的這一站。",
                "placeholder_premium": "請寫下你想對將來的自己想說的話，我們會於一年後為你寄出「五味雜陳」獨家車票 (明信片)，讓你回味人生旅程的這一站。",
                "characters": "字數",
                "departure": "到達",
                "onewayticket": "單程車票",
                "address": "地址",
                "email": "電郵*",
                "emailTips": "*提供電郵資料即表示你同意透過電郵收取「五味雜陳」的推廣資料。",
                "send": "發送",
            }
        },
        "loading": "Loading",
        "menu": {
            "about_us": "關於「五味雜陳」",
            "shop": "線上購買",
            "product": "產品資訊",
            "news": "最新消息",
            "contact": "聯絡我們",
        },
        "home_section_1": {
            "subtitle_darkgold": "Oriental Botanical Liqueur",
            "subtitle": "- 顛覆傳統中式草本味道 -",
        },
        "home_section_2": {
            "title": "顛覆傳統中式草本味道",
            "subtitle": "從調酒，到透過酒讓東方養生概念進一步融入生活，變成一種能與朋友分享的品味與享受，這是個天馬行空的過程。每次試酒，不同中式草本泡出來的酒，一啖啖甜酸苦辣鹹的微妙變化，都是個走向未知的旅程。\n\n希望有一天，大家Happy Hour時，會想到喝一杯令人身心放鬆而具味道層次的中式草本浸泡酒。",
            "slogan": "在五味雜陳的時代\n喝一口像人生的酒",
        },
        "home_section_3": {
            "product_link": "帶我進入草本酒的世界"
        },
        "about_us_intro": {
            "slogan_1": "踏上旅程，投入未知國度",
            "slogan_2": "追尋夢想，無懼旁人目光",
            "slogan_3": "勇敢求變，讓世界跟着你走",
        },
        "about_us_section_1": {
            "slogan_1": "踏上旅程，\n投入未知國度",
            "slogan_2": "",
            "slogan_3": "",
            "p": "2020年疫情肆虐全球，所有計劃被打亂，猶如被偷走了一年。\n但我們則趁世界停頓的空檔，沉澱、反思、創造，尋找初心，發掘自己真正渴望的事物。",
            "caption": "「五味雜陳」正是誕生於這個獨特的時空。"
        },
        "about_us_dennis": {
            "quote": "我們於是以西方調酒的技術，配合味道、香氣、功效俱佳的優質中式草本，調配出美味的草本浸泡酒，讓這傳統成為現代lifestyle的一部份。",
            "caption": "家裡有一埕浸泡了二十多年的藥酒，是媽媽浸來給爸爸補身的，味道可能不怎麼樣，卻是上一輩對至親表達關懷、照顧的心意。這些漸被遺忘的傳統，失傳了實在太可惜。",
            "caption_2": "希望跟大家分享中式草本獨特迷人的色香味，同時不乏溫和調理的效果，也盛載一份關心照顧自己身心靈之意。",
            "name": "DENNIS MAK",
            "desc_1": "熱愛騎着電單車周遊列國的投資銀行交易員，毅然放棄穩定工作追夢。先當上了調酒師，一圓年少時的夢；又與朋友聯手推廣船屋體驗，保育並讓更多人認識歷史悠久的香港傳統漁村文化。他最熱愛的還是酒，透過調酒、品酒，希望能將關懷至親、傳承文化的心意融入酒中，調出一種帶有人情與傳統的味道。",
            "desc_2": ""
        },
        "about_us_james": {
            "quote": "中醫應用和發展可以怎樣再進一步？其實草本材料本身含豐富味道，入藥的草本很多都是平常烹調用到的香料、食材；自己工作處方時，也會盡量選用香味宜人、廣為大眾接受的草本。而我亦喜歡喝酒，於家中自己調hot toddy時，也會加入合適的東方草本，增添味覺享受之餘，同時帶有調整身心之意。",
            "caption": "假如中醫概念變成一種品味、生活；你不再是拿着一碗苦茶，而是拿着一杯酒，那會是很有意思的一件事。",
            "name": "丁皡",
            "desc_1": "註冊中醫師，常於媒體分享保健知識，如香港電台《醫生與你》、蘋果日報專欄等。",
            "desc_2": "自幼受父母及朋友薰陶，熱愛美食及下廚，亦對茶藝和咖啡有濃厚興趣。偶然接觸調酒後，對當中的變化樂此不疲，遂加入熟悉的中式草本，細細玩味，思其配方、產地及炮製方法所帶來的層次和風味，驀然發現應讓更多人了解與體驗中式草本結合調酒的可塑性，擴闊味覺的疆界。",
            "desc_3": ""
        },
        "about_us_story": {
            "meet": "當調酒師遇上\n中醫師",
            "meet_desc": "調酒師Dennis和愛喝雞尾酒的舊同學、現職中醫師的James，一次偶然在酒吧閒談，話題自然觸及調酒和草本養生概念。",
            "story_lifestyle": "大家不約而同認為，東方草本除了可以熬成需要捏着鼻子才灌得下去的「苦茶」外，當中帶舒緩感又濃烈的傳統天然芬芳，跟品酒時那種複雜、具層次的味蕾刺激，有異曲同工之妙。",
            "story_born_1": "二人一拍即合，以Dennis調酒的技術和James對中式草本的專業知識，慢慢開始碰撞出一番獨特的滋味。",
            "story_born_2": "各種草本有助調養身心；酒則帶人釋放情感，放鬆精神。在香港以至全世界，陷入一片混沌的期間，二人每週相約聚頭，蒐羅各種天然的優質中式草本，搭配不同中式基酒反覆嘗試、篩選，希望打破大眾對傳統浸泡酒的既定形象，調配出襯托這代人生命中，集齊甜酸苦辣鹹的味道：一種能配搭不同mixer、不同場合的中式草本浸泡酒。經過一年多的努力，",
            "conclusion": "「五味雜陳」的兩支liqueur由此而生。"
        },
        "about_us_timeline": {
            "title_1": "小酌養生",
            "p_1": "以各類花果、草本製作的浸泡酒，在人類文明中有數千年歷史，被視為養生保健佳品。酒本性溫，味辛而苦甘，有溫通血脈、溫暖腸胃等作用。《漢書‧食貨志》甚至有云：「酒，百藥之長」。古人發現通過浸泡技術，各式材料的味道、營養等精華能溶於酒中，可以保存更長時間，更易被身體吸收，保健效果亦得以提高，而且方便服用，易於儲存，故應用非常廣泛。",
            "title_2": "傳統智慧",
            "p_2": "東方草本泡酒的基酒一般以當地出產的五穀類蒸餾或發酵而成，配合不同季節道地草本的特性，採用冷浸法、熱浸法、煎煮法和滲漉法等，製作出不同濃度的浸泡酒。若以成效區分，可分成適合一般人飲用的保健酒，和需按醫生指示服用的治病藥酒。保健酒更成為日常養生，以至節慶飲食傳統的一部分，如人蔘酒、百歲酒、梅酒、新年的喝屠蘇酒、椒柏酒；端午節喝的艾酒等，反映古人的傳統智慧。",
        },
        "product": {
            "magnolia": "五味雜陳",
            "roselle": "閉月羞花"
        },
        "product_detail": {
            "infusion": "主要草本成份",

            "magnolia_berry": "五味子",
            "tangerine_peel": "陳皮",
            "mulberry": "桑椹",
            "sandalwood": "檀香",

            "roselle_flower": "洛神花",
            "jujube": "紅棗",
            "ginger": "乾薑",
            "angelica_root": "當歸",

            "alcohol_title": "酒精濃度",
            "alcohol_amount": "{{amount}} VOL",

            "magnolia_title": "天然草本的豐富內涵",
            "magnolia_p_1": "酒如其名，初嚐五味子，才發現世間原來酸、甜、苦、辣、鹹味俱全的中式草本。五味俱全，五行相生，養五臟之氣，正展現了中式草本的豐富奇妙內涵。",
            "magnolia_p_2": "加上陳皮的沉穩甘香，桑椹的清甜，檀香的典雅醇厚，實為「五味雜陳」。輕呷一口，讓思緒跟着味蕾，細嚐人生的各種味道。",

            "roselle_title": "女神之選",
            "roselle_p_1": "傳說中的洛神，絕色天下，溺水而揮別塵世。被册封為掌管洛水的神明，同時代表愛情與美麗；曹植的《洛神賦》所描述的洛神，更是滿身天地靈氣的經典女神。",
            "roselle_p_2": "這款以洛神花製作的浸泡酒，有養顏暖宮之效，故取名「閉月羞花」，可謂女神chill out之選。洛神花的清新酸度，平衡紅棗的軟綿香甜、乾薑的溫口辛辣，而當歸的獨特香氣貫穿其中，入口酸酸甜甜，加上中式草本的辛香餘韻縈繞，彷彿給身體一個和暖的擁抱。",

            "disclaimer": "「五味雜陳」所採用的草本材料均來自香港頂級中式草本供應商「百子櫃」，保證從道地產區嚴選上乘佳品；挑選原植物最佳部分，並於當造時節採收；炮製技藝火候精準，用料講究；保留原色而不漂白，亦無多餘人工添加劑，確保入酒草本天然健康，提供最高味覺享受。",

            "back": "回到產品資訊",
            "see_more": "了解更多",
        },
        "product_detail_enjoy": {
            "title": "ENJOY ALSO WITH",
            "magnolia": {
                "on_the_rock": "「五味雜陳」\n 冰塊適量\n 1片橙皮",
                "on_the_rock_tips": "刨一條橙皮，先將在杯上將橙皮扭轉並稍為擠壓，將橙油擠到杯中，然後將橙皮在杯緣抺一圈，令其沾上橙油，其清新果香令陳皮和檀香的味道更為立體，整杯「五味雜陳」的味道頓時變得更有層次；最後可將橙皮放在杯邊作裝飾。",
                "tonic": "1份「五味雜陳」\n 1份湯力水\n 1片鮮橙",
                "tonic_tips": "湯力水裡來自奎寧（Quinine）那隱約的微苦，與陳皮的回甘相得益章，最能帶出「五味雜陳」的豐富增次。",
            },
            "roselle": {
                "on_the_rock": "「閉月羞花」\n 冰塊適量\n 檸檬汁少許\n 1片檸檬皮",
                "on_the_rock_tips": "將檸檬皮扭轉並稍為擠壓，把檸檬油擠到杯中，然後將檸檬皮在杯緣抺一圈，令其沾上檸檬油香氣。檸檬汁的清新果酸，為「閉月羞花」的溫暖甘甜帶來完美平衡；冰塊的降溫和稀釋效果，令酒變得更順喉，亦有助釋放酒香。",
                "tonic": "1份「閉月羞花」\n 1份梳打水\n 1片檸檬",
                "tonic_tips": "梳打水的氣泡，為「閉月羞花」添上一份清爽輕盈，最適合炎炎夏日。",
            },
        },
        "product_detail_journey": {
            "prologue": "讓我們跟着味蕾走一趟……",
            "sandalwood": "檀香",
            "citrus": "柑橘類",
            "leafy": "綠葉",
            "berry": "莓果",
            "plum": "李子",
            "floral": "花香",
            "spicy": "辛辣",
        },
        "product_detail_ingredient": {
            "magnolia": "五味子",
            "magnolia_desc": "五味子果實常呈紅色或紫紅色，唐朝《新修本草》載五味子：「皮肉甘酸，核中辛苦，都有鹹味」，因而得名，這些複雜微妙、意想不到的味道正是「五味雜陳」的靈感來源。根據中醫理論，五味子具有補肺腎、斂精氣、安神、平衡神經系統、保護肝臟及增強免疫力的功效。",
            "mulberries": "桑椹",
            "mulberries_desc": "桑椹是桑樹的果實，酸甜多汁，可新鮮或曬乾食用，營養豐富，功效眾多，包括滋補心、肝、腎；明目，延緩白髪生長、抗衰老；且能降脂、滋陰補血、利五臟關節、安魂鎮神，有助改善失眠。",
            "tangerine": "陳皮",
            "tangerine_desc": "陳皮即橘子皮，廣東新會出產為最佳，以當地盛產的大紅柑為原材料，經曬乾並陳化數年至數十年不等，價值、香味和功效均跟隨年份而倍增。「五味雜陳」的產品一律用上五至十五年不等的優質新會陳皮，味道甘香醇舊。陳皮是廣東菜常見的香料，具理氣健脾、燥濕化痰、幫助消化和消除腸道積氣的功效。",
            "sandalwood": "檀香",
            "sandalwood_desc": "檀香用於宗教儀式由來已久，佛教經書《佛說旃檀樹經》中道：「此樹香潔，世所稀有... ...樹名栴檀，根莖枝葉，治人百病，其香遠聞，世之奇異。」其略帶辛辣而又溫暖甜美的木香，能辟邪淨化、安神凝氣、使人祥和愉悅，亦具利膈寬胸、散寒調中、行氣止痛之效。",
            "roselle": "洛神花",
            "roselle_desc": "洛神花常用的花萼部份，顏色鮮豔、酸甜醒胃，一般會製成花茶、蜜餞或果醬等食用，具有清熱解渴、養顏消斑、降血壓、消脂、預防心血管疾病等功效。",
            "jujube": "紅棗",
            "jujube_desc": "紅棗味道醇厚香甜，營養豐富，令它成為亞洲菜中常見的食材。不論甜品、小食、飲品、湯水，以至各式菜餚均見其身影。紅棗具益氣補血、健脾和胃、袪風功效，美白祛斑、延緩衰老等效用，尤其受到女士喜愛。",
            "ginger": "乾薑",
            "ginger_desc": "薑是亞洲菜最常用的香料之一，迷人的溫暖香辛氣味能有效辟腥提鮮。有別於生薑，冬天收採再風乾的乾薑味道更為辛辣濃郁，能暖胃暖宮散寒、溫經止血、溫中止痛、鎮嘔鎮靜止咳等。",
            "angelica": "當歸",
            "angelica_desc": "當歸具有鮮明獨特的甘辛香氣，既能增香、辟除肉類的腥臊味，也具補血養生之效。明代著名醫師張景岳所著《景岳全書．本草正》記載：「當歸，其味甘而重，故專能補血；其氣輕而辛，故又能行血……誠血中之氣藥，亦血中之聖藥也。」可見其具調經、止痛、促進肝細胞再生等功效，被視為女士調理補身之選。",
        },
        "footer": {
            "title": "訂閱「五味雜陳」電子通訊",
            "enter_email": "輸入您的電郵",
            "about_us": "關於「五味雜陳」",
            "shop": "線上購買",
            "product": "產品資訊",
            "press": "新聞中心",
            "media": "酒醒之時",
            "faq": "FAQ",
            "terms": "條款及細則",
            "privacy": "私隱權聲明",
            "contact_email_title": "電郵:",
            "contact_tel_title": "電話:",
            "contact_address_title": "地址:",
            "contact_address": "香港葵涌打磚坪街68號和豐工業中心7 樓 710A 室",
            "copyright": "©{{year}}  Magnolia Concept Limited. All rights reserved.",
        },
        "faq": {
            "q1": "浸泡酒和蒸餾、發酵酒有何分別？",
            "a1": "發酵酒利用酵母菌將糖份轉化，並在過程中產生酒精，度數較低。蒸餾酒則是透過酒精沸點低的特性，將之與其他成份分離並加以濃縮，造成的高濃度烈酒。浸泡酒採用現成酒類，配合不同材料，以浸泡形式萃取當中可溶解於酒精的味道、顏色和營養素，味道千變萬化。三種方法亦會混合使用以達到理想的味道和濃度。\n\n 由於材料當中個別成份的沸點、溶解度等特質有所不同，所以同樣的材料，經過蒸餾、浸泡式發酵，成品的色、香、味都會各有不同，各有特色。",
            "q2": "「五味雜陳」和「閉月羞花」建議如何飲用為佳？",
            "a2": "兩款酒均為liqueur（利口酒），喝原味或加冰已經很順喉易入口，其獨特的東方草本芳香用來調雞尾酒酒或搭配中西美食亦能帶出來不同的味覺體驗。",
            "q3": "中醫不是認為喝酒傷身嗎？",
            "a3": "於中醫角度，酒性熱，亦較易聚濕，故針對濕熱體質而言，不宜多喝；但不同酒類亦有其偏性，適量飲用，亦能起補益、減壓助眠等作用。《漢書‧食貨志》有云「酒，百藥之長」，中西醫學都有以酒入藥的傳統，不少藥材亦需以酒製過始能發揮功效。",
            "q4": "「五味雜陳」採用的草本材料來自哪裡？來源可靠嗎？",
            "a4": "「五味雜陳」所採用的草本材料均來自香港頂級中式草本供應商「百子櫃」，保證從中國、東南亞等道地產區嚴選上乘佳品；挑選原植物最佳部分，並於當造時節採收；炮製技藝火候精準，用料講究；保留原色而不漂白，亦無多餘人工添加劑，確保入酒草本天然健康，提供最高味覺享受。",
        }
    }
}

const enTranslations = {
    translation: {
        "header": {
            "title": {
                "home": "Oriental Botanical Liqueur | Magnolia Lab",
                "about-us": "Brand story | Magnolia Lab",
                "product": "Our liqueurs | Magnolia Lab",
                "magnolia": "Explore our liqueurs: MAGNOLIA | Magnolia Lab",
                "roselle": "Explore our liqueurs: ROSELLE | Magnolia Lab",
                "news": "News, blogs and promotions | Magnolia Lab",
                "contact-us": "Contact us | Magnolia Lab",
                "faq": "FAQ | Magnolia Lab",
                "promotion": "Dear future self | SOBER THOUGHTS @ Magnolia Lab",
                "press-room": "Press release & coverage | Magnolia Lab",
                "sober-thoughts": "SOBER THOUGHTS @ Magnolia Lab",
            },
            "des": {
                "home": "Explore the rich flavours of top-quality oriental herbs infused in unique blends of Chinese liquors. Every sip is a journey.",
                "about-us": "A range of delectable oriental herbal liqueurs was born when a mixologist and a herbologist joined hands to revive the ancient tradition of liquor infusion that goes back thousands of years in Asia.",
                "product": "Find out all about our botanical infused liqueurs, Magnolia and Roselle, key herbal ingredients and their benefits, mixing tips and cocktail ideas.",
                "magnolia": "An infusion featuring magnolia berries, aged tangerine peel, mulberries and sandalwood, MAGNOLIA is a complex drink to be enjoyed either neat or in cocktails.",
                "roselle": "Infused with oriental herbs including roselle, jujube, dried ginger and Chinese angelica, ROSELLE is delightful and soothing either enjoyed neat or in cocktails.",
                "news": "Read all about liquors, spirits and cocktails, and check out our latest offerings and promotions.",
                "contact-us": "Contact us by phone or email and we will respond as soon as possible.",
                "faq": "What is the difference between infused, distilled and fermented liquors? What is the best way to enjoy Magnolia and Roselle? Where do Magnolia Lab’s botanical ingredients come from? Are they from reliable sources?",
                "promotion": "Slow down and check in with yourself, past, present and future. Write a letter to your future self and we will deliver it on an exclusive Magnolia Lab e-postcard. ",
                "press-room": "Download our press release and check out what media friends have been saying about us.",
                "sober-thoughts": "Watch this space for creative collaborations with musicians and artists who share their thoughts inspired by Magnolia Lab.",
            }
        },
        "promotion": {
            "subtitle": "SOBER THOUGHTS @ MAGNOLIA LAB",
            "title": "DEAR FUTURE SELF,",
            "content": "The pandemic has changed the world forever. People used to make plans for five or 10 years into the future. Now it seems impossible to predict what life will be like in 365 days. \n\nOne thing we know for certain is, life is a one-way journey. No matter what goes on at this stop, you are on the way to the next before you know it. Is there anything you wish to remind yourself of when you reach the next stop? Leave a message as a record of the present. One year from now, no matter where you are, Magnolia Lab will deliver to your future self the message from this particular moment of your life.",
            "ticket": {
                "question1": "What kind of life will you be living a year from now?",
                "question2": "Will you be proud of your achievements? Any regrets?",
                "question3": "Will you feel the same way about what is important to you today?",
                "instruction": "Write down your feelings, goals, or anything you wish to say to your future self. A year from now, the message will be delivered to you on an exclusive Magnolia Lab Ticket (Postcard).",
                "instruction_premium": "Write down your feelings, goals, or anything you wish to say to your future self. A year from now, the message will be delivered to you on an exclusive Magnolia Lab Ticket (Postcard).",
                "dear": "DEAR",
                "placeholder": "Write down your feelings, goals, or anything you wish to say to your future self......",
                "placeholder_premium": "Write down your feelings, goals, or anything you wish to say to your future self......",
                "characters": "Characters",
                "departure": "DEPARTURE",
                "onewayticket": "one way ticket",
                "address": "Enter your address",
                "email": "Enter your email*",
                "emailTips": "*By providing your email, you agree to receive promotion material from Magnolia Lab by email.",
                "send": "Send",
            }
        },
        "loading": "Loading",
        "menu": {
            "about_us": "ABOUT US",
            "shop": "SHOP",
            "product": "PRODUCTS",
            "news": "NEWS",
            "contact": "CONTACT",
        },
        "home_section_1": {
            "subtitle": "- Reinventing Oriental Botanical Liquor -",
            "subtitle_darkgold": ""
        },
        "home_section_2": {
            "title": "Mixology x Herbology",
            "subtitle": "From mixing cocktails, to using alcohol to infuse oriental wellness concepts and herbology into everyday life, and transforming them into something to be enjoyed and shared with friends—this was a journey into the unknown. Every time we tried a new infusion, the complex taste resulting from the indefinite combinations of herbs, liquors and time brought us on an adventure.\n\nWe look forward to the day when delicious oriental botanical liqueurs make it onto the happy hour drink list at your nearest bar!",
            "slogan": "Take the journey, make a change.",
        },
        "home_section_3": {
            "product_link": "SHOW ME THE DRINKS"
        },
        "about_us_intro": {
            "slogan_1": "TAKE A JOURNEY",
            "slogan_2": "DISCOVER YOUR DREAM",
            "slogan_3": "MAKE A CHANGE",
        },
        "about_us_section_1": {
            "slogan_1": "TAKE A JOURNEY",
            "slogan_2": "DISCOVER YOUR DREAM",
            "slogan_3": "MAKE A CHANGE",
            "p": "Year 2020 saw the world devastated by the pandemic. Plans became irrelevant and life was put on pause. Some call it a lost year, while others took it as an opportunity to reconnect with the inner self, to reflect, explore, and figure out what the heart really wanted.",
            "caption": "Magnolia Lab is a product of these unique circumstances."
        },
        "about_us_dennis": {
            "quote": "By utilising Western mixology and top-quality oriental herbs, we have created brand new, delicious botanical infused liqueurs that make delightful beverages complementing all occasions in the modern lifestyle.",
            "caption": "Since I was a child we have had a big jar of remedial herbal-infused liquor at home. My mother made it for my father as a restorative more than 20 years ago. The taste may not be for everyone but this homemade potion embodies the love and care of the older generation for their beloved.",
            "caption_2": "",
            "name": "DENNIS MAK",
            "desc_1": "A keen traveller who enjoyed roaming the earth on a motorbike, Dennis gave up a stable job as a trader in an investment bank in pursuit of bigger adventures. First he became a mixologist, a dream of his younger days. Then he decided to share his love for the fading traditions of Hong Kong by partnering with friends to offer houseboat experience in one of the city’s oldest fishing villages. \n\nHe is now returning to his ultimate passion, liquor. By reinventing an age-old custom that embodies an ancient wellness practice and the dedication of a wife and mother, he hopes to create and share brand-new flavours that evoke memories of kindness and tradition.",
            "desc_2": "He is now returning to his ultimate passion, liquor. By reinventing an age-old custom that embodies an ancient wellness practice and the dedication of a wife and mother, he hopes to create and share brand-new flavours that evoke memories of kindness and tradition."
        },
        "about_us_james": {
            "quote": "How can we bring the application of traditional Chinese medicine to the next level? The herbs used in Chinese medicine are in fact extremely flavourful and aromatic. Many of them are commonly used in various oriental cuisines as spices. Whenever I can, I tend to use herbs with appealing aromas and pleasant tastes in prescriptions for my patients. ",
            "caption": "If we can instil the concept of traditional Chinese medicine into the modern lifestyle, and channel it into an enjoyable drink instead of a bowl of bitter herbal medicine, that would be really awesome.",
            "name": "JAMES H. TING",
            "desc_1": "A registered Chinese medicine practitioner, James shares his medical knowledge regularly on major media platforms in Hong Kong, such as RTHK and Apple Daily.",
            "desc_2": "Influenced by family and friends, James developed a passion for food and cooking as a child. His epicurean interest also extended to tea and coffee making before mixology captured his imagination. Fascinated by its endless possibilities, he started experimenting with oriental herbs in cocktails and greatly enjoyed discovering how herbs of various origins and process methods in different combinations bring new tastes to the drink.",
            "desc_3": "With Magnolia Lab, James hopes to encourage people to expand their palates and experience the endless possibilities of oriental herbs and mixology."
        },
        "about_us_story": {
            "meet": "Mixology\nmeets\nHerbology",
            "meet_desc": "Cocktail connoisseurs Dennis, a mixologist, and James, a Chinese medicine practitioner, saw a lot in common between the rich and distinct aromas of oriental herbs and the complex tastes offered by liquor.",
            "story_lifestyle": "Both believed that oriental herbs can be much more than pungent and bitter herbal medicine, and liquor can be the key to incorporating traditional herbal therapy into the modern lifestyle. ",
            "story_born_1": "Herbs restore the balance in the body and mind, while alcohol calms the nerves and releases emotions.",
            "story_born_2": "In the hope of reinventing the tradition of remedial botanical-infused liquor, they strived to capture the rich and complex flavours of oriental herbs and create liqueurs that can be enjoyed on any occasion, either on their own or with mixers. After a year of trial and error,",
            "conclusion": "Magnolia Lab was born."
        },
        "about_us_timeline": {
            "title_1": "INFUSION AS REMEDY",
            "p_1": "Throughout human civilisation, botanicals have been infused in liquor and consumed as herbal restoratives or remedies. It was discovered thousands of years ago that, through infusion, the flavours and medicinal properties of plants were transferred to the alcohol and can therefore be preserved for a longer time.\n\nThe warming attribute of alcohol was considered to improve blood circulation and warm up the digestive system. These concoctions, which were convenient to store, easily consumed in exact doses and highly effective, were therefore widely consumed as remedies.",
            "title_2": "ANCIENT WISDOM",
            "p_2": "Liquor infusions are typically made with base liquors created from local grain crops. Various methods such as hot or cold maceration, decoction and percolation are used to infuse various seasonal botanicals. These infusions are consumed either as medicine to be taken under doctors’ instructions, or restoratives, which are much milder in nature and can be safely consumed without a diagnosis.\n\nOver time, the consumption of these restoratives has become a common wellness practice as well as seasonal festive custom to address the body’s needs at different times of the year. Examples include ginseng wine, baekse-ju (100 Year Wine), plum liquor, Tusu wine as well as pepper and cypress liquor enjoyed at new year celebrations, as well as mugwort liquor consumed in early summer.",
        },
        "product": {
            "magnolia": "MAGNOLIA",
            "roselle": "ROSELLE"
        },
        "product_detail": {
            "infusion": "KEY INGREDIENTS",

            "magnolia_berry": "Magnolia Berries",
            "tangerine_peel": "Aged Tangerine Peel",
            "mulberry": "Mulberries",
            "sandalwood": "Sandalwood",

            "roselle_flower": "Roselle",
            "jujube": "Jujube",
            "ginger": "Dried Ginger",
            "angelica_root": "Chinese Angelica",

            "alcohol_title": "",
            "alcohol_amount": "{{amount}} VOL",

            "magnolia_title": "COMPLEX FLAVOURS",
            "magnolia_p_1": "Magnolia berries, a.k.a. “five flavour fruit”, is a unique vine plant that packs all five primary flavours: sweetness, saltiness, tartness, bitterness and pungency, which in Chinese culture are an allegory for the vicissitudes of life. They are also considered to represent the five elements of Nature that correspond with various organs and functions of the body.",
            "magnolia_p_2": "Combining the distinct flavours of magnolia berries with the rich citrus aroma and mildly bitter aftertaste of sundried tangerine peel, fruity sweetness of mulberries and the earthy and exotic scent of sandalwood, MAGNOLIA is a complex drink for the sophisticated palate.",

            "roselle_title": "SOOTHING SWEETNESS",
            "roselle_p_1": "The refreshing tartness of roselle explodes on the palate, balanced by the nourishing sweetness of jujube, which blossoms into the earthy scent of Chinese angelica and the invigorating heat of dried ginger for a long, warming finish.",
            "roselle_p_2": "With its warming attribute, ROSELLE is a delightful and soothing drink particularly beneficial for women.",

            "disclaimer": "All herbs used in Magnolia Lab’s liqueurs are supplied by Hong Kong’s premium Chinese herb supplier, 100 Cabinet. 100 Cabinet goes through a meticulous quality control process to ensure that herbs offered are of the best quality. They are carefully sourced from the best origins, where they are harvested at the optimal time with only the most effect parts retained. The herbs are then processed to the highest standard. No bleaching agent and excessive artificial additives are used. All the herbs are guaranteed to be natural and of the best possible flavours.",

            "back": "BACK TO PRODUCTS",
            "see_more": "SEE MORE",
        },
        "product_detail_enjoy": {
            "title": "ENJOY ALSO WITH",
            "magnolia": {
                "on_the_rock": "Served with ice\n Garnished with orange peel",
                "on_the_rock_tips": "Remove a strip of orange rind with a peeler, twist it over the glass to squeeze its fragrant oil into the drink, then rub the orange peel around the rim. The aroma of fresh orange will highlight the aged tangerine peel and sandalwood in Magnolia. Garnish the drink with the orange twist. ",
                "tonic": "1 part Magnolia\n 1 part tonic water\n 1 slice of orange",
                "tonic_tips": "The faint bitterness of the quinine in tonic water echoes with the after taste of aged tangerine peel to highlight the complexity of Magnolia.",
            },
            "roselle": {
                "on_the_rock": "Served with ice\n A dash of lemon juice\n Garnished with lemon twist",
                "on_the_rock_tips": "Twist a strip of lemon peel over the glass to squeeze its fragrant oil into the drink, then rub the lemon peel around the rim. The sharpness of lemon perfectly balances the warm sweetness of Roselle, while ice chills and dilutes the alcohol, opening up the aromas and making the taste smoother.",
                "tonic": "1 part Roselle\n 1 part soda water\n 1 slice of lemon",
                "tonic_tips": "The fizz of soda water lightens the sweetness of Roselle, making it the perfect summer drink.",
            },
        },
        "product_detail_journey": {
            "prologue": "The magnolia\n journey of taste",
            "sandalwood": "Sandalwood",
            "citrus": "Citrus",
            "leafy": "Leafy",
            "berry": "Berry",
            "plum": "Plum",
            "floral": "Floral",
            "spicy": "Spicy",
        },
        "product_detail_ingredient": {
            "magnolia": "Magnolia Berries",
            "magnolia_desc": "Magnolia berries, a.k.a. “five flavour fruit”, is a unique vine plant native to the forests of Northeast Asia that packs all five primary flavours: sweetness, saltiness, tartness, bitterness and pungency. In Chinese culture, the five tastes are an allegory for the vicissitudes of life and represent the five elements of Nature. Magnolia berries are therefore considered in oriental herbology to be of great health benefits, such as the nourishment of the lungs, kidneys and liver, strengthening of the immune system and calming of the nerves. ",
            "mulberries": "Mulberries",
            "mulberries_desc": "Juicy with a delightful balance of sweet and tart flavours, mulberries are a tasty treat eaten fresh as well as dried. They are a good source of iron and vitamin C and offer a wide range of health benefits, including nourishing the heart, liver, kidney and eyes, anti-aging, weight loss, enhancing blood production and improving sleep quality.",
            "tangerine": "Aged Tangerine Peels",
            "tangerine_desc": "With its rich citrus aroma and distinct bitter aftertaste, aged tangerine peel is commonly used as a spice in Cantonese cuisine. Aged for extended periods from years to decades, dried tangerine peel boasts health benefits such as aiding digestion and relieving cough as well as bloating, and good vintages can be pricey. Aged tangerine peel used in Magnolia Lab’s products is between five to 15 years old.",
            "sandalwood": "Sandalwood",
            "sandalwood_desc": "Exuding a warm and exotic sweet aroma with a hint of spice, sandalwood has been used as a fragrance, especially in temples and shrines, for centuries for its purifying and calming qualities. It is also believed to remove the stuffiness of the chest and coldness in the body, as well as boosts circulation and relieves pain. ",
            "roselle": "Roselle",
            "roselle_desc": "The brightly coloured calyces of roselle possess a sweet and sour taste and are often processed into jams, preserves or pickles, and drinks consumed for relief during hot weather. Roselle is also believed to aid digestion and weight loss, lower blood pressure, prevent cardiovascular diseases and possess anti-aging qualities.",
            "jujube": "Jujube",
            "jujube_desc": "With its rich sweetness and nourishing qualities, dried jujube is a common ingredient in Asian and Middle Eastern cuisine and used in everything from desserts, snacks and drinks to soups and dishes. According to oriental herbology, jujube nourishes the blood, improves the complexion and has anti-aging qualities.",
            "ginger": "Dried Ginger",
            "ginger_desc": "One of the most common spices in Asian cuisine, ginger is often used to spice up a dish or remove unpleasant odours in fish or meat. Unlike the juicier and lighter young ginger, dried old ginger root is much spicier and packs a lot of heat. Its warming attribute is believed to nourish the stomach and uterus, relieve pain, nausea and symptoms of the common cold, and calm the nerves.",
            "angelica": "Chinese Angelica",
            "angelica_desc": "With its powerful earthy, warm and bittersweet aroma, Chinese angelica (angelica sinensis) is often used in Asian cuisine as a spice and local varieties are used in the West to produce fragrances, and essential oils. Chinese angelica is known for its healing qualities and considered highly beneficial for women’s health for its effect on blood circulation as well as discomfort associated with menstruation and menopause.",
        },
        "footer": {
            "title": "Subscribe to Magnolia Lab’s Newsletter",
            "enter_email": "Enter your email",
            "about_us": "About Us",
            "shop": "Online Shop",
            "product": "Products",
            "press": "Press Room",
            "media": "Sober Thoughts",
            "faq": "FAQ",
            "terms": "Terms & Conditions",
            "privacy": "Privacy Policy",
            "contact_email_title": "Email:",
            "contact_tel_title": "Tel:",
            "contact_address_title": "Address:",
            "contact_address": "Unit 710A, 7/F, Well Fung Industrial Centre, 68 Ta Chuen Ping Street, Kwai Chung, Hong Kong",
            "copyright": "©{{year}}  Magnolia Concept Limited. All rights reserved.",
        },
        "faq": {
            "q1": "What is the difference between infused, distilled and fermented liquors?",
            "a1": "In fermentation, sugar is metabolised by yeast, which releases alcohol as a by-product. Alcohol content produced by fermentation is relatively low. Distillation takes advantage of the low boiling point of alcohol to extract it from water and other ingredients to produce a distillate of higher alcohol content. With infusion, a common method of flavouring liquor, an ingredient is placed in contact with liquor, which extracts from it the nutrients and compounds of flavour and colour that are soluble in alcohol. The three processes are often used in combination to attain the desired flavour and alcoholic strength. \n\n As a result of the different boiling points and solubilities of the compounds contained in an ingredient, the same ingredient processed by different methods can result in completely different colours, aromas and flavours in liquors. ",
            "q2": "What is the best way to enjoy Magnolia and Roselle?",
            "a2": "Magnolia and Roselle are both liqueurs, which can be enjoyed neat or with ice. Their distinct oriental botanical flavours can also bring a unique twist to cocktails and complement Asian or Western cuisine.",
            "q3": "",
            "a3": "",
            "q4": "Where do Magnolia Lab’s botanical ingredients come from? Are they from reliable sources?",
            "a4": "All herbs used in Magnolia Lab’s liqueurs are supplied by Hong Kong’s premium Chinese herb supplier, 100 Cabinet. 100 Cabinet goes through a meticulous quality control process to ensure that herbs offered are of the best quality. They are carefully sourced from the best origins, where they are harvested at the optimal time with only the most effective parts retained. The herbs are then processed to the highest standard. No bleaching agent and excessive artificial additives are used. All the herbs are guaranteed to be natural and of the best possible flavours.",
        }
    }
}

const res = {
    "en-US": enTranslations,
    "zh-HK": zhTranslations,
    "zh-TW": zhTranslations
}

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: res,
        fallbackLng: "zh-HK",
        debug: false,
        whitelist: ["zh-HK", "en-US"],
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;

