import { useEffect, useState } from "react"
import { AnchorConfig } from "types/AnchorTypes"
import "./Anchor.scss"
import { AnchorNode } from "./AnchorNode"

export const AnchorGroup = (props: { configs: AnchorConfig[] }) => {

    const [childrenState, setChildrenState] = useState<boolean[]>([]);
    const [lastActivate, setLastActivate] = useState<number>(0);

    useEffect(() => {
        setChildrenState(props.configs.map((config, index) => index === 0 ? true : false));
        setLastActivate(0);
    }, [])

    const updateActivate = (index: number, activate: boolean) => {
        const array = childrenState;
        array[index] = activate;
        setChildrenState(array);
        setLastActivate(childrenState.lastIndexOf(true));
    }

    return (
        <div className="anchor-group">
            {
                props.configs.map((config, index) => (
                    <AnchorNode
                        nodeConfig={config}
                        controlActivate={lastActivate === index}
                        onSelfActivate={(activate) => updateActivate(index, activate)}
                        key={"anchor_" + config.targetElementId}
                    />
                ))
            }
        </div>
    )
}